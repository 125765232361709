import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { IconButton, TextField } from '@mui/material'
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, px } from '@mprise/react-ui'
import { RefreshIcon } from '../../shared/refresh-icon'
import { useLazyQuery } from '@apollo/client'
import { GET_RESOURCES } from '../../gql/resource'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { DEFAULT_PAGE_SIZE, SimpleDataGrid } from '../../shared/simple-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { GridColDef } from '@mui/x-data-grid'
import { QueryErrorMessage } from '../../shared/react-apollo'

const columns: GridColDef[] = [
  { field: 'code', headerName: 'Code', width: 200, valueGetter: params => params.row.code },
  { field: 'name', headerName: 'Name', width: 300, valueGetter: params => params.row.name },
  { field: 'type', headerName: 'Type', width: 200, valueGetter: params => params.row.type },
  { field: 'jobTitle', headerName: 'Job Title', width: 300, valueGetter: params => params.row.jobTitle },
]

export const ResourceList = () => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 500)
  const handleSearch = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => setSearch(e.target.value), [])

  const [getResources, { data, loading, error, fetchMore }] = useLazyQuery(GET_RESOURCES, {
    onError: console.error,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    getResources({
      variables: {
        filter: {
          companyId: +companyId,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          removed: false,
        },
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
      },
    })
  }, [companyId, debouncedSearch, getResources])

  // TODO: Replace control that requires refetch or make a pagination in backend
  const refetch = () => {}

  return (
    <>
      <Helmet title={t('Resources')} />

      <MPageTitle label={t('Resources')} />
      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <MFlexItem minWidth='auto' grow={10} shrink={2} />
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='on'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={() => setSearch(``)}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
          <IconButton onClick={refetch}>
            <RefreshIcon loading={loading} />
          </IconButton>
        </MFlex>

        <QueryErrorMessage query={{ loading, error }} />

        <SimpleDataGrid
          columns={columns}
          data={data?.resources?.page}
          rowCount={data?.resources?.total}
          loading={loading}
          fetchMore={fetchMore}
        />
      </MFlexBlock>
    </>
  )
}
