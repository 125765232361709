import React from 'react'
import { SwitchTransition } from 'react-transition-group'
import { QueryResult } from '@apollo/client'
import { CircularProgress, Fade } from '@mui/material'
import { MColor, MFlexBlock, MText, px } from '@mprise/react-ui'

export const LoadingSwitchPanel = ({
  loadingAnyway,
  query,
  children,
}: {
  loadingAnyway?: boolean
  query: QueryResult | Array<QueryResult> | any
  children: React.ReactNode
}) => {
  const loading = loadingAnyway || (query ? [query].flat().some(x => x.loading) : false)

  return (
    <SwitchTransition>
      <Fade key={Number(loading)}>
        {loading ? (
          <MFlexBlock justifyContent='center' alignItems='center' padding={10} gap={5}>
            <MText block textColor={MColor.primary}>
              <CircularProgress color='inherit' size={px(48)} />
            </MText>
          </MFlexBlock>
        ) : (
          <div>{children}</div>
        )}
      </Fade>
    </SwitchTransition>
  )
}
