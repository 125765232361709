import { MColor, MFieldInput, MFieldInteger, MFlexBlock, MFlexItem, MRadioGroup, MText } from '@mprise/react-ui'
import { Field, Formik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { AlertType } from '../../shared/alert-dialog'
import { AssigneeValue } from '../../shared/dialog/select-assignee-dialog'
import { SkillValue } from '../../shared/dialog/select-skill-set-dialog'
import { FieldAssignee } from '../../shared/form/field-assignee'
import { FieldAssigneeSet } from '../../shared/form/field-assignee-set'
import { FieldSkillSet } from '../../shared/form/field-skill-set'
import { withFormikCompareFix } from '../../shared/formik'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MSection, MSections } from '../../shared/msection'
import { MutationErrorMessage } from '../../shared/react-apollo'
import { FormikDialog } from '../../shared/react-formik-dialog'
import { useLocalState } from '../../shared/react-local-state'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { Maybe, defined } from '../../shared/typescript'
import { ValidationIssues } from '../../shared/validation-issues'
import { yup, yupEnum, yupObject, yupObjectArray } from '../../shared/yup-common-types'
import { TeamResourceMode } from '../../lib/enums'
import { useMutation } from '@apollo/client'
import { GET_TEAMS, CREATE_TEAM } from '../../gql/teams'
import { Collapse } from '@mui/material'

const MAX_LENGTH_INPUT_FIELD = 50

export interface CreateTeamInput {
  companyId: number
  resourceMode: TeamResourceMode
  name: string
  leaderResource: Maybe<AssigneeValue>
  resources: Array<AssigneeValue>
  resourceCount: number
  skills: Array<SkillValue>
}

export const CreateTeamDialog = ({
  handleAlert,
  open,
  onClose,
  onFinished,
}: {
  handleAlert: (alertType: AlertType) => void
  open: boolean
  onClose: () => void
  onFinished: (args: { teamId: string }) => void
}) => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const [conflict, setConflict] = useState(false)
  const [conflicts, setConflicts] = useState([])

  const [initialValues] = useLocalState<CreateTeamInput>(
    () =>
      withFormikCompareFix({
        companyId: +companyId,
        resourceMode: TeamResourceMode.List,
        name: ``,
        leaderResource: null,
        resources: [],
        resourceCount: 0,
        skills: [],
      }),
    [companyId, open],
  )

  useEffect(() => {
    setConflict(false)
  }, [open])

  const [createTeam, { data: createData, loading: createLoading, error: createError }] = useMutation(CREATE_TEAM, {
    refetchQueries: [GET_TEAMS, 'teams'],
  })

  if (createError) {
    console.error('createError', createError)
  }

  useEffect(() => {
    if (createData) {
      onFinished({ teamId: createData.createTeam.id })
      handleAlert('create')
    }
  }, [createData])

  // const { save, mutations } = CreateTeamDialog.useSave()

  const handleSubmit = async (form: CreateTeamInput) => {
    const conflicts: never[] = []
    if (!form.leaderResource?.id) {
      conflicts.push(t('Team Leader is a Required Field'))
    }

    if (conflicts.length > 0) {
      setConflict(true)
      setConflicts(conflicts)
    } else {
      createTeam({
        variables: {
          input: {
            companyId: +companyId,
            name: form.name,
            leader: form.leaderResource ? +form.leaderResource.id! : null,
            resourceMode: form.resourceMode,
            resourceCount: form.resourceCount,
            resources: form.resources && form.resources.map((x: any) => +x.id),
            skills: form.skills && form.skills.map((x: any) => +x.id),
          },
        },
      })
    }
    // const saved = await save(form)
    // if (saved) {
    //   onFinished({ teamId: saved.id })
    //   handleAlert('create')
    // }
  }

  const schema = CreateTeamDialog.useSchema()

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <FormikDialog
        minWidth='sm'
        open={open}
        title={t('DialogCreateTeamTitle')}
        submit={t('Create')}
        onClose={onClose}
      >
        <SavingSwitchPanel savingAnyway={createLoading} mutation={null}>
          {/* <MutationErrorMessage mutation={mutations} /> */}
          <ValidationIssues />
          {conflict && <CreateTeamDialogConflict conflicts={conflicts} />}
          <CreateTeamDialogForm />
        </SavingSwitchPanel>
      </FormikDialog>
    </Formik>
  )
}

const CreateTeamDialogConflict = ({ conflicts }: { conflicts: never[] }) => {
  return (
    <Collapse in={true} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
        <MFlexItem grow={1}>
          {conflicts.map((c: any) => (
            <MText block textVariant='small'>
              {c}
            </MText>
          ))}
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}

const CreateTeamDialogForm = () => {
  const { t } = useTranslation()
  const f = useFormikContext<CreateTeamInput>()

  return (
    <MSections>
      <MSection title={t(`Team Information`)}>
        <Field component={MFieldConnector} name='resourceMode' label={t(`Type`)}>
          <MRadioGroup>
            <MRadioGroup.Button
              name='resourceMode'
              value={TeamResourceMode.List}
              checked={f.values.resourceMode === TeamResourceMode.List}
              onChange={({ checked, value }) => checked && f.setFieldValue(`resourceMode`, value)}
            >
              <MText block>{t(`Named resources`)}</MText>
            </MRadioGroup.Button>
            <MRadioGroup.Button
              name='resourceMode'
              value={TeamResourceMode.Count}
              checked={f.values.resourceMode === TeamResourceMode.Count}
              onChange={({ checked, value }) => checked && f.setFieldValue(`resourceMode`, value)}
            >
              <MText block>{t(`No. of resources`)}</MText>
            </MRadioGroup.Button>
          </MRadioGroup>
        </Field>
        <Field component={MFieldConnector} name='name' label={t(`Name`)}>
          <MFieldInput inputProps={{ maxLength: MAX_LENGTH_INPUT_FIELD }} />
        </Field>
        <Field component={MFieldConnector} name='leaderResource' label={t(`Team Lead`)}>
          <FieldAssignee title={t(`Select Team Lead`)} accept={['resource']} />
        </Field>
        {f.values.resourceMode === TeamResourceMode.List ? (
          <Field component={MFieldConnector} name='resources' label={t(`Resources`)}>
            <FieldAssigneeSet title={t(`Select Resources`)} accept={['resource']} />
          </Field>
        ) : null}
        {f.values.resourceMode === TeamResourceMode.Count ? (
          <Field component={MFieldConnector} name='resourceCount' label={t(`Total number of resources`)}>
            <MFieldInteger min={0} />
          </Field>
        ) : null}
      </MSection>
      <MSection title={t(`Skills`)}>
        <Field component={MFieldConnector} name='skills' label={t(`Skills`)}>
          <FieldSkillSet title={t(`Select Skills`)} />
        </Field>
      </MSection>
    </MSections>
  )
}

CreateTeamDialog.useSchema = () => {
  const { t } = useTranslation()
  const [schema] = useState(() => {
    return yupObject<CreateTeamInput>({
      companyId: yup.string().label(t(`Company Id`)).required(),
      resourceMode: yupEnum(TeamResourceMode).required(),
      name: yup.string().required().label(t(`Name`)).max(MAX_LENGTH_INPUT_FIELD),
      leaderResource: yupObject({ id: yup.string().required(), name: yup.string().required() })
        .required()
        .nullable()
        .label(t(`Team Lead`)),
      resources: yupObjectArray({ id: yup.string().required(), name: yup.string().required() }).label(
        t(`Resources`),
      ) as any,
      resourceCount: yup.number().label(t(`Total number of resources`)).required(),
      skills: yupObjectArray({ id: yup.string().required(), name: yup.string().required() }).label(t(`Skills`)) as any,
    })
  })
  return schema
}

// CreateTeamDialog.useSave = () => {
//   const [createTeam, createTeamMutation] = useCreateTeamMutation()
//   const [updateTeamDetails, updateTeamDetailsMutation] = useUpdateTeamDetailsMutation()
//   const [updateTeamResources, updateTeamResourcesMutation] = useUpdateTeamResourcesMutation()
//   const [updateTeamResourceCount, updateTeamResourceCountMutation] = useUpdateTeamResourceCountMutation()

//   async function save(form: CreateTeamInput) {
//     const created = await createTeam({
//       variables: {
//         companyId: form.companyId.toString(),
//         name: form.name,
//       },
//     })
//     if (!created.data?.createTeam?.id) {
//       return null
//     }

//     const updated = await updateTeamDetails({
//       refetchQueries: [namedOperations.Query.resourcesByFilter, namedOperations.Query.teamsByFilter],
//       variables: {
//         teamId: created.data.createTeam.id,
//         name: form.name,
//         leaderResourceId: form.leaderResource?.id!.toString(),
//         skillIds: form.skills.map((x) => x.id.toString()),
//       },
//     })
//     if (!updated.data?.details?.setName?.id) {
//       return null
//     }

//     if (form.resourceMode === TeamResourceMode.Count) {
//       const updated = await updateTeamResourceCount({
//         refetchQueries: [namedOperations.Query.resourcesByFilter, namedOperations.Query.teamsByFilter],
//         variables: {
//           teamId: created.data.createTeam.id,
//           resourceIdCount: form.resourceCount,
//         },
//       })
//       if (!updated.data?.team?.setResourcesByCount?.id) {
//         return null
//       }
//     } else {
//       const updated = await updateTeamResources({
//         refetchQueries: [namedOperations.Query.resourcesByFilter, namedOperations.Query.teamsByFilter],
//         variables: {
//           teamId: created.data.createTeam.id,
//           resourceIds: form.resources.map((x) => x.id?.toString()).filter(defined),
//         },
//       })
//       if (!updated.data?.team?.setResourcesByList?.id) {
//         return null
//       }
//     }
//     return created.data?.createTeam
//   }

//   return { save, mutations: [createTeamMutation, updateTeamDetailsMutation, updateTeamResourcesMutation, updateTeamResourceCountMutation] }
// }
