import { MDivider, MFieldRadioGroup, MFieldRadioGroupOption, MText, useMField } from '@mprise/react-ui'
import { PriorityAvatar } from '../tag-priority'
import { WorkPriority } from '../../lib/enums'

export const FieldPriority = () => (
  <MFieldRadioGroup>
    <MFieldRadioGroupOption value={WorkPriority.None}>
      <MText block>None</MText>
    </MFieldRadioGroupOption>
    <MDivider vertical />
    <MFieldRadioGroupOption value={WorkPriority.Low}>
      <MText block>Low</MText>
    </MFieldRadioGroupOption>
    <MFieldRadioGroupOption value={WorkPriority.Medium}>
      <MText block>Medium</MText>
    </MFieldRadioGroupOption>
    <MFieldRadioGroupOption value={WorkPriority.High}>
      <MText block>High</MText>
    </MFieldRadioGroupOption>
  </MFieldRadioGroup>
)

export const FieldPriorityReadOnly = ({ disabled }: { disabled?: boolean }) => {
  const f = useMField()
  return <PriorityAvatar priority={f.value} disabled={disabled} />
}
