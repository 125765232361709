import { MField, useMField } from '@mprise/react-ui'
import { useState } from 'react'
import { SelectLocationsDialog, SelectLocationsDialogAvatars, SimpleLocation } from '../dialog/select-locations-dialog'
import { FieldBarcode } from './field-barcode'

export const FieldLocationSet = ({ title }: { title: string }) => {
  const f = useMField()
  const selected = f.value as SimpleLocation[]
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const onSave = (selectedItems: SimpleLocation[]) => {
    f.onChange?.(selectedItems)
    handleClose()
  }

  return (
    <>
      <SelectLocationsDialog
        open={open}
        onClose={handleClose}
        onSave={onSave}
        title={title}
        loading={false}
        initialValue={selected}
      />
      <FieldBarcode
        selected={f.value}
        loading={false}
        onExplore={handleOpen}
        renderLabel={() => (
          <MField.ScrollableContentX>
            <SelectLocationsDialogAvatars />
          </MField.ScrollableContentX>
        )}
        showInstantScanButton={false}
        onClear={handleClear}
        onScanned={() => {}}
      />
    </>
  )
}
