import { MFlex, MText, MTextColor } from '@mprise/react-ui'
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const DEFAULT_PAGE_SIZE = 20

export const SimpleDataGrid = ({
  data,
  rowCount,
  columns,
  columnNames,
  fetchMore,
  loading,
  onRowClick,
  pageSize = DEFAULT_PAGE_SIZE,
}: {
  data: any[]
  rowCount: number
  columns?: GridColDef[]
  columnNames?: string[]
  fetchMore?: (fetchMoreOptions: any) => Promise<any>
  loading: boolean
  onRowClick?: any
  pageSize?: number
}) => {
  const columnDefinitions: GridColDef[] = columnNames
    ? columnNames.map((columnId: string) => {
        return { field: columnId, headerName: capitalizeFirstLetter(columnId), width: 250 }
      })
    : columns ?? []

  if (fetchMore) {
    return SimpleDataGridWithServerSidePaging({
      data,
      rowCount,
      columnDefinitions,
      fetchMore,
      loading,
      onRowClick,
      pageSize,
    })
  } else {
    return SimpleDataGridWithClientSidePaging({ data, rowCount, columnDefinitions, loading, onRowClick, pageSize })
  }
}

/** Implementation of the SimpleDataGrid which loads all the data at once and only does paging in
 * the UI. Not recommended; ideally all views will use the ServerSidePaging soon. */
const SimpleDataGridWithClientSidePaging = ({
  data,
  rowCount,
  columnDefinitions,
  loading,
  onRowClick,
  pageSize,
}: {
  data: any[]
  rowCount: number
  columnDefinitions: GridColDef[]
  loading: boolean
  onRowClick?: any
  pageSize: number
}) => {
  return (
    <DataGrid
      className='MuiTypography-body2'
      rows={data ?? []}
      columns={columnDefinitions}
      initialState={{
        pagination: { paginationModel: { pageSize: pageSize } },
      }}
      loading={loading}
      autoHeight={true}
      pageSizeOptions={[pageSize]}
      disableRowSelectionOnClick
      onRowClick={onRowClick}
      rowCount={rowCount}
      onPaginationModelChange={() => {}}
      sx={{
        '.MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: onRowClick ? 'pointer' : 'unset',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'normal',
        },
      }}
      slots={{
        noRowsOverlay: NoResultsOverlay,
        noResultsOverlay: NoResultsOverlay,
      }}
    />
  )
}

/** Implementation of the SimpleDataGrid which properly loads one page of data from the backend at a time. Recommended way
 * of using the DataGrid; ideally all views will use this component soon and the ClientSidePaging option will be removed. */
const SimpleDataGridWithServerSidePaging = ({
  data,
  rowCount,
  columnDefinitions,
  fetchMore,
  loading,
  onRowClick,
  pageSize = DEFAULT_PAGE_SIZE,
}: {
  data: any[]
  rowCount: number
  columnDefinitions: GridColDef[]
  fetchMore?: (fetchMoreOptions: any) => Promise<any>
  loading: boolean
  onRowClick?: any
  pageSize: number
}) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: pageSize })

  const handlePaginationModelChange = async (newPaginationModel: GridPaginationModel) => {
    if (!fetchMore || newFilterValueUsed()) {
      return
    }

    const newOffset = newPaginationModel.page * pageSize
    if (newOffset !== 0 && data.length <= newOffset) {
      await fetchMore({
        variables: {
          offset: newOffset,
        },
      })
    }
    setPaginationModel(newPaginationModel)
  }

  useEffect(() => {
    if (newFilterValueUsed()) {
      setPaginationModel({ page: 0, pageSize: pageSize })
    }
  }, [data])

  /** Detects when a new search value is used on the page. Used to jump back to the first page. */
  function newFilterValueUsed() {
    return data && data.length <= pageSize && paginationModel.page > 0
  }

  return (
    <DataGrid
      className='MuiTypography-body2'
      rows={data ?? []}
      // Sorting disabled on all columns; if we want it to work, we need to build it in the backend
      columns={columnDefinitions.map(column => ({ ...column, sortable: false }))}
      loading={loading}
      autoHeight={true}
      pageSizeOptions={[pageSize]}
      disableRowSelectionOnClick
      // Filtering disabled on all columns; if we want it to work, we need to build it in the backend
      disableColumnFilter
      onRowClick={onRowClick}
      rowCount={rowCount}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      sx={{
        '.MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: onRowClick ? 'pointer' : 'unset',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'normal',
        },
      }}
      slots={{
        noRowsOverlay: NoResultsOverlay,
        noResultsOverlay: NoResultsOverlay,
      }}
    />
  )
}

const NoResultsOverlay = () => {
  const { t } = useTranslation()

  return (
    <MFlex justifyContent='center' alignItems='center' style={{ height: '100%' }}>
      <MText block textVariant='content bold' textColor={MTextColor.disabled}>
        {t('NOTIFICATION_NO_RESULTS')}
      </MText>
    </MFlex>
  )
}

const capitalizeFirstLetter = (s: string) => {
  return (s && s[0].toUpperCase() + s.slice(1)) || ''
}
