import { gql } from '@apollo/client'

export const GET_TENANT_ROLES = gql`
  query Roles($filter: RoleFilter) {
    roles(filter: $filter) {
      id
      name
      permissions {
        id
      }
      userCount
      locationPermissions {
        id
        name
      }
      genusPermissions {
        id
        name
      }
      jobTypePermissions {
        id
        name
      }
      activityPermissions {
        id
        name
      }
    }
  }
`

export const UPDATE_ROLE = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input)
  }
`

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: String!) {
    deleteRole(id: $id)
  }
`

export const GET_CONFIGURABLE_PERMISSIONS = gql`
  query GetConfigurablePermissions {
    configurablePermissions {
      id
      name
      permissions
    }
  }
`
