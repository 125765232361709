import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentCompanyId } from '../useCurrentCompany'
import { SearchAndSelectDialog } from './search-and-select-dialog'
import { NextPageView } from '../NextPageView'
import { DocumentNode, OperationVariables, useLazyQuery } from '@apollo/client'
import { useDebounceValue } from '../../shared/debounce-value'
import styled from '@emotion/styled'
import { MJoinChildren, MDivider, useMField, MFlexItem, MFlexBlock } from '@mprise/react-ui'
import { ListItem, ListItemText, Radio } from '@mui/material'

export const SingleSelectDialog = <T extends { id: number }>({
  open,
  title,
  onClose,
  onSave,
  initialValue,
  getRecordsQuery,
  variables,
  queryResultAccessor,
  hideSearch,
  displayProperty,
}: {
  open: boolean
  title: string
  onClose: () => void
  onSave: (selected: T) => void
  initialValue?: T
  getRecordsQuery: DocumentNode
  variables?: OperationVariables
  queryResultAccessor: (data: any) => T[] | undefined
  hideSearch?: boolean
  displayProperty: keyof T
}) => {
  const companyId = useCurrentCompanyId()

  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounceValue(search, 500)

  const [dialogInitialValue] = useState(initialValue)

  const [getRecords, { data, loading, fetchMore }] = useLazyQuery(getRecordsQuery, {
    onError: console.error,
  })

  useEffect(() => {
    getRecords({
      variables: variables ?? {
        filter: {
          companyId: +companyId,
          removed: false,
          ...(debouncedSearch && { searchString: debouncedSearch }),
        },
        limit: 20,
        offset: 0,
      },
    })
  }, [companyId, debouncedSearch, getRecords, variables])

  const records = queryResultAccessor(data)

  return (
    <SearchAndSelectDialog
      loading={loading}
      initialValue={dialogInitialValue as T}
      gap={0}
      open={open}
      title={title}
      text={search}
      onClose={onClose}
      onSave={onSave}
      onChange={hideSearch ? undefined : setSearch}
    >
      <SingleSelectDialogList records={records} displayProperty={displayProperty} />
      <NextPageView
        offset={records?.length ?? 0}
        fetchMore={fetchMore}
        pageSize={20}
        fetchMoreDataAccessor={queryResultAccessor}
      />
    </SearchAndSelectDialog>
  )
}

const SingleSelectDialogList = <T extends { id: number }>({
  records,
  displayProperty,
}: {
  records?: T[]
  displayProperty: keyof T
}) => {
  const { t } = useTranslation()

  if (!records?.length) {
    return (
      <ListItem>
        <ListItemText primary={t('No results')} />
      </ListItem>
    )
  }

  return (
    <MJoinChildren divider={MDivider}>
      {records.map(x => {
        return <SingleSelectDialogItem key={x.id} record={x} displayProperty={displayProperty} />
      })}
    </MJoinChildren>
  )
}

const SingleSelectDialogItem = <T extends { id: number }>({
  record,
  displayProperty,
}: {
  record: T
  displayProperty: keyof T
}) => {
  const f = useMField()
  const selectedRecord = f.value as T
  const currentlySelected = selectedRecord?.id === record.id

  const handleClick = () => {
    f.onChange?.(record)
  }

  return (
    <MFlexBlockClickable gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <Radio checked={currentlySelected} />
      <MFlexItem grow={1}>
        <ListItemText primary={record[displayProperty] as string} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

const MFlexBlockClickable = styled(MFlexBlock)`
  cursor: pointer;
  color: inherit;
`
