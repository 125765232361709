import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Collapse, ListItem, ListItemText } from '@mui/material'
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'
import Icon from '@mdi/react'
import {
  MAvatar,
  MColor,
  MDivider,
  MFlexBlock,
  MFlexItem,
  MJoinChildren,
  MSelectItem,
  MTextColor,
  useMField,
} from '@mprise/react-ui'
import { useCurrentCompanyId } from '../useCurrentCompany'
import { IconTeam } from '../../icons'
import { MSection, MSections } from '../msection'
import { SearchAndSelectDialog } from './search-and-select-dialog'
import { useLazyQuery } from '@apollo/client'
import { GET_TEAMS } from '../../gql/teams'
import { useDebounceValue } from '../../shared/debounce-value'

export type SimpleTeam = {
  __typename: 'Team'
  id: number
  name: string
}
const MFlexBlockClickable = styled(MFlexBlock)`
  cursor: pointer;
`

export const SelectTeamsDialog = ({
  loading,
  open,
  title,
  onClose,
  onSave,
  initialValue,
}: {
  loading: boolean
  open: boolean
  title: string
  onClose: () => void
  onSave: (selected: SimpleTeam[]) => void
  initialValue?: SimpleTeam[]
}) => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounceValue(search, 500)

  const [getTeams, { error: teamsError, data: teams }] = useLazyQuery(GET_TEAMS)

  if (teamsError) {
    console.error(teamsError)
  }

  useEffect(() => {
    getTeams({
      variables: {
        filter: {
          companyId: +companyId,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          removed: false,
        },
      },
    })
  }, [companyId, debouncedSearch, getTeams])

  return (
    <SearchAndSelectDialog
      loading={loading}
      initialValue={initialValue}
      gap={0}
      open={open}
      title={title}
      text={search}
      onClose={onClose}
      onSave={selected => onSave(selected as SimpleTeam[])}
      onChange={(text: string) => {
        setSearch(text)
      }}
    >
      <SelectTeamsDialogAvatars />
      <MSections>
        <MDivider />
        <SelectTeamsDialogList header={t('All')} teams={teams && teams.teams} />
        {/* {nextCursor && <NextPageView key={nextCursor} cursor={nextCursor} fetch={fetchCursor} />} */}
      </MSections>
    </SearchAndSelectDialog>
  )
}

const SelectTeamsDialogList = ({ teams, header }: { teams: SimpleTeam[]; header: string }) => {
  const { t } = useTranslation()
  return (
    <MSection title={header}>
      <MJoinChildren divider={MDivider}>
        {teams &&
          teams.map(t => {
            return <SelectTeamsDialogItem key={t?.id} team={t} />
          })}
        {teams && teams.length > 0 ? null : (
          <ListItem>
            <ListItemText primary={t(`No results`)} />
          </ListItem>
        )}
      </MJoinChildren>
    </MSection>
  )
}

const SelectTeamsDialogItem = ({ team }: { team: SimpleTeam }) => {
  const field = useMField()
  const selectedTeams = field.value as SimpleTeam[]
  const selected = keyed(selectedTeams).includes(team)

  const primary = team.name ?? team.id

  const icon = (
    <MAvatar.Badge seed={primary} size='medium'>
      <IconTeam fontSize='large' />
    </MAvatar.Badge>
  )

  const check = selected ? (
    <Icon path={mdiRadioboxMarked} size={1} color={MColor.primary} />
  ) : (
    <Icon path={mdiRadioboxBlank} size={1} color={MTextColor.shadow} />
  )

  const handleClick = () => {
    if (selected) {
      const new_selected = selectedTeams.slice()
      keyed(new_selected).delete(team)
      field.onChange?.(new_selected)
    } else {
      const new_selected = selectedTeams.slice()
      keyed(new_selected).delete(team) // ?why?
      keyed(new_selected).add(team)
      field.onChange?.(new_selected)
    }
  }

  return (
    <MFlexBlockClickable id={team.id.toString()} gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <MFlexItem>{check}</MFlexItem>
      <MFlexItem>{icon}</MFlexItem>
      <MFlexItem grow={1}>
        <ListItemText primary={primary} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

const keyed = <T extends { id: number }>(list: T[]) => {
  const byId = (item: { id: number }) => (other: { id: number }) => item.id === other.id
  return {
    add(item: T) {
      if (!list.some(byId(item))) {
        list.push(item)
      }
    },
    delete(item: { id: number }) {
      if (list.some(byId(item))) {
        list.splice(list.findIndex(byId(item)), 1)
      }
    },
    includes(item: { id: number }) {
      return list.some(byId(item))
    },
  }
}

export const SelectTeamsDialogAvatars = ({ onClick }: { onClick?: () => void }) => {
  const field = useMField()
  const selectedTeams = field.value as SimpleTeam[]
  const handleRemove = (team: MSelectItem<SimpleTeam>) => {
    const keys = selectedTeams.slice()
    keyed(keys).delete(team.data)

    if (field.onChange) {
      field.onChange(keys)
    }
  }

  return (
    <Collapse in={selectedTeams.length > 0} unmountOnExit>
      <MAvatar.MultiSelectList
        items={selectedTeams.map(toSelectItem)}
        enableUngroup={() => false}
        enableRemove={() => true}
        onUngroup={() => {}}
        onRemove={handleRemove}
        onClick={onClick}
      />
    </Collapse>
  )
}

const toSelectItem = (value: SimpleTeam): MSelectItem<SimpleTeam> => {
  return {
    data: value,
    icon: <IconTeam fontSize='large' />,
    id: value.id.toString(),
    primary: value.name ?? ``,
    secondary: null,
  }
}
