import { gql } from '@apollo/client'

export const GET_ACTIVITIES = gql`
  query Activities($filter: ActivityFilter, $limit: Int, $offset: Int) {
    activities(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
      }
      total
    }
  }
`
