import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppBar, Box, CssBaseline, Drawer, Fade, IconButton, Toolbar } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { Menu as MenuIcon } from '@mui/icons-material'
import {
  mdiArrowLeftBoldCircle,
  mdiArrowLeftBoldCircleOutline,
  mdiArrowRightBoldCircle,
  mdiArrowRightBoldCircleOutline,
} from '@mdi/js'
import Icon from '@mdi/react'
import { MAppsButton, MText, MEnvironment, spacing } from '@mprise/react-ui'
import { useHistory } from '../shared/use-history'
import { useCurrentCompanyId } from '../shared/useCurrentCompany'
import { CompanyValue, SelectCompanyDialog } from '../shared/select-company-dialog'
import { StateSession } from '../state/session'
import { AppDrawer } from './drawer'
import * as serviceWorker from '../shared/serviceWorker'
import { updateApplicationReducer, emptyUpdateApplicationState } from '../shared/update-reducer'
import { UpdateBar } from '../shared/update-bar'
import { useQuery } from '@apollo/client'
import { GET_APPS } from '../gql/apps'
import { defined } from '../shared/typescript'
import { GET_COMPANIES } from '../gql/companies'

export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme()

  const [state, dispatch] = useReducer(updateApplicationReducer, emptyUpdateApplicationState)
  useEffect(() => {
    serviceWorker.register({
      onSuccess: reg => dispatch({ type: 'SERVICEWORKER_INIT', instance: reg }),
      onUpdate: reg => dispatch({ type: 'SERVICEWORKER_UPDATED', instance: reg }),
    })
  }, [])

  const { t } = useTranslation()

  const handleToggleDrawer = () => setMobileOpen(x => !x)

  const companyId = useCurrentCompanyId()
  const [, dispatchContext] = useContext(StateSession.Context)
  const [selectCompany, setSelectCompany] = useState(false)
  const selectCompanyAnyway = !companyId
  const handleSelectCompany = () => setSelectCompany(true)

  const [mobileOpen, setMobileOpen] = useState(false)
  const handleCloseDrawer = () => setMobileOpen(false)

  const appsQuery = useQuery(GET_APPS)
  const apps = appsQuery.data?.myApplications?.filter(defined).map((app: any) => ({ ...app, url: app.publicUrl })) ?? []

  const [initialRender, setInitialRender] = useState(true)

  const { data: companies } = useQuery(GET_COMPANIES, {
    onError: console.error,
  })

  useEffect(() => {
    if (companies && initialRender && companies.companies?.length) {
      const savedCompanyIsValid = companies.companies.map((company: { id: any }) => company.id).includes(companyId)

      if (!savedCompanyIsValid) {
        if (companies.companies.length === 1) {
          dispatchContext({ setCompanyId: (companies.companies[0].id ?? '').toString() })
          dispatchContext({ setCosmosKey: companies.companies[0].cosmosKey ?? '' })
        } else {
          dispatchContext({ setCompanyId: '' })
          dispatchContext({ setCosmosKey: '' })
          setSelectCompany(true)
        }
      }
      setInitialRender(false)
    }
  }, [companies])

  return (
    <Box sx={root}>
      <CssBaseline />
      <UpdateBar
        instance={state.instance}
        updated={state.updated}
        onUpdate={() => dispatch({ type: 'SERVICEWORKER_RESET' })}
      >
        <AppLayout.StyledAppBar position='fixed'>
          <Toolbar>
            <AppLayout.MenuButtonContainer>
              <IconButton color='inherit' edge='start' onClick={handleToggleDrawer} sx={menuButton}>
                <MenuIcon />
              </IconButton>
            </AppLayout.MenuButtonContainer>
            <MText block textVariant='header'>
              Administration
            </MText>
            <PointerNavigation />
            <AppLayout.EnvironmentButton>
              <MEnvironment />
            </AppLayout.EnvironmentButton>
            <Box flexBasis={0}>
              <MAppsButton apps={apps} />
            </Box>
          </Toolbar>
        </AppLayout.StyledAppBar>
        <AppLayout.StyledNav>
          <Drawer
            sx={{
              [theme.breakpoints.up('lg')]: {
                display: 'none',
              },
              '& .MuiDrawer-paper': { width: drawerWidth },
            }}
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleToggleDrawer}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <AppDrawer onClose={handleCloseDrawer} onSelectCompany={handleSelectCompany} />
          </Drawer>
          <Drawer
            sx={{
              [theme.breakpoints.down('lg')]: {
                display: 'none',
              },
              '& .MuiDrawer-paper': { width: drawerWidth },
            }}
            variant='permanent'
            open
          >
            <AppDrawer onClose={handleCloseDrawer} onSelectCompany={handleSelectCompany} />
          </Drawer>
        </AppLayout.StyledNav>
        <main style={{ flexGrow: 1, backgroundColor: 'white' }}>
          <div style={{ minHeight: theme.spacing(8) }} />
          {children}
        </main>
        <SelectCompanyDialog
          open={selectCompany || selectCompanyAnyway}
          title={t('SelectACompany')}
          onClose={() => setSelectCompany(false)}
          onSave={(company: CompanyValue | null) => {
            if (company) {
              dispatchContext({ setCompanyId: company.id.toString() })
              dispatchContext({ setCosmosKey: company.cosmosKey })
            }
            setSelectCompany(false)
          }}
        />
      </UpdateBar>
    </Box>
  )
}

const PointerNavigation = () => {
  const history = useHistory()
  const [active, setActive] = useState(false)
  const [start, setStart] = useState<number | null>(null)
  const pending = useRef(false)
  const [current, setCurrent] = useState(0)
  const range = 80
  const state = start === null ? 0 : Math.pow((current - start) / range, 3)
  const handlePointerDown: React.PointerEventHandler<HTMLElement> = e => {
    e.preventDefault()
    e.stopPropagation()

    setStart(e.screenX)
    setCurrent(e.screenX)
    setActive(true)
    pending.current = true
  }
  const handlePointerMove: React.PointerEventHandler<HTMLElement> = e => {
    e.preventDefault()
    e.stopPropagation()

    setCurrent(e.screenX)

    if (Math.abs(state) >= 1 && pending.current) {
      pending.current = false
      setActive(false)
      const forward = state > 0
      history.go(forward ? 1 : -1)
    }
  }
  const handlePointerLeave: React.PointerEventHandler<HTMLElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    pending.current = false
    setActive(false)
  }
  const handlePointerUp: React.PointerEventHandler<HTMLElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    pending.current = false
    setActive(false)
  }
  const leftIcon = state <= -1 ? mdiArrowLeftBoldCircle : mdiArrowLeftBoldCircleOutline
  const rightIcon = state >= 1 ? mdiArrowRightBoldCircle : mdiArrowRightBoldCircleOutline
  return (
    <Box
      display='flex'
      flexGrow={1}
      justifyContent='center'
      alignSelf='stretch'
      alignItems='center'
      style={{ userSelect: `none`, touchAction: `none` }}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
      onPointerLeave={handlePointerLeave}
    >
      <Fade in={active} timeout={{ enter: 150, exit: 600 }}>
        <Box paddingRight={1}>
          <Icon
            path={leftIcon}
            size={2}
            style={{
              opacity: 0.4 + Math.max(-0.4, state * -1) * 0.6,
              transformOrigin: `50% 50%`,
              transform: `translateX(${constrain(state, -1, 0) * 30}px) scale(${1.0 + constrain(state, -1, 1) * -0.3})`,
            }}
          />
        </Box>
      </Fade>
      <Fade in={active} timeout={{ enter: 150, exit: 600 }}>
        <Box paddingLeft={1}>
          <Icon
            path={rightIcon}
            size={2}
            style={{
              opacity: 0.4 + Math.max(-0.4, state) * 0.6,
              transformOrigin: `50% 50%`,
              transform: `translateX(${constrain(state, 0, 1) * 30}px) scale(${1.0 + constrain(state, -1, 1) * 0.3})`,
            }}
          />
        </Box>
      </Fade>
    </Box>
  )
}

const constrain = (val: number, min: number, max: number) => (val < min ? min : val > max ? max : val)

const drawerWidth = 261

const root = {
  display: 'flex',
  userSelect: 'none',
}

const menuButton = {
  marginRight: spacing(2),
}

AppLayout.StyledNav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}))

AppLayout.StyledAppBar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
}))

AppLayout.MenuButtonContainer = styled('div')(({ theme }) => ({
  flexBasis: 64,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

AppLayout.EnvironmentButton = styled('span')(() => ({
  color: 'rgba(0, 0, 0, 0.54)',
  borderRight: '1px solid rgba(0, 0, 0, 0.54)',
  fontSize: '1rem',
  display: 'block',
  lineHeight: '1em',
  paddingRight: '.8rem',
}))
