import { gql } from '@apollo/client'

export const GET_BOOLEAN_SETTING = gql`
  query getSetting($companyId: Int!, $settingName: String!) {
    booleanSetting(companyId: $companyId, settingName: $settingName)
  }
`

export const SET_BOOLEAN_SETTING = gql`
  mutation setSetting($companyId: Int!, $settingName: String!, $value: Boolean!) {
    setBooleanSetting(companyId: $companyId, settingName: $settingName, value: $value)
  }
`
