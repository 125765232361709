import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MutationResult, QueryResult } from '@apollo/client'
import { MFlex, MFlexBlock, MPortalDialog, px } from '@mprise/react-ui'
import { Form, Formik } from 'formik'
import { withFormikCompareFix } from './formik'
import { LoadingSwitchPanel } from './loading-switch-panel'
import { MutationErrorMessage, QueryErrorMessage } from './react-apollo'
import { SavingSwitchPanel } from './saving-switch-panel'
import { ValidationIssues } from './validation-issues'
import { AlertDialog, AlertType } from '../shared/alert-dialog'
import { Button } from '@mui/material'

export const PatternDialog = <Values extends {}>({
  alert,
  initialValues,
  schema,
  open,
  title,
  submit,
  children,
  query,
  mutation,
  onSave,
  onClose,
  onDelete,
  onAlertClose,
}: {
  alert?: AlertType | null
  initialValues: Values
  schema: unknown
  open: boolean
  title: React.ReactNode
  submit: React.ReactNode
  children: React.ReactNode
  query?: QueryResult | Array<QueryResult> | any
  mutation?: MutationResult | Array<MutationResult> | any
  onSave: (values: Values) => void
  onClose: () => void
  onDelete?: (e: React.MouseEvent<HTMLElement>) => void
  onAlertClose?: () => void
}) => {
  const { t } = useTranslation()
  const initialValuesFixed = useMemo(() => withFormikCompareFix(initialValues), [initialValues])

  // TODO: fix
  // const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down(`sm`))
  const isSmall = true
  const contentMinWidth = isSmall ? undefined : px(480)

  return (
    <Formik<Values> enableReinitialize initialValues={initialValuesFixed} validationSchema={schema} onSubmit={onSave}>
      <MPortalDialog.Dialog open={open} onClose={onClose}>
        <Form>
          <MPortalDialog.Header onClose={onClose}>{title}</MPortalDialog.Header>
          <MPortalDialog.Content style={{ minWidth: contentMinWidth }}>
            {alert === `warning` && <AlertDialog alert={alert} entity={`Template`} handleClose={onAlertClose} />}
            <ValidationIssues />
            <MutationErrorMessage mutation={mutation ?? []} />
            <QueryErrorMessage query={query ?? []} />
            <SavingSwitchPanel mutation={mutation ?? []}>
              <LoadingSwitchPanel query={query ?? []}>{children}</LoadingSwitchPanel>
            </SavingSwitchPanel>
          </MPortalDialog.Content>
          <MPortalDialog.Footer>
            <MFlexBlock justifyContent='flex-end'>
              {onDelete && (
                <MFlex grow={1}>
                  <Button onClick={onDelete}>{t('ACTION_DELETE')}</Button>
                </MFlex>
              )}
              <MPortalDialog.SubmitButton>{submit}</MPortalDialog.SubmitButton>
            </MFlexBlock>
          </MPortalDialog.Footer>
        </Form>
      </MPortalDialog.Dialog>
    </Formik>
  )
}
