import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GridColDef } from '@mui/x-data-grid'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { useHistory } from '../../shared/use-history'
import { SimpleDialog } from '../../shared/dialog/simple-dialog'
import { GET_TRID_HISTORY } from '../../gql/trackings'
import { SimpleDataGrid } from '../../shared/simple-data-grid'
import { useTranslation } from 'react-i18next'

const columns: GridColDef[] = [
  { field: 'task', headerName: 'Task', width: 140, valueGetter: params => params.row.task },
  { field: 'status', headerName: 'Status', width: 120, valueGetter: params => params.row.status },
  { field: 'statusName', headerName: 'Status Name', width: 150, valueGetter: params => params.row.statusName },
  {
    field: 'created_on',
    headerName: 'Created On',
    width: 230,
    valueGetter: params =>
      `${params.row.datetime.split('T')[0]} | ${params.row.datetime.split('T')[1].substring(0, 8)}`,
  },
  {
    field: 'resource',
    headerName: 'Resource',
    width: 320,
    renderCell: params =>
      params.row.resource ? (
        `${params.row.resource?.code} - ${params.row.resource?.name}`
      ) : (
        <i>{'N/A - Automatic process'}</i>
      ),
  },
]

export const TrackingDetailsDialogRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const params = useParams()
  const tracking = params.tridIdAndCode ?? ''

  const { data, loading } = useQuery(GET_TRID_HISTORY, {
    variables: {
      trackingCode: tracking.substring(tracking.indexOf('-') + 1),
      company: +companyId!,
    },
    onError: console.error,
  })

  const handleClose = async () => {
    h.push('/trackingids', { replace: true })
  }

  return (
    <SimpleDialog
      title={`${t('Status History')} | ${tracking.substring(tracking.indexOf('-') + 1)}`}
      open={true}
      onClose={handleClose}
    >
      <SimpleDataGrid
        columns={columns}
        data={data?.tridStatusHistory}
        rowCount={data?.tridStatusHistory?.length}
        loading={loading}
      />
    </SimpleDialog>
  )
}
