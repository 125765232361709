import { Avatar, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { availableLanguages } from '../../lib/translations/i18n'

export const LanguageDialog = ({
  open,
  onCancelled,
  onChanged,
}: {
  open: boolean
  onCancelled: () => void
  onChanged: (companyId: string) => void
}) => {
  const { t } = useTranslation()

  return (
    <Dialog onClose={onCancelled} aria-labelledby='language-dialog-title' open={open}>
      <DialogTitle id='language-dialog-title'>{t('TITLE_SELECT_LANGUAGE')}</DialogTitle>
      <List>
        {availableLanguages.map(language => (
          <ListItem button onClick={() => onChanged(language)} key={language}>
            <ListItemAvatar>
              <Avatar>{language.toUpperCase()}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={t(`Language-${language}`)} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}
