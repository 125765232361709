import styled from '@emotion/styled'
import { Collapse, ListItem, ListItemText } from '@mui/material'
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'
import Icon from '@mdi/react'
import {
  Internals,
  MAvatar,
  MColor,
  MDivider,
  MFlexBlock,
  MFlexItem,
  MJoinChildren,
  MSelectItem,
  MTextColor,
  useMField,
} from '@mprise/react-ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconSkill } from '../../icons'
import { MSection, MSections } from '../msection'
import {} from '../validation-issues'
import { SearchAndSelectDialog } from './search-and-select-dialog'
import { useLazyQuery } from '@apollo/client'
import { GET_SKILLS } from '../../gql/skills'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../useCurrentCompany'

export type SkillValue = {
  __typename?: 'Skill' | undefined
  id: number
  name: string
  code: string
  description?: string
}
export const SelectSkillSetDialog = ({
  initialValue,
  title,
  loading,
  open,
  onClose,
  onSave,
}: {
  initialValue?: SkillValue[]
  title: React.ReactNode
  loading?: boolean
  open: boolean
  onClose: () => void
  onSave: (selected: SkillValue[]) => Promise<void> | void
}) => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()
  const [search, setResourceSearch] = useState(``)
  const debouncedSearch = useDebounceValue(search, 500)

  const [getSkills, { error: skillsError, data: skills }] = useLazyQuery(GET_SKILLS)

  if (skillsError) {
    console.error(skillsError)
  }

  useEffect(() => {
    getSkills({
      variables: {
        filter: {
          companyId: +companyId,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          removed: false,
        },
      },
    })
  }, [companyId, debouncedSearch, getSkills])

  return (
    <SearchAndSelectDialog
      loading={loading}
      initialValue={initialValue}
      gap={0}
      open={open}
      title={title}
      text={search}
      onClose={onClose}
      onSave={selected => onSave(selected as SkillValue[])}
      onChange={setResourceSearch}
    >
      {/* <QueryErrorMessage query={skillQuery} /> */}
      <SelectSkillSetDialogAvatars />
      <MSections>
        {search ? <SelectSkillSetDialogList header={t(`Results`)} skills={skills.skills} /> : null}
        <MDivider />
        {!search ? <SelectSkillSetDialogList header={t(`All`)} skills={skills && skills.skills} /> : null}
      </MSections>
    </SearchAndSelectDialog>
  )
}

SelectSkillSetDialog.useDialogState = <C extends unknown>(
  onSelected: (values: SkillValue[], context: C | undefined) => void,
) => {
  const [state, setState] = useState({
    context: undefined as C | undefined,
    loading: false,
    open: false,
    assignees: [] as Array<SkillValue>,
  })
  return {
    context: state.context,
    loading() {
      setState({ open: true, loading: true, assignees: [], context: undefined })
    },
    open(assignees: Array<SkillValue>, context?: C) {
      setState({ open: true, loading: false, assignees, context })
    },
    close() {
      setState(s => ({ ...s, open: false }))
    },
    props: {
      initialValue: state.assignees,
      open: state.open,
      loading: state.loading,
      onClose: () => setState(s => ({ ...s, open: false })),
      onSave: async (a: Array<SkillValue>) => {
        await onSelected(a, state.context)
        setState(s => ({ ...s, open: false }))
      },
    },
  }
}

const SelectSkillSetDialogList = ({ header, skills }: { header: React.ReactNode; skills: SkillValue[] }) => {
  const { t } = useTranslation()
  return (
    <MSection title={header}>
      <MJoinChildren divider={MDivider}>
        {skills.map(skill => (
          <SelectSkillSetDialogItem key={skill.id} skill={skill} />
        ))}
        {skills.length > 0 ? null : (
          <ListItem>
            <ListItemText primary={t(`No results`)} />
          </ListItem>
        )}
      </MJoinChildren>
    </MSection>
  )
}

const SelectSkillSetDialogItem = ({ skill }: { skill: SkillValue }) => {
  const field = useMField()
  const selectedSkills = field.value as SkillValue[]
  const uniqueId = Internals.useUniqueId(field.id ?? skill.id)
  const selected = keyed(selectedSkills).includes(skill)
  const primary = skill.name ?? skill.id
  const icon = (
    <MAvatar.Badge seed={skill.id.toString()} size='medium'>
      <IconSkill fontSize='large' />
    </MAvatar.Badge>
  )
  const check = selected ? (
    <Icon path={mdiRadioboxMarked} size={1} color={MColor.primary} />
  ) : (
    <Icon path={mdiRadioboxBlank} size={1} color={MTextColor.shadow} />
  )
  const handleClick = () => {
    if (selected) {
      const new_selected = selectedSkills.slice()
      keyed(new_selected).delete(skill)
      field.onChange?.(new_selected)
    } else {
      const new_selected = selectedSkills.slice()
      keyed(new_selected).delete(skill)
      keyed(new_selected).add(skill)
      field.onChange?.(new_selected)
    }
  }

  return (
    <MFlexBlockClickable id={uniqueId} gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <MFlexItem>{check}</MFlexItem>
      <MFlexItem>{icon}</MFlexItem>
      <MFlexItem grow={1}>
        <ListItemText primary={primary} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

export const SelectSkillSetDialogAvatars = ({ onClick }: { onClick?: () => void }) => {
  const field = useMField()
  const assignees = field.value as SkillValue[]
  const handleRemove = (assignee: MSelectItem<SkillValue>) => {
    const keys = assignees.slice()
    keyed(keys).delete(assignee.data)
    field.onChange?.(keys)
  }
  return (
    <Collapse in={assignees.length > 0} unmountOnExit>
      <MAvatar.MultiSelectList
        items={assignees.map(toSelectItem)}
        enableUngroup={() => false}
        enableRemove={() => true}
        onUngroup={() => {}}
        onRemove={handleRemove}
        onClick={onClick}
      />
    </Collapse>
  )
}

const toSelectItem = (value: SkillValue): MSelectItem<SkillValue> => {
  return {
    data: value,
    icon: <IconSkill fontSize='large' />,
    id: value.id?.toString(),
    primary: value.name ?? ``,
    secondary: value.code ?? null,
  }
}

const MFlexBlockClickable = styled(MFlexBlock)`
  cursor: pointer;
`

const keyed = <T extends { id: number }>(list: T[]) => {
  const byId = (item: { id: number }) => (other: { id: number }) => item.id === other.id
  return {
    add(item: T) {
      if (!list.some(byId(item))) {
        list.push(item)
      }
    },
    delete(item: { id: number }) {
      if (list.some(byId(item))) {
        list.splice(list.findIndex(byId(item)), 1)
      }
    },
    includes(item: { id: number }) {
      return list.some(byId(item))
    },
  }
}
