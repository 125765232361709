import { List, ListItemAvatar, ListItemText } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { MAvatar, spacing } from '@mprise/react-ui'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { LanguageDialog } from '../../shared/dialog/select-language-dialog'
import { StateLocale } from '../../state/locale'
import { StateSession } from '../../state/session'
import LanguageIcon from '@mui/icons-material/Language'
import { MpriseAuthProvider } from '@mprise/react-auth'
import styled from '@emotion/styled'
import { IconLogout } from '../../icons'
import { LocationOn } from '@mui/icons-material'
import { SelectCompanyDialog, CompanyValue } from '../../shared/select-company-dialog'
import { MPageTitle } from '../../shared/page-title'
import { useLazyQuery } from '@apollo/client'
import { GET_ONE_COMPANY } from '../../gql/companies'

export const SettingsPage = () => {
  const { t } = useTranslation()
  const [, dispatch] = useContext(StateSession.Context)
  const [localeState, localeDispatch] = useContext(StateLocale.Context)

  const [selectCompany, setSelectCompany] = useState<boolean>(false)
  const [selectLanguage, setSelectLanguage] = useState<boolean>(false)
  const currentCompanyId = useCurrentCompanyId()

  const [getCompanies, { data: company }] = useLazyQuery(GET_ONE_COMPANY)

  useEffect(() => {
    getCompanies({
      variables: {
        filter: {
          id: +currentCompanyId,
        },
      },
    })
  }, [currentCompanyId])

  const handleOpenCompanyDialog = () => setSelectCompany(true)
  const handleOpenLanguageDialog = () => setSelectLanguage(true)

  const { mpriseId, user } = MpriseAuthProvider.useContext()

  const handleLogout = () => {
    mpriseId?.logout()
  }

  return (
    <>
      <Helmet title={t('Settings')} />
      <SelectCompanyDialog
        open={selectCompany}
        title={t('SelectACompany')}
        onClose={() => setSelectCompany(false)}
        onSave={(company: CompanyValue | null) => {
          if (company) {
            dispatch({ setCompanyId: company.id.toString() })
          }
          setSelectCompany(false)
        }}
      />
      <LanguageDialog
        open={selectLanguage}
        onCancelled={() => {
          setSelectLanguage(false)
        }}
        onChanged={(language: string) => {
          setSelectLanguage(false)
          localeDispatch({ setLocale: language })
        }}
      />

      <MPageTitle label={t('Settings')} />
      <List>
        <ListItem button>
          <ListItemIcon>
            <MAvatar.Badge seed=''>
              <MAvatar.Icon.Resource />
            </MAvatar.Badge>
          </ListItemIcon>
          <ListItemText
            primary={user?.email ?? `(Name missing)`}
            secondary={(company && company.company.name) || t('NoCompanySelected')}
          />
        </ListItem>
        <ListItem button onClick={handleOpenCompanyDialog}>
          <ListItemIcon>
            <MAvatar.Badge seed={(company && company.company.id) || ``}>
              <LocationOn />
            </MAvatar.Badge>
          </ListItemIcon>
          <ListItemText
            primary={t('Company')}
            secondary={(company && company.company.name) || t('NoCompanySelected')}
          />
        </ListItem>
        <ListItem button onClick={handleOpenLanguageDialog}>
          <ListItemAvatar>
            <MAvatar.Badge seed={localeState.locale}>
              <LanguageIcon />
            </MAvatar.Badge>
          </ListItemAvatar>
          <ListItemText primary={t('Language')} secondary={t(`Language-${localeState.locale}`)} />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <StyledShadedIcon>
              <IconLogout />
            </StyledShadedIcon>
          </ListItemIcon>
          <ListItemText primary={t('Logout')} secondary={user?.email ?? `(Name missing)`} />
        </ListItem>
      </List>
    </>
  )
}

const StyledShadedIcon = styled.div`
  padding: ${spacing(1)};
  width: ${spacing(8)};
  height: ${spacing(8)};
  background-color: #00000010;
  border-radius: ${spacing(3)};
`
