import styled from '@emotion/styled'
import { Box, Collapse, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TuneIcon from '@mui/icons-material/Tune'
import { MAvatar, MFlexBlock, MText, MTextColor, spacing } from '@mprise/react-ui'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { RoleVisible } from '../auth'
import { MpriseAuthProvider } from '@mprise/react-auth'
import { useCurrentCompanyId } from '../shared/useCurrentCompany'
import {
  IconActivity,
  IconAgriware,
  IconSecurity,
  IconItem,
  IconJob,
  IconLocation,
  IconPosition,
  IconResource,
  IconResources,
  IconSkill,
  IconTeam,
  IconTrackingId,
  IconTrackingIdStatus,
  IconWork,
  IconWorkItemTemplate,
  IconInspect,
  IconCompression,
  IconContainers,
  HelpOutlineIcon,
  IconShoppingBasket,
  IconLocalShipping,
  IconSpacingMethods,
  IconSettings,
} from '../icons'
import logo from '../shared/logo-normal.svg'
import { StateSettings } from '../state/settings'
import { useLazyQuery } from '@apollo/client'
import { GET_ONE_COMPANY } from '../gql/companies'

export const AppDrawer = ({ onClose, onSelectCompany }: { onClose: () => void; onSelectCompany: () => void }) => {
  const { t } = useTranslation()
  const currentCompanyId = useCurrentCompanyId()
  const settings = useContext(StateSettings.Context)

  const [getCompanies, { data: company }] = useLazyQuery(GET_ONE_COMPANY)

  useEffect(() => {
    getCompanies({
      variables: {
        filter: {
          id: +currentCompanyId,
        },
      },
    })
  }, [currentCompanyId, getCompanies])

  const { user } = MpriseAuthProvider.useContext()

  return (
    <Box height='100%' flexDirection='column' display='flex'>
      <Box flex={1}>
        <List disablePadding>
          <ListItem button component={RouterLink} to='/' disableRipple onClick={onClose}>
            <img
              alt={t(`Mprise Agriware Logo`)}
              src={logo}
              style={{ width: `100%`, maxHeight: 48, padding: 12, paddingTop: 4, paddingBottom: 4 }}
            />
          </ListItem>

          <Divider />

          <ListItem button onClick={onSelectCompany}>
            <ListItemIcon>
              <MAvatar.Badge seed=''>
                <MAvatar.Icon.Resource />
              </MAvatar.Badge>
            </ListItemIcon>
            <ListItemText
              primary={user?.email ?? `(Name missing)`}
              secondary={company && company.company ? company.company.name : t('NoCompanySelected')}
            />
          </ListItem>

          <Divider />

          <RoleVisible role='ADMIN_AGRIWARE'>
            <MenuItemGroup icon={IconAgriware} name={t('Agriware Data')}>
              <ListItemPage to='/activities' icon={IconActivity} children={t('Activities')} onClick={onClose} />
              <ListItemPage to='/items' icon={IconItem} children={t('Items')} onClick={onClose} />
              <ListItemPage to='/reasons' icon={HelpOutlineIcon} children={t('Reasons')} onClick={onClose} />
              <ListItemPage to='/jobs' icon={IconJob} children={t('Jobs')} onClick={onClose} />
              <ListItemPage to='/locations' icon={IconLocation} children={t('Locations')} onClick={onClose} />
              <ListItemPage to='/positions' icon={IconPosition} children={t('Positions')} onClick={onClose} />
              <ListItemPage to='/resources' icon={IconResource} children={t('Resources')} onClick={onClose} />
              <ListItemPage to='/carrierids' icon={IconTrackingId} children={t('Carrier Ids')} onClick={onClose} />
              <ListItemPage to='/trackingIds' icon={IconTrackingId} children={t('Tracking Ids')} onClick={onClose} />
              <ListItemPage to='/lots' icon={IconTrackingId} children={t('Lots')} onClick={onClose} />
              <ListItemPage to='/genus' icon={IconItem} children={t('Genuses')} onClick={onClose} />
              <ListItemPage to='/variants' icon={IconItem} children={t('Variants')} onClick={onClose} />
              <ListItemPage to='/containers' icon={IconContainers} children={t('Containers')} onClick={onClose} />
              <ListItemPage
                to='/spacing-methods'
                icon={IconSpacingMethods}
                children={t('Spacing Methods')}
                onClick={onClose}
              />
            </MenuItemGroup>
          </RoleVisible>

          <Divider />

          <MenuItemGroup icon={IconItem} name={t('Greenhouse')}>
            <RoleVisible role='ADMIN_TEMPLATE'>
              <ListItemPage
                to='/greenhouse-templates'
                icon={IconWorkItemTemplate}
                children={t('Templates')}
                onClick={onClose}
              />
            </RoleVisible>
          </MenuItemGroup>

          <Divider />

          <MenuItemGroup icon={IconWork} name={t('Work')}>
            <RoleVisible role='ADMIN_TEAM'>
              <ListItemPage to='/teams' icon={IconResources} children={t('Teams')} onClick={onClose} />
            </RoleVisible>
            <RoleVisible role='ADMIN_PLANNINGROUP'>
              <ListItemPage
                to='/resource-planning-groups'
                icon={IconTeam}
                children={t('Planning Group')}
                onClick={onClose}
              />
            </RoleVisible>
            <RoleVisible role='ADMIN_AGRIWARE'>
              {/* TODO: Correct RoleVisible? */}
              <ListItemPage to='/skills' icon={IconSkill} children={t('Skills')} onClick={onClose} />
            </RoleVisible>
            <RoleVisible role='ADMIN_TEMPLATE'>
              <ListItemPage
                to='/work-item-templates'
                icon={IconWorkItemTemplate}
                children={t('Templates')}
                onClick={onClose}
              />
            </RoleVisible>
          </MenuItemGroup>

          <Divider />

          <MenuItemGroup icon={IconInspect} name={t('Inspect')}>
            <RoleVisible role='INSPECT_COMPRESSOR'>
              <ListItemPage to='/compression' icon={IconCompression} children={t('Compression')} onClick={onClose} />
            </RoleVisible>
          </MenuItemGroup>

          <Divider />

          <MenuItemGroup icon={IconLocalShipping} name={t('Logistics')}>
            <RoleVisible role='ADMIN_AGRIWARE'>
              <ListItemPage to='/auto-pick' icon={IconShoppingBasket} children={t('Auto Pick')} onClick={onClose} />
            </RoleVisible>
            <RoleVisible role='ADMIN_STATUS'>
              <ListItemPage
                to='/tracking-id-status'
                icon={IconTrackingIdStatus}
                children={t('Statuses')}
                onClick={onClose}
              />
            </RoleVisible>
          </MenuItemGroup>

          <Divider />

          <RoleVisible role='ADMIN_AGRIWARE'>
            <ListItemPage
              to='/configuration'
              icon={IconSettings}
              children={t('Settings')}
              onClick={onClose}
              nested={false}
            />

            <Divider />
          </RoleVisible>

          <RoleVisible role={['IDENTITYSERVER_ROLE_ADMIN', 'IDENTITYSERVER_USER_ADMIN']}>
            <MFlexBlock margin={[7, 4, 1, 4]}>
              <MText block textVariant='smallest' style={{ color: MTextColor.header, fontWeight: 400 }}>
                {t('Organization administration')}
              </MText>
            </MFlexBlock>

            <Divider />

            <MenuItemGroup icon={IconSecurity} name={t('Access control')}>
              <RoleVisible role='IDENTITYSERVER_USER_ADMIN'>
                <ListItemPage to='/users' icon={IconResource} children={t('Users')} onClick={onClose} />
              </RoleVisible>
              <RoleVisible role='IDENTITYSERVER_ROLE_ADMIN'>
                <ListItemPage to='/roles' icon={IconContainers} children={t('Roles')} onClick={onClose} />
              </RoleVisible>
            </MenuItemGroup>

            <Divider />
          </RoleVisible>
        </List>
      </Box>
      <Box>
        <List disablePadding>
          <ListItemPage nested={false} to='/settings' icon={TuneIcon} children={t('User settings')} onClick={onClose} />

          <ListItem dense>
            <ListItemText>
              <Typography variant='caption' color='textSecondary'>
                {settings.version}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}

const StyledShadedIcon = styled.div`
  padding: ${spacing(1)};
  width: ${spacing(8)};
  height: ${spacing(8)};
  background-color: #00000010;
  border-radius: ${spacing(3)};
`
function ListItemPage({
  children,
  icon: Icon,
  to,
  onClick,
  nested = true,
}: {
  nested?: boolean
  children: React.ReactNode
  icon: React.ComponentType
  to: string
  onClick: () => void
}) {
  return (
    <ListItem sx={nested ? nestedStyle : normal} button component={RouterLink} onClick={onClick} to={to}>
      <ListItemIcon>
        <StyledShadedIcon>
          <Icon />
        </StyledShadedIcon>
      </ListItemIcon>
      <ListItemText>{children}</ListItemText>
    </ListItem>
  )
}

const MenuItemGroup = ({
  children,
  icon: Icon,
  name,
}: {
  children: React.ReactNode
  icon: React.ComponentType
  name: any
}) => {
  const [open, setOpen] = useState(false)
  const handleToggle = () => setOpen(x => !x)

  return (
    <>
      <ListItem button onClick={handleToggle}>
        <ListItemIcon>
          <StyledShadedIcon>
            <Icon />
          </StyledShadedIcon>
        </ListItemIcon>
        <ListItemText>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box flex={1}>{name}</Box>
            {/* <ListSubheader disableGutters>{name}</ListSubheader> */}
            <Box display='flex'>{open ? <ExpandLessIcon color='action' /> : <ExpandMoreIcon color='action' />}</Box>
          </Box>
        </ListItemText>
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List dense disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  )
}

const nestedStyle = {
  paddingLeft: spacing(5),
}
const normal = {
  paddingLeft: spacing(4),
}
