import { MColor, MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { mdiMagnify } from '@mdi/js'
import Icon from '@mdi/react'
import { defined } from '../typescript'
import { useTranslation } from 'react-i18next'

export const FieldSelect = <T extends object>({
  selected,
  onClear,
  onExplore,
  displayProperty,
  disabled,
  allSelected,
}: {
  selected: T | T[] | null
  onClear: () => void
  onExplore: () => void
  displayProperty: keyof T
  disabled?: boolean
  allSelected?: boolean
}) => {
  const { t } = useTranslation()

  const selectedItems = [selected].flat().filter(defined) as T[]

  return (
    <MFlexBlock bgColor={MColor.white} variant='rounded' alignItems='center' grow={1} gap={2}>
      <MFlexItem
        grow={1}
        onClick={!disabled ? onExplore : undefined}
        style={{ ...(!disabled && { cursor: 'pointer' }) }}
      >
        <MText block style={{ ...(disabled && { color: 'rgba(0, 0, 0, 0.26)' }) }}>
          {allSelected
            ? t('All')
            : selectedItems.length > 0
              ? selectedItems.map(s => s[displayProperty]).join(', ')
              : '-'}
        </MText>
      </MFlexItem>
      {selected && (
        <IconButton onClick={onClear} size='small' disabled={disabled}>
          <CloseIcon fontSize='small' />
        </IconButton>
      )}
      <IconButton onClick={onExplore} disabled={disabled}>
        <Icon path={mdiMagnify} size={1} />
      </IconButton>
    </MFlexBlock>
  )
}
