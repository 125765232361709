import { BarcodeCompletedHandler, MField, useMField } from '@mprise/react-ui'
import { useEffect, useState } from 'react'
import { SelectSkillSetDialog, SelectSkillSetDialogAvatars, SkillValue } from '../dialog/select-skill-set-dialog'
import { FieldBarcode } from './field-barcode'
import { GET_SKILLS } from '../../gql/skills'
import { useLazyQuery } from '@apollo/client'
import { useCurrentCompanyId } from '../useCurrentCompany'

export const FieldSkillSet = ({ title }: { title: string }) => {
  const f = useMField()
  const companyId = useCurrentCompanyId()
  const selected = f.value as SkillValue[]
  const [open, setOpen] = useState(false)

  const [getSkills, { error: skillsError, loading: skillsLoading, data: skills }] = useLazyQuery(GET_SKILLS)

  if (skillsError) {
    console.error(skillsError)
  }

  useEffect(() => {
    if (skills) {
      f.onChange?.(skills.skills)
    }
  }, [skills])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const handleScanned: BarcodeCompletedHandler = ({ code }) => {
    getSkills({
      variables: {
        filter: {
          companyId: +companyId,
          id: code,
        },
      },
    })
  }

  return (
    <>
      <SelectSkillSetDialog
        initialValue={selected}
        open={open}
        onClose={handleClose}
        onSave={team => {
          f.onChange?.(team)
          setOpen(false)
        }}
        title={title}
      />
      <FieldBarcode
        selected={f.value}
        loading={skillsLoading}
        onExplore={handleOpen}
        renderLabel={() => (
          <MField.ScrollableContentX>
            <SelectSkillSetDialogAvatars />
          </MField.ScrollableContentX>
        )}
        showInstantScanButton={false}
        onClear={handleClear}
        onScanned={handleScanned}
      />
    </>
  )
}
