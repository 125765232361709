import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MFieldInput, MPortalDialog } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import * as uuid from 'uuid'
import { useParams } from 'react-router-dom'
import { useHistory } from '../../shared/use-history'
import * as yup from 'yup'
import { useCurrentCompanyCosmosKey, useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { AlertDialog, AlertType } from '../../shared/alert-dialog'
import { withFormikCompareFix } from '../../shared/formik'
import { LoadingSwitchPanel } from '../../shared/loading-switch-panel'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MutationErrorMessage } from '../../shared/react-apollo'
import { FormikDialog } from '../../shared/react-formik-dialog'
import { useLocalState } from '../../shared/react-local-state'
import { SavingSwitchPanel } from '../../shared/saving-switch-panel'
import { ValidationIssues } from '../../shared/validation-issues'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { GET_ONE_SKILL, GET_SKILLS, CREATE_SKILL, UPDATE_SKILL } from '../../gql/skills'
import { DialogContentText, TextField } from '@mui/material'

export interface SkillDialogForm {
  id: string
  companyId: string
  name: string
  description: string
  code: string
}

export const SkillDialog = () => {
  const params = useParams()
  const { t } = useTranslation()
  const h = useHistory()
  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlert = (alertType: AlertType) => setAlert(alertType)
  const handleAlertClose = () => setAlert(null)
  const companyId = useCurrentCompanyId()
  const cosmosKey = useCurrentCompanyCosmosKey()
  const skillId: string = params.skillId!

  const [getSkill, { error: skillError, loading: skillLoading, data: skillData }] = useLazyQuery(GET_ONE_SKILL, {
    variables: { filter: { id: +skillId } },
  })
  const { error: skillsError, data: skillsData } = useQuery(GET_SKILLS, {
    variables: { filter: { companyId: +companyId, removed: false } },
  })
  const [createSkill, { data: createData, loading: createLoading, error: createError }] = useMutation(CREATE_SKILL, {
    refetchQueries: [GET_SKILLS, 'skills'],
  })
  const [updateSkill, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(UPDATE_SKILL, {
    refetchQueries: [GET_SKILLS, 'skills'],
  })

  useEffect(() => {
    if (params.skillId) {
      getSkill({ variables: { filter: { id: +params.skillId } } })
    }
  }, [])

  if (skillError) {
    console.error('skillError', skillError)
  }
  if (skillsError) {
    console.error('skillsError', skillsError)
  }
  if (createError) {
    console.error('createError', createError)
  }
  if (updateError) {
    console.error('updateError', updateError)
  }

  const [initialValues] = useLocalState<SkillDialogForm>(() => {
    if (skillData) {
      return withFormikCompareFix({
        id: skillData.skill?.id ?? ``,
        companyId: skillData.skill?.company?.id ?? companyId,
        name: skillData.skill?.name ?? ``,
        code: skillData.skill?.code ?? ``,
        description: skillData.skill?.description ?? ``,
      })
    } else {
      return withFormikCompareFix({
        id: ``,
        companyId: cosmosKey,
        name: ``,
        code: ``,
        description: ``,
      })
    }
  }, [skillData, companyId])

  const [schema] = useState(() =>
    yup.object({
      id: yup.string(),
      companyId: yup.string(),
      name: yup.string().label(t(`Name`)).required(),
    }),
  )

  const handleClose = async () => {
    h.push(`/skills`)
  }

  const [removing, setRemoving] = useLocalState(false, [skillId])

  const handleSave = async (form: SkillDialogForm) => {
    if (createLoading || updateLoading) {
      return
    }
    if (removing) {
      setRemoving(false)
      updateSkill({
        variables: {
          id: skillData.skill.cosmosKey,
          input: {
            removed: true,
          },
        },
      })
      handleAlert('delete')
      handleClose()
      return
    }

    const skillAlreadyExists = skillsData.skills
      ?.filter((t: any) => t.id !== form.id)
      .some((t: any) => t.name === form.name)

    if (skillAlreadyExists) {
      handleAlert('skill')
      return
    }

    if (form.id) {
      handleAlert('edit')

      updateSkill({
        variables: {
          id: skillData.skill.cosmosKey,
          input: {
            name: form.name,
            description: form.description,
            code: form.code,
          },
        },
      })

      handleClose()
    } else {
      handleAlert('create')

      createSkill({
        variables: {
          input: {
            companyId: form.companyId,
            name: form.name,
            description: form.description,
            code: form.code,
            externalId: uuid.v4(),
          },
        },
      })

      handleClose()
    }
  }

  const onConfirmDelete: React.FormEventHandler = async e => {
    e.preventDefault()
    if (updateLoading) return
    updateSkill({
      variables: {
        id: skillData.skill.cosmosKey,
        input: {
          removed: true,
        },
      },
    })
    handleAlert('delete')
    handleClose()
  }

  return (
    <>
      <AlertDialog alert={alert} entity={`Skill`} handleClose={handleAlertClose} />
      <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleSave}>
        <FormikDialog
          title={initialValues.id ? t(`Edit Skill`) : t(`Add Skill`)}
          submit={initialValues.id ? t(`Save`) : t(`Create`)}
          removeChildren={initialValues.id && !removing ? t(`ACTION_REMOVE`) : null}
          open
          onClose={handleClose}
          minWidth='sm'
          onRemove={() => setRemoving(true)}
        >
          <SavingSwitchPanel
            mutation={
              [
                /*createSkillState, editSkillState*/
              ]
            }
          >
            <LoadingSwitchPanel loadingAnyway={skillLoading} query={null}>
              <ValidationIssues />
              {/* <QueryErrorMessage query={skillQuery} /> */}
              <MutationErrorMessage
                mutation={
                  [
                    /*createSkillState, editSkillState*/
                  ]
                }
              />
              {removing ? (
                <MPortalDialog
                  open={true}
                  title={t(`DialogDeleteSkillTitle`)}
                  submit={t(`DialogDeleteSkillTitle`)}
                  onSubmit={onConfirmDelete}
                  onClose={handleClose}
                >
                  <DialogContentText>{t('SkillDeleteDialogHint', { name: skillData?.skill?.name })}</DialogContentText>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label={t('Name')}
                    value={skillData?.skill?.name ?? ``}
                    InputProps={{ readOnly: true }}
                  />
                </MPortalDialog>
              ) : (
                <></>
              )}
              <Field component={MFieldConnector} name='name' label={t(`Skill`)}>
                <MFieldInput />
              </Field>
              <Field component={MFieldConnector} name='code' label={t(`Code`)}>
                <MFieldInput />
              </Field>
              <Field component={MFieldConnector} name='description' label={t(`Description`)}>
                <MFieldInput multiline />
              </Field>
            </LoadingSwitchPanel>
          </SavingSwitchPanel>
        </FormikDialog>
      </Formik>
    </>
  )
}
