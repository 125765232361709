import { useMemo } from 'react'

const emptyArray: any[] = []

export type Collector<T> = (item: T) => Array<string | undefined | null> | string | undefined | null

export const useSelectiveSearch = <T extends unknown>(
  searchText: string,
  data: Array<T> = emptyArray,
  ...collector: Array<Collector<T>>
): Array<T> => useMemo(() => selectiveSearch(searchText, data, collector), [data, searchText])

const selectiveSearch = <T extends unknown>(
  searchText: string,
  items: Array<T>,
  collector: Array<Collector<T>>,
): Array<T> => {
  if (searchText === '') {
    return items
  }
  const searchMatches = makeWildcard(searchText)
  return items.filter(item => collector.flatMap(c => c(item)).some(searchMatches))
}

const makeWildcard = (text: string) => {
  const toWildcard = (text: string) =>
    text
      .replace(/[-[\]{}()+.,\\^$|#\s]/g, `\\$&`)
      .replace(/\?/g, `.`)
      .replace(/\*/g, `.*`)

  const toMatchSomewhere = (text: string) => `(?=.*${text})`

  const r = new RegExp(`^${text.split(/\s+/).map(toWildcard).map(toMatchSomewhere).join(``)}`, `i`)
  return (text: string | null | undefined) => Boolean(text?.match(r))
}
