import { gql } from '@apollo/client'

export const GET_ITEMS = gql`
  query Items($filter: ItemFilter, $limit: Int, $offset: Int) {
    items(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
        genus
        cosmosKey
      }
      total
    }
  }
`
