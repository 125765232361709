import React, { useMemo } from 'react'

export interface Settings {
  version: string
  gateway: string
  login: string
  clientId: string
  clientSecret: string
  instrumentation: string
}

export const StateSettings = ({ children }: { children: React.ReactNode }) => {
  const userManager = useMemo(
    () => ({
      version: getVersion(),
      gateway: getGateway(),
      login: getLogin(),
      clientId: getClientId(),
      clientSecret: getClientSecret(),
      instrumentation: getInstrumentationKey(),
    }),
    [],
  )
  return <StateSettings.Context.Provider value={userManager}>{children}</StateSettings.Context.Provider>
}
StateSettings.Context = React.createContext<Settings>({
  version: ``,
  gateway: ``,
  login: ``,
  clientId: ``,
  clientSecret: ``,
  instrumentation: ``,
})

const getVersion = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-version"]`) as HTMLMetaElement)?.content ?? ``
const getGateway = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-gateway"]`) as HTMLMetaElement)?.content ?? ``
const getLogin = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-login"]`) as HTMLMetaElement)?.content ?? ``
const getClientId = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-clientId"]`) as HTMLMetaElement)?.content ?? ``
const getClientSecret = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-clientSecret"]`) as HTMLMetaElement)?.content ?? ``
const getInstrumentationKey = () =>
  (global.window?.document?.querySelector(`meta[name="mprise-instrumentationKey"]`) as HTMLMetaElement)?.content ?? ``
