import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { Clear as ClearIcon, Search as SearchIcon, Add as AddIcon } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, px } from '@mprise/react-ui'
import { ResourcePlanningGroupDialogCreate } from './rpg-dialog.create'
import { AlertDialog, AlertType } from '../../shared/alert-dialog'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import { RoleVisible, useRoleAvailable } from '../../auth'
import { MPageTitle } from '../../shared/page-title'
import { useLazyQuery } from '@apollo/client'
import { GET_ALL_RPGS } from '../../gql/rpgs'
import { useDebounceValue } from '../../shared/debounce-value'
import { SimpleDataGrid } from '../../shared/simple-data-grid'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import ResourcePlanningGroupDialogDelete from './rpg-dialog-delete'
import { ResourcePlanningGroupDialogEdit } from './rpg-dialog.edit'
import { GridColDef } from '@mui/x-data-grid'
import { defined } from '../../shared/typescript'
import { QueryErrorMessage } from '../../shared/react-apollo'

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'description', headerName: 'Description', width: 250 },
  {
    field: 'workItemTypes',
    headerName: 'Work Item Types',
    width: 250,
    valueGetter: params => params.row?.workItemTypes.join(', '),
  },
  {
    field: 'teams',
    headerName: 'Teams',
    width: 250,
    valueGetter: params =>
      params.row?.teams
        .filter(defined)
        .map((t: any) => t.name)
        .join(', '),
  },
  {
    field: 'locations',
    headerName: 'Locations',
    width: 250,
    valueGetter: params =>
      params.row?.locations.length + (params.row?.locations.length === 1 ? ' location' : ' locations'),
  },
]

export const ResourcePlanningGroupRoute = () => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const isPlanningGroupAdmin = useRoleAvailable('ADMIN_PLANNINGROUP_ADMIN')

  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlert = (alertType: AlertType) => setAlert(alertType)
  const handleAlertClose = () => setAlert(null)

  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounceValue(search, 500)
  const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => setSearch(evt.target.value)

  const [isCreating, setCreating] = useState<boolean>(false)
  const [isEditing, toggleEdit] = useState<string>('')
  const [isDeleting, toggleDelete] = useState<string>('')

  const [getRpgs, { data, loading, error }] = useLazyQuery(GET_ALL_RPGS, {
    onError: console.error,
  })

  useEffect(() => {
    getRpgs({
      variables: {
        filter: {
          companyId: +companyId,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          removed: false,
        },
      },
    })
  }, [companyId, debouncedSearch, getRpgs])

  const handleEdit = (id: string) => {
    if (isPlanningGroupAdmin) {
      toggleEdit(id)
    }
  }

  const handleDelete = (id: string) => {
    if (isPlanningGroupAdmin) {
      toggleDelete(id)
    }
  }

  const handleClose = () => {
    toggleEdit('')
    toggleDelete('')
  }

  return (
    <>
      <Helmet title={t('Resource Planning Groups')} />
      <AlertDialog alert={alert} entity={t('Resource Planning Group')} handleClose={handleAlertClose} />

      <ResourcePlanningGroupDialogCreate
        open={isCreating}
        onClose={() => setCreating(false)}
        handleAlert={handleAlert}
        rpgs={data?.resourcePlanningGroups}
      />
      <ResourcePlanningGroupDialogEdit
        open={!!isEditing}
        id={isEditing}
        rpgs={data}
        onClose={handleClose}
        onDelete={handleDelete}
        handleAlert={handleAlert}
        key={'isEditing'}
      />
      <ResourcePlanningGroupDialogDelete
        open={!!isDeleting}
        groupId={isDeleting}
        onClose={handleClose}
        handleAlert={handleAlert}
        key={'isDeleting'}
      />

      <MPageTitle label={t('Resource Planning Groups')} />
      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <MFlexItem minWidth='auto' grow={10} shrink={2}>
            <RoleVisible role='ADMIN_PLANNINGROUP_ADMIN'>
              <Box mr={1} mt={1}>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  startIcon={<AddIcon />}
                  children={t('DialogCreateRPGTitle')}
                  onClick={() => setCreating(true)}
                />
              </Box>
            </RoleVisible>
          </MFlexItem>
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={() => setSearch('')}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
        </MFlex>

        <QueryErrorMessage query={{ loading, error }} />

        <SimpleDataGrid
          columns={columns}
          data={data?.resourcePlanningGroups}
          rowCount={data?.resourcePlanningGroups?.length}
          onRowClick={(record: any) => handleEdit(record.id)}
          loading={loading}
        />
      </MFlexBlock>
    </>
  )
}
