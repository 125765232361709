import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { IconButton, TextField } from '@mui/material'
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, px } from '@mprise/react-ui'
import { RefreshIcon } from '../../shared/refresh-icon'
import { useLazyQuery } from '@apollo/client'
import { GET_POSITIONS } from '../../gql/positions'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { DEFAULT_PAGE_SIZE, SimpleDataGrid } from '../../shared/simple-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { QueryErrorMessage } from '../../shared/react-apollo'

export const PositionList = () => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const handleSearch = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => setSearch(e.target.value), [])
  const [search, setSearch] = useState(``)
  const debouncedSearch = useDebounceValue(search, 500)

  const [getPositions, { data, loading, error, fetchMore }] = useLazyQuery(GET_POSITIONS, {
    onError: console.error,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    getPositions({
      variables: {
        filter: {
          companyId: +companyId,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          removed: false,
        },
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
      },
    })
  }, [companyId, debouncedSearch, getPositions])

  // TODO: Replace control that requires refetch or make a pagination in backend
  const refetch = () => {}

  return (
    <>
      <Helmet title={t('Positions')} />

      <MPageTitle label={t('Positions')} />
      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <MFlexItem minWidth='auto' grow={10} shrink={2} />
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={() => setSearch(``)}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
          <IconButton onClick={refetch}>
            <RefreshIcon loading={loading} />
          </IconButton>
        </MFlex>

        <QueryErrorMessage query={{ loading, error }} />

        <SimpleDataGrid
          columnNames={['code', 'name']}
          data={data?.positions.page}
          rowCount={data?.positions?.total}
          loading={loading}
          fetchMore={fetchMore}
        />
      </MFlexBlock>
    </>
  )
}
