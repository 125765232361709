import React, { useState } from 'react'
import { Internals, MPortalDialog, MFieldProvider, MFlex, SearchBox, MFlexBlock } from '@mprise/react-ui'
import { LoadingSwitchPanel } from '../loading-switch-panel'
import { useLocalState } from '../../shared/react-local-state'
import { SavingSwitchPanel } from '../saving-switch-panel'
import { useScanningSetting } from '../useScanningSetting'

export const SearchAndSelectDialog = <T,>({
  children,
  loading = false,
  initialValue,
  gap = 5,
  title,
  open,
  text: search,
  onChange: handleSearchChange,
  onClose,
  onSave,
}: {
  children: React.ReactNode
  loading?: boolean
  gap?: number
  initialValue: T
  title: React.ReactNode
  open: boolean
  text?: string
  onChange?: (text: string) => void
  onClose: () => void
  onSave: (value: T) => Promise<void> | void
}) => {
  const [saving, setSaving] = useState(false)
  const [selected, setSelected] = useLocalState(initialValue, [initialValue, open])
  const uniqueId = Internals.useUniqueId(`selectdialog`)
  const handleSubmit: React.FormEventHandler = async e => {
    e.preventDefault()
    e.stopPropagation()
    if (loading) {
      return
    }
    if (selected) {
      setSaving(true)
      try {
        await onSave(selected)
      } finally {
        setSaving(false)
      }
    }
  }

  const { scanningSetting } = useScanningSetting()

  return (
    <MPortalDialog.Dialog open={open} onClose={onClose}>
      <MPortalDialog.Form onSubmit={handleSubmit}>
        <SavingSwitchPanel mutation={[]} savingAnyway={saving}>
          <LoadingSwitchPanel query={[]} loadingAnyway={loading}>
            <MFlex vertical gap={gap}>
              <MPortalDialog.Header onClose={onClose}>
                <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)', lineHeight: 'inherit' }}>
                  {title}
                </MFlexBlock>
              </MPortalDialog.Header>
              {handleSearchChange && (
                <SearchBox
                  autoFocus
                  value={search ?? ''}
                  scanningSetting={scanningSetting}
                  status={0}
                  onChange={handleSearchChange}
                />
              )}
              <MFieldProvider id={uniqueId} name='selected' value={selected} onChange={setSelected}>
                {children}
              </MFieldProvider>
              <MPortalDialog.Footer>
                <MFlexBlock justifyContent='flex-end'>
                  <MPortalDialog.SubmitButton>Confirm</MPortalDialog.SubmitButton>
                </MFlexBlock>
              </MPortalDialog.Footer>
            </MFlex>
          </LoadingSwitchPanel>
        </SavingSwitchPanel>
      </MPortalDialog.Form>
    </MPortalDialog.Dialog>
  )
}
