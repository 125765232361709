import { DialogContentText, TextField } from '@mui/material'
import { MPortalDialog } from '@mprise/react-ui'
import { FormEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertType } from '../../shared/alert-dialog'
import { FullRPG } from './rpg-dialog.edit'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_RPG, GET_ALL_RPGS, DELETE_RPG } from '../../gql/rpgs'

const ResourcePlanningGroupDialogDelete = ({
  groupId,
  onClose,
  open,
  handleAlert,
}: {
  handleAlert: (alertType: AlertType) => void
  groupId: string
  onClose: () => any
  open: boolean
}) => {
  const [getRpg, { error: rpgError, loading: rpgLoading, data: rpg }] = useLazyQuery(GET_RPG)

  if (rpgError) {
    console.error('rpgError', rpgError)
  }

  const [deleteRpg, { data: deleteData, error: deleteError }] = useMutation(DELETE_RPG, {
    refetchQueries: [GET_ALL_RPGS, 'resourcePlanningGroups'],
  })

  if (deleteError) {
    console.error('deleteError', deleteError)
  }

  useEffect(() => {
    if (groupId) {
      getRpg({
        variables: {
          filter: {
            id: +groupId,
          },
        },
      })
    }
  }, [groupId])

  useEffect(() => {
    if (deleteData) {
      handleAlert('delete')
      onClose()
    }
  }, [deleteData])

  const handleConfirm: React.FormEventHandler = async e => {
    e.preventDefault()
    deleteRpg({
      variables: {
        id: +groupId,
      },
    })
  }

  return (
    <ResourcePlanningGroupDialogDeleteDialog
      group={rpg && rpg.resourcePlanningGroup}
      onConfirm={handleConfirm}
      onClose={onClose}
      open={open}
      loading={rpgLoading}
    />
  )
}

const ResourcePlanningGroupDialogDeleteDialog = ({
  group,
  onConfirm,
  onClose,
  open,
}: {
  group: FullRPG | null
  onConfirm: (e: FormEvent) => any
  onClose: () => any
  open: boolean
  loading: boolean
}) => {
  const { t } = useTranslation()

  return (
    <MPortalDialog
      open={open}
      title={t(`DialogDeleteRPGTitle`)}
      submit={t(`DialogDeleteRPGTitle`)}
      onSubmit={onConfirm}
      onClose={onClose}
    >
      <DialogContentText>{t('DialogDeleteRPGHint', { name: group?.name })}</DialogContentText>
      <TextField
        fullWidth
        variant='outlined'
        label={t('Name')}
        value={group?.name ?? ``}
        InputProps={{ readOnly: true }}
      />
    </MPortalDialog>
  )
}

export default ResourcePlanningGroupDialogDelete
