import styled from '@emotion/styled'
import { Divider, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { mdiMagnify, mdiRefresh } from '@mdi/js'
import Icon from '@mdi/react'
import { BarcodeCompletedHandler, InstantScanButton, MColor, MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import React from 'react'

export const FieldBarcode = <T extends unknown>({
  selected,
  loading,
  renderLabel,
  showInstantScanButton,
  onClear,
  onExplore,
  onScanned,
}: {
  selected: T | null
  loading: boolean
  renderLabel: (item: T) => React.ReactNode
  showInstantScanButton: boolean
  onClear?: () => void
  onExplore?: () => void
  onScanned: BarcodeCompletedHandler
}) => {
  const isClear = selected === undefined || selected === null || (Array.isArray(selected) && selected.length === 0)
  const handleClear = () => onClear?.()
  const handleExplore = () => onExplore?.()
  const handleScanned = onScanned
  return (
    <MFlexBlock bgColor={MColor.white} variant='rounded' alignItems='center' grow={1} gap={2}>
      {loading && <Icon spin={0.8} path={mdiRefresh} size={1} color={MColor.primary} />}
      <MFlexItemClickable grow={1} onClick={handleExplore}>
        {isClear ? (
          <MText block textVariant='content bold'>
            -
          </MText>
        ) : (
          renderLabel(selected!)
        )}
      </MFlexItemClickable>
      {onClear && !isClear && (
        <IconButton onClick={handleClear}>
          <CloseIcon />
        </IconButton>
      )}
      <Divider sx={divider} orientation='vertical' />
      {showInstantScanButton && <InstantScanButton onScanned={handleScanned} />}
      {onExplore && (
        <IconButton onClick={handleExplore}>
          <Icon path={mdiMagnify} size={1} />
        </IconButton>
      )}
    </MFlexBlock>
  )
}

const MFlexItemClickable = styled(MFlexItem)`
  cursor: pointer;
`

const divider = {
  height: 28,
  margin: 4,
}
