import { MBlock, MText } from '@mprise/react-ui'

export const DashboardRoute = () => {
  return (
    <MBlock padding={5}>
      <MText block textVariant='header slim'>
        Welcome to Agriware Administration Portal
      </MText>
    </MBlock>
  )
}
