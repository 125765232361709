import { FormControlLabel, Switch } from '@mui/material'
import { MText, MTextColor, useMField } from '@mprise/react-ui'
import React from 'react'

export const FieldSwitch = ({ label }: { label: React.ReactNode }) => {
  const f = useMField()

  const checked = Boolean(f.value)
  const handleChange = (checked: boolean) => {
    f.onChange?.(checked)
  }

  return (
    <FormControlLabel
      label={label}
      control={
        <Switch
          color='primary'
          name={f.name ?? undefined}
          checked={checked}
          onChange={(_, checked) => handleChange(checked)}
        />
      }
    />
  )
}

export const FieldArraySwitch = <T extends unknown>({
  label,
  value,
  disabled,
}: {
  label: React.ReactNode
  value: T
  disabled?: boolean
}) => {
  const f = useMField()

  const checked = f.value.includes(value)
  const handleChange = (checked: boolean) => {
    const selectedSet = new Set(f.value)
    if (checked) {
      selectedSet.add(value)
    } else {
      selectedSet.delete(value)
    }
    f.onChange?.(Array.from(selectedSet).filter(Boolean))
  }

  return (
    <FormControlLabel
      label={
        <MText block textColor={MTextColor.dark}>
          {label}
        </MText>
      }
      control={
        <Switch
          color='primary'
          name={f.name ?? undefined}
          checked={checked}
          onChange={(_, checked) => handleChange(checked)}
          disabled={disabled}
        />
      }
    />
  )
}
