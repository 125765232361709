import { MutationResult } from '@apollo/client'
import styled from '@emotion/styled'
import { Button, Collapse, IconButton, ListItem, ListItemText } from '@mui/material'
import { mdiAccount, mdiAccountMultiple, mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'
import Icon from '@mdi/react'
import { MpriseAuthProvider } from '@mprise/react-auth'
import {
  Internals,
  MAvatar,
  MBlock,
  MColor,
  MDivider,
  MFlexBlock,
  MFlexItem,
  MJoinChildren,
  MTextColor,
  PaginatedSearchAndSelect,
  useMField,
} from '@mprise/react-ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwitchTransition } from 'react-transition-group'
import { useCurrentCompanyId } from '../useCurrentCompany'
import { useDebounceValue } from '../debounce-value'
import { MSection, MSections } from '../msection'
import { MutationErrorMessage, QueryErrorMessage } from '../react-apollo'
import { useLocalState } from '../react-local-state'
import { defined, Maybe } from '../typescript'
import { SearchAndSelectDialogCustom } from './search-and-select-dialog-custom'
import { TeamResourceMode } from '../../lib/enums'
import { GET_RESOURCES } from '../../gql/resource'
import { GET_TEAMS } from '../../gql/teams'
import { useLazyQuery } from '@apollo/client'
import { DEFAULT_PAGE_SIZE } from '../simple-data-grid'

type TeamValue = {
  __typename: 'Team'
  id: number
  name?: Maybe<string> | undefined
  resourceCount?: Maybe<number> | undefined
  resources: Maybe<{
    __typename: 'Resource'
    id: number
    code: Maybe<string>
    name: Maybe<string>
    type: Maybe<string>
    jobTitle: Maybe<string>
    initials: Maybe<string>
  }>[]
}

type ResourceValue = {
  __typename: 'Resource'
  id: number
  code: Maybe<string> | undefined
  name: Maybe<string> | undefined
  type: Maybe<string> | undefined
}

export type AssigneeType = 'resource' | 'team'

export type AssigneeValue<Type = AssigneeType> = {
  id?: Maybe<number>
  code?: Maybe<string>
  name?: Maybe<string>
  type: Type
  resources?: Array<{
    id?: Maybe<number>
    code?: Maybe<string>
    type: `resource`
    name?: Maybe<string>
  }>
  resourceCount?: Maybe<number>
}

export const toAssigneeValue = (item: TeamValue | ResourceValue): AssigneeValue => {
  if (item?.__typename === `Resource`) {
    return { ...item, type: `resource` }
  } else if (item?.__typename === `Team`) {
    return {
      ...item,
      type: `team`,
      resources: (item.resources ?? [])
        .map((r): AssigneeValue<'resource'> => ({ ...r, type: `resource` }))
        .filter(defined),
    }
  } else {
    throw Error(`unexpected assignee type`)
  }
}

export type AssigneeTeamOptions = {
  exceptWithResourceId?: string
  resourceMode?: TeamResourceMode
}

export const SelectAssigneeDialog = ({
  initialValue,
  accept,
  title,
  loading,
  open,
  type,
  mutations,
  teamFilterOnResourceMode,
  onClose,
  onSave,
}: {
  initialValue?: AssigneeValue
  accept?: Array<AssigneeType>
  teamOptions?: AssigneeTeamOptions
  title: React.ReactNode
  loading?: boolean
  open: boolean
  type?: string
  mutations?: Array<MutationResult>
  teamFilterOnResourceMode?: TeamResourceMode
  onClose: () => void
  onSave: (selected: AssigneeValue) => Promise<void> | void
}) => {
  const { mpriseId } = MpriseAuthProvider.useContext()

  const handleLogout = () => {
    mpriseId?.logout()
  }

  const acceptResource = accept ? accept.includes(`resource`) : true
  const acceptTeam = accept ? accept.includes(`team`) : true

  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500)

  const [getTeams, { error: teamsError, loading: teamsLoading, data: teams }] = useLazyQuery(GET_TEAMS)
  const [getResources, { error: resourcesError, loading: resourcesLoading, data: resources }] =
    useLazyQuery(GET_RESOURCES)

  if (teamsError) {
    console.error(teamsError)
  }

  if (resourcesError) {
    console.error(resourcesError)
  }

  useEffect(() => {
    if (acceptTeam) {
      getTeams({
        variables: {
          filter: {
            companyId: +companyId,
            resourceMode: teamFilterOnResourceMode ?? null,
            ...(debouncedSearch && { searchString: debouncedSearch }),
          },
        },
      })
    }

    if (acceptResource) {
      getResources({
        variables: {
          filter: {
            companyId: +companyId,
            type: type,
            removed: false,
            ...(debouncedSearch && { searchString: debouncedSearch }),
          },
          limit: DEFAULT_PAGE_SIZE,
          offset: 0,
        },
      })
    }
  }, [debouncedSearch])

  const initialTab = acceptResource ? `resource` : `team`
  const [dialogInitialValue] = useLocalState(initialValue, [open])
  const [tab, setTab] = useLocalState<AssigneeType>(initialTab, [open, initialTab])
  return (
    <SearchAndSelectDialogCustom
      loading={loading}
      initialValue={dialogInitialValue}
      gap={0}
      open={open}
      title={title}
      text={search}
      onClose={onClose}
      onSave={(selected: any) => selected && onSave(selected)}
      onChange={setSearch}
      footer={
        resourcesError || (resources?.resources?.page?.length ?? 0) === 0 ? (
          <Button fullWidth variant='contained' color='primary' onClick={handleLogout}>
            {t(`Logout`)}
          </Button>
        ) : null
      }
    >
      <QueryErrorMessage
        query={[
          { error: teamsError, loading: teamsLoading },
          { error: resourcesError, loading: resourcesLoading },
        ]}
      />
      <MutationErrorMessage mutation={mutations ?? []} />
      {acceptResource && acceptTeam ? (
        <MFlexBlock justifyContent='space-between'>
          <MFlexItem>
            <IconButton onClick={() => setTab(`resource`)}>
              <Icon path={mdiAccount} size={1} />
            </IconButton>
          </MFlexItem>
          <MFlexItem>
            <IconButton onClick={() => setTab(`team`)}>
              <Icon path={mdiAccountMultiple} size={1} />
            </IconButton>
          </MFlexItem>
        </MFlexBlock>
      ) : (
        <MBlock padding={2.5} />
      )}
      <SwitchTransition>
        <Collapse key={tab}>
          {tab === `resource` ? (
            <MSections>
              {search ? (
                resourcesLoading ? (
                  <PaginatedSearchAndSelect.LoadingMessage />
                ) : (
                  <ResourceList header={t(`Results`)} resources={resources && resources.resources.page} />
                )
              ) : null}
              {/* TODO:  CHANGE TO resourcesRecent */}
              {/* {!search ? (
                <ResourceList header={t(`Recently Used`)} resources={resources && resources.resources.slice(0, 5)} />
              ) : null} */}
              {!search ? (
                resourcesLoading ? (
                  <PaginatedSearchAndSelect.LoadingMessage />
                ) : (
                  <ResourceList header={t(`All`)} resources={resources && resources.resources.page} />
                )
              ) : null}
              {/* <NextPageView cursor={nextResourceCursor} fetch={fetchResourceCursor} /> */}
            </MSections>
          ) : (
            <MSections>
              {search ? (
                teamsLoading ? (
                  <PaginatedSearchAndSelect.LoadingMessage />
                ) : (
                  <TeamList
                    header={t(`Results`)}
                    teams={teams.teams}
                    onSave={selected => selected && onSave(selected)}
                  />
                )
              ) : null}
              {/* TODO: CHANGE TO teamsRecent */}
              {!search ? (
                <TeamList
                  header={t(`Recently Used`)}
                  teams={teams && teams.teams.slice(0, 5)}
                  onSave={selected => selected && onSave(selected)}
                />
              ) : null}
              {!search ? (
                teamsLoading ? (
                  <PaginatedSearchAndSelect.LoadingMessage />
                ) : (
                  <TeamList header={t(`All`)} teams={teams.teams} onSave={selected => selected && onSave(selected)} />
                )
              ) : null}
              {/* <NextPageView cursor={nextTeamCursor} fetch={fetchTeamCursor} /> */}
            </MSections>
          )}
        </Collapse>
      </SwitchTransition>
    </SearchAndSelectDialogCustom>
  )
}
SelectAssigneeDialog.useDialogState = <C extends unknown>(
  onSelected: (assignee: AssigneeValue, context: C | undefined) => void,
) => {
  const [state, setState] = useState({
    context: undefined as C | undefined,
    loading: false,
    open: false,
    assignee: undefined as AssigneeValue | undefined | undefined,
  })
  return {
    context: state.context,
    loading() {
      setState({
        open: true,
        loading: true,
        assignee: undefined,
        context: undefined,
      })
    },
    open(assignee: AssigneeValue, context?: C) {
      setState({ open: true, loading: false, assignee, context })
    },
    close() {
      setState(s => ({ ...s, open: false }))
    },
    props: {
      initialValue: state.assignee,
      open: state.open,
      loading: state.loading,
      onClose: () => setState(s => ({ ...s, open: false })),
      onSave: async (a: AssigneeValue) => {
        await onSelected(a, state.context)
        setState(s => ({ ...s, open: false }))
      },
    },
  }
}

const ResourceList = ({ header, resources }: { header: React.ReactNode; resources: ResourceValue[] }) => {
  const { t } = useTranslation()
  return (
    <MSection title={header}>
      <MJoinChildren divider={MDivider}>
        {resources?.map(resource => <ResourceItem key={resource.id} resource={resource} />)}
        {resources?.length > 0 ? null : (
          <ListItem>
            <ListItemText primary={t(`No results`)} />
          </ListItem>
        )}
      </MJoinChildren>
    </MSection>
  )
}

const TeamList = ({
  header,
  teams,
  onSave,
}: {
  header: React.ReactNode
  teams: TeamValue[]
  onSave: (value: AssigneeValue) => Promise<void> | void
}) => {
  const { t } = useTranslation()
  return (
    <MSection title={header}>
      <MJoinChildren divider={MDivider}>
        {teams.map(team => (
          <TeamItem key={team.id} team={team} onSave={selected => selected && onSave(selected)} />
        ))}
        {teams.length > 0 ? null : (
          <ListItem>
            <ListItemText primary={t(`No results`)} />
          </ListItem>
        )}
      </MJoinChildren>
    </MSection>
  )
}

const ResourceItem = ({ resource }: { resource: ResourceValue }) => {
  const { t } = useTranslation()
  const field = useMField()
  const res = field.value as AssigneeValue | undefined
  const uniqueId = Internals.useUniqueId(field.id ?? resource.id)
  const selected = res?.id === resource.id
  const primary =
    resource?.name && resource?.code
      ? `${resource.name} - ${resource.code}`
      : resource?.name ?? resource?.code ?? t('PLACEHOLDER_NOT_SET')
  const secondary = <>{resource.type ?? t(`PLACEHOLDER_NOT_SET`)}</>
  const icon = (
    <MAvatar.Badge seed={resource.id.toString()} size='medium'>
      <MAvatar.Icon.Resource />
    </MAvatar.Badge>
  )
  const check = selected ? (
    <Icon path={mdiRadioboxMarked} size={1} color={MColor.primary} />
  ) : (
    <Icon path={mdiRadioboxBlank} size={1} color={MTextColor.shadow} />
  )
  const handleClick = () => {
    if (selected) {
      field.onChange?.(undefined)
    } else {
      const assignee = { ...resource, type: `resource` }
      field.onChange?.(assignee)
    }
  }
  return (
    <MFlexBlockClickable id={uniqueId} gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <MFlexItem>{check}</MFlexItem>
      <MFlexItem>{icon}</MFlexItem>
      <MFlexItem grow={1}>
        <ListItemText primary={primary} secondary={secondary} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

const TeamItem = ({ team }: { team: TeamValue; onSave: (value: AssigneeValue) => Promise<void> | void }) => {
  const field = useMField()
  const assignee = field.value as AssigneeValue | undefined
  const uniqueId = Internals.useUniqueId(field.id ?? team.id)
  const selected = assignee?.id === team.id
  const primary = team.name ?? team.id
  const secondary = `${team.resources?.length || team.resourceCount} members`
  const icon = (
    <MAvatar.Badge seed={team.id.toString()} size='medium'>
      <MAvatar.Icon.Team />
    </MAvatar.Badge>
  )
  const check = selected ? (
    <Icon path={mdiRadioboxMarked} size={1} color={MColor.primary} />
  ) : (
    <Icon path={mdiRadioboxBlank} size={1} color={MTextColor.shadow} />
  )
  const handleClick = () => {
    if (selected) {
      field.onChange?.(undefined)
    } else {
      const assignee = {
        ...team,
        type: `team`,
        resources: team.resources?.map(x => ({
          ...x,
          type: `resource`,
        })),
      }
      field.onChange?.(assignee)
    }
  }

  return (
    <MFlexBlockClickable id={uniqueId} gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <MFlexItem>{check}</MFlexItem>
      <MFlexItem>{icon}</MFlexItem>
      <MFlexItem grow={1}>
        <ListItemText primary={primary} secondary={secondary} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

const MFlexBlockClickable = styled(MFlexBlock)`
  cursor: pointer;
`
