import { gql } from '@apollo/client'

export const GET_TENANT_USERS = gql`
  query Users($filter: UserFilter, $limit: Int, $offset: Int) {
    users(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        email
        isActive
        activeUntil
        resource {
          id
          name
        }
        roles {
          id
          name
        }
        locationPermissions {
          id
          name
        }
        genusPermissions {
          id
          name
        }
        jobTypePermissions {
          id
          name
        }
        activityPermissions {
          id
          name
        }
      }
      total
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input)
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    deleteUser(id: $id)
  }
`

export const KNOWN_EMAILS = gql`
  query KnownEmails($searchString: String!) {
    knownEmails(searchString: $searchString)
  }
`

export const GET_LICENSE_USAGE = gql`
  query LicenseUsage {
    licenseUsage {
      used
      total
    }
  }
`
