import { Internals, MDialog, MFieldProvider, MFlex, SearchBox } from '@mprise/react-ui'
import React, { useState } from 'react'
import { LoadingSwitchPanel } from '../loading-switch-panel'
import { useLocalState } from '../react-local-state'
import { SavingSwitchPanel } from '../saving-switch-panel'
import { useScanningSetting } from '../useScanningSetting'

export interface SearchAndSelectDialogCustomProps<T> {
  children: React.ReactNode
  footer?: React.ReactNode
  loading?: boolean
  gap?: number
  initialValue: T | null
  title: React.ReactNode
  open: boolean
  text: string
  onChange: (text: string) => void
  onClose: () => void
  onSave: (value: T) => Promise<void> | void
}

export const SearchAndSelectDialogCustom = <T,>({
  children,
  footer,
  loading = false,
  initialValue,
  gap = 5,
  title,
  open,
  text: search,
  onChange: onSearch,
  onClose,
  onSave,
}: SearchAndSelectDialogCustomProps<T>) => {
  const [saving, setSaving] = useState(false)
  const [selected, setSelected] = useLocalState(initialValue, [initialValue, open])
  const uniqueId = Internals.useUniqueId(`selectdialog`)
  const handleSearchChange = (text: string) => onSearch(text)
  const handleSubmit: React.FormEventHandler = async e => {
    e.preventDefault()
    e.stopPropagation()
    if (loading) {
      return
    }
    if (selected) {
      setSaving(true)
      try {
        await onSave(selected)
      } finally {
        setSaving(false)
      }
    }
  }

  const { scanningSetting } = useScanningSetting()

  return (
    <MDialog open={open} title={title} footer={footer} onClose={onClose} onSubmit={handleSubmit}>
      <SavingSwitchPanel mutation={[]} savingAnyway={saving}>
        <LoadingSwitchPanel query={[]} loadingAnyway={loading ?? false}>
          <MFlex vertical gap={gap}>
            <SearchBox
              autoFocus
              value={search}
              onChange={handleSearchChange}
              scanningSetting={scanningSetting}
              status={0}
              showSwitchKeyboardOption={true}
            />
            <MFieldProvider id={uniqueId} name='selected' value={selected} onChange={setSelected}>
              {children}
            </MFieldProvider>
          </MFlex>
        </LoadingSwitchPanel>
      </SavingSwitchPanel>
    </MDialog>
  )
}
