import { gql } from '@apollo/client'

export const GET_CARRIERS = gql`
  query Carriers($filter: CarrierIdFilter, $limit: Int, $offset: Int) {
    carrierIds(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        status
      }
      total
    }
  }
`
