import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { CircularProgress, Switch } from '@mui/material'
import { MFlexBlock, MText, MTextColor } from '@mprise/react-ui'
import { MutationResult, useMutation, useQuery } from '@apollo/client'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { MPageTitle } from '../../shared/page-title'
import { MutationErrorMessage, QueryErrorMessage } from '../../shared/react-apollo'
import { GET_BOOLEAN_SETTING, SET_BOOLEAN_SETTING } from '../../gql/settings'

export const CompanySettingsPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet title={t('Company settings')} />

      <MPageTitle label={t('Company settings')} />

      <br />

      <BooleanSetting
        settingName='use-new-phase-date-format'
        displayName={t('Use new Phase date format')}
        explanation={t('Use new date format for Job Production Phases. Only enable for compatible BC versions.')}
      />
    </>
  )
}

const BooleanSetting = ({
  settingName,
  displayName,
  explanation,
}: {
  settingName: string
  displayName: string
  explanation?: string
}) => {
  const companyId = useCurrentCompanyId()

  const [settingEnabled, setSettingEnabled] = useState(false)

  const { data, loading, error, refetch } = useQuery(GET_BOOLEAN_SETTING, {
    variables: {
      companyId: +companyId,
      settingName: settingName,
    },
  })

  const [setSetting, { data: setSettingData, loading: saving, error: setSettingError }] =
    useMutation(SET_BOOLEAN_SETTING)

  useEffect(() => {
    setSettingEnabled(data?.booleanSetting ?? false)
  }, [data])

  useEffect(() => {
    const newSettingValue = setSettingData?.setBooleanSetting
    if (newSettingValue !== null && newSettingValue !== undefined) {
      setSettingEnabled(newSettingValue)
    }
  }, [setSettingData])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSettingValue = event.target.checked
    setSettingEnabled(newSettingValue)

    setSetting({
      variables: {
        companyId: +companyId,
        settingName: settingName,
        value: newSettingValue,
      },
    })
  }

  return (
    <MFlexBlock vertical gap={2} margin={[0, 4, 4, 4]} padding={[0, 2]}>
      <QueryErrorMessage query={{ loading, error, refetch }} />
      <MutationErrorMessage mutation={{ error: setSettingError } as MutationResult} />
      <MText block textVariant='content' textColor='#000000de'>
        {displayName}
      </MText>
      <MText block textVariant='small' textColor={MTextColor.shadow}>
        {explanation}
      </MText>
      <MFlexBlock gap={2} alignItems='center' margin={[-1, 0, 2, -2]}>
        <Switch checked={settingEnabled} disabled={loading || saving} onChange={handleChange} />
        {(loading || saving) && <CircularProgress size='20px' />}
      </MFlexBlock>
    </MFlexBlock>
  )
}
