import { gql } from '@apollo/client'

export const GET_LOCATIONS = gql`
  query Locations($filter: LocationFilter) {
    locations(filter: $filter) {
      id
      code
      name
    }
  }
`
