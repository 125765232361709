import { Dispatch, SetStateAction, useEffect, useState, DependencyList } from 'react'

/** A wrapper around React's useState hook that resets to or recalculates its passed 'source' value when any dependencies change. */
export const useLocalState = <T extends unknown>(
  source: T | (() => T),
  dependencies: DependencyList = [source],
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(source)

  useEffect(() => {
    setState(source)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: having a variable dependency array is the intention of this component.
  }, dependencies)

  return [state, setState]
}
