import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMField } from '@mprise/react-ui'
import { FieldSelect } from './field-select'
import { MultiSelectDialog } from '../dialog/multi-select-dialog'
import { GET_VARIANTS } from '../../gql/variants'
import { OperationVariables } from '@apollo/client'

export type SimpleVariant = {
  id: number
  code: string
  item: {
    id: number
    name: string
  }
}

export const FieldVariantSet = ({
  filterBy,
  disabled,
  allSelectedInForm,
  setAllSelectedInForm,
}: {
  filterBy?: OperationVariables | undefined
  disabled?: boolean
  allSelectedInForm?: boolean
  setAllSelectedInForm?: (newValue: boolean) => void
}) => {
  const f = useMField()
  const { t } = useTranslation()

  const selected = f.value as SimpleVariant[]
  const [open, setOpen] = useState(false)

  const [allSelected, setAllSelected] = useState<boolean>(allSelectedInForm ?? false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleClear = () => {
    f.onChange?.([])
    setAllSelected(false)
    setAllSelectedInForm?.(false)
  }

  const onSave = (selectedItems: SimpleVariant[]) => {
    f.onChange?.(selectedItems)
    setAllSelectedInForm?.(allSelected)
    handleClose()
  }

  return (
    <>
      {open && (
        <MultiSelectDialog<SimpleVariant>
          displayProperty='code'
          open={open}
          onClose={handleClose}
          onSave={onSave}
          title={t('Variants')}
          initialValue={selected}
          getRecordsQuery={GET_VARIANTS}
          additionalVariables={filterBy}
          queryResultAccessor={data => data?.variants?.page}
          selectAll={allSelected}
          onSelectAll={setAllSelected}
          groupBy='item.name'
        />
      )}
      <FieldSelect<SimpleVariant>
        displayProperty='code'
        disabled={disabled}
        selected={selected}
        allSelected={allSelectedInForm}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
