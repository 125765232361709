import { gql } from '@apollo/client'

export const GET_TRACKING_IDS = gql`
  query GetTrackingIds($filter: TrackingIdFilter, $limit: Int, $offset: Int) {
    trackingIds(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        containerCode
        position {
          id
          code
        }
        status
        quantity
        unitOfMeasure
      }
      total
    }
  }
`

export const GET_TRID_HISTORY = gql`
  query GetTridHistory($trackingCode: String!, $company: Int!) {
    tridStatusHistory(trackingCode: $trackingCode, company: $company) {
      id
      trackingId
      task
      status
      datetime
      resource {
        id
        code
        name
      }
      statusName
    }
  }
`
