import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useMField } from '@mprise/react-ui'

export const FieldDropdown = ({ values }: { values: string[] }) => {
  const f = useMField()

  const handleChange = (e: SelectChangeEvent<any>) => {
    f.onChange?.(e.target.value)
  }

  return (
    <Select value={f.value ?? ''} onChange={handleChange} variant='standard' sx={{ width: '100%' }}>
      {values.map(x => (
        <MenuItem key={x} value={x}>
          {x}
        </MenuItem>
      ))}
    </Select>
  )
}
