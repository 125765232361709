import { gql } from '@apollo/client'

export const GET_RPG = gql`
  query RPG($filter: ResourcePlanningGroupFilter) {
    resourcePlanningGroup(filter: $filter) {
      id
      name
      description
      workItemTypes
      locations {
        id
        name
        code
      }
      teams {
        id
        name
      }
    }
  }
`

export const GET_ALL_RPGS = gql`
  query RPGs($filter: ResourcePlanningGroupFilter) {
    resourcePlanningGroups(filter: $filter) {
      id
      name
      description
      workItemTypes
      locations {
        id
      }
      teams {
        id
        name
      }
    }
  }
`

export const CREATE_RPG = gql`
  mutation CreateRpg($input: CreateResourcePlanningGroupInput!) {
    createResourcePlanningGroup(input: $input) {
      id
    }
  }
`

export const UPDATE_RPG = gql`
  mutation UpdateRpg($id: Int!, $input: UpdateResourcePlanningGroupInput!) {
    updateResourcePlanningGroup(id: $id, input: $input) {
      id
    }
  }
`

export const DELETE_RPG = gql`
  mutation DeleteRpg($id: Int!) {
    deleteResourcePlanningGroup(id: $id)
  }
`
