import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { Clear as ClearIcon, Search as SearchIcon, Add as AddIcon } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, px } from '@mprise/react-ui'
import { useHistory } from '../../shared/use-history'
import { RouterLink } from '../../react-router'
import { AlertDialog } from '../../shared/alert-dialog'
import { RefreshIcon } from '../../shared/refresh-icon'
import { RoleVisible, useRoleAvailable } from '../../auth'
import { useLazyQuery } from '@apollo/client'
import { GET_ALL_TEMPLATES } from '../../gql/templates'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { DEFAULT_PAGE_SIZE, SimpleDataGrid } from '../../shared/simple-data-grid'
import { GridColDef } from '@mui/x-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { AppName } from '../../lib/enums'
import { WorkItemTemplate } from '../work-item-template/page'

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 250 },
  { field: 'type', headerName: 'Work Item Type', width: 250 },
  { field: 'activity', headerName: 'Activity', width: 250, valueGetter: params => params.row?.activity.name },
]

export const GHWorkItemTemplateList = () => {
  const isTemplateAdmin = useRoleAvailable(`ADMIN_TEMPLATE_ADMIN`)
  const h = useHistory()
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()
  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlertClose = () => setAlert(null)
  const [search, setSearch] = useState(``)
  const handleSearch = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => setSearch(e.target.value), [])
  const debouncedSearch = useDebounceValue(search, 500)

  const [getTemplates, { error, loading, data, fetchMore }] = useLazyQuery(GET_ALL_TEMPLATES, {
    onError: console.error,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    getTemplates({
      variables: {
        filter: {
          companyId: +companyId,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          appName: AppName.GreenhouseApp,
          removed: false,
        },
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
      },
    })
  }, [companyId, debouncedSearch, getTemplates])

  // TODO: Replace control that requires refetch or make a pagination in backend
  const refetch = () => {}

  const handleEditTemplate = (template: WorkItemTemplate, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (isTemplateAdmin) {
      h.push(`/greenhouse-templates/edit/${template.id}`)
    }
  }

  return (
    <>
      <Helmet title={t('Greenhouse Work Item Templates')} />
      <AlertDialog alert={alert} entity={`Template`} handleClose={handleAlertClose} />
      <MPageTitle label={t('Greenhouse Work Item Templates')} />
      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <MFlexItem minWidth='auto' grow={10} shrink={2}>
            <RoleVisible role='ADMIN_TEMPLATE_ADMIN'>
              <Box mr={1} mt={1}>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  component={RouterLink}
                  startIcon={<AddIcon />}
                  to={`/greenhouse-templates/new`}
                  children='Add Greenhouse Template'
                />
              </Box>
            </RoleVisible>
          </MFlexItem>
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={() => setSearch(``)}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
          <IconButton onClick={refetch}>
            <RefreshIcon loading={loading} />
          </IconButton>
        </MFlex>

        <SimpleDataGrid
          columns={columns}
          data={data?.workItemTemplates?.page}
          rowCount={data?.workItemTemplates?.total}
          onRowClick={handleEditTemplate}
          loading={loading}
          fetchMore={fetchMore}
        />
      </MFlexBlock>
    </>
  )
}
