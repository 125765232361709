import { Collapse } from '@mui/material'
import { MBlock, MFieldInput, MFlexBlock, MText, MTextColor } from '@mprise/react-ui'
import { Field, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useHistory } from '../../shared/use-history'
import * as yup from 'yup'
import { IconWarning } from '../../icons'
import { AutocompleteField } from '../../shared/material-ui-formik'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MSection, MSections } from '../../shared/msection'
import { PatternDialog } from '../../shared/pattern-dialog'
import { useLocalState } from '../../shared/react-local-state'
import { yupObject } from '../../shared/yup-common-types'
import { GHTaskListField } from './task-list-field'
import { AlertDialog, AlertType } from '../../shared/alert-dialog'
import { FieldActivity } from '../../shared/form/field-activity'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_TEMPLATE, GET_ALL_TEMPLATES, CREATE_TEMPLATE, UPDATE_TEMPLATE } from '../../gql/templates'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { WorkItemType, WorkResultType, WorkItemTemplateTaskOption, AppName } from '../../lib/enums'
import { DeleteTemplateDialog } from '../work-item-template/dialog-delete-template'
import { AvailableResultTypes } from '../work-item-template/restrictions'
import { useRoleAvailable } from '../../auth'
import { Maybe } from '../../shared/typescript'

export interface GHWorkItemTemplateForm {
  id: Maybe<number>
  companyId: number
  type: Maybe<{ id: string; name: string }>
  name: Maybe<string>
  activity: Maybe<{ id: number; name: string; code: Maybe<string> }>
  tasks: Maybe<Array<WorkItemTemplateTaskForm>>
}

export interface WorkItemTemplateTaskForm {
  order: number
  name: string
  types: Array<WorkResultType>
  taskOptions: Array<WorkItemTemplateTaskOption>
  taskOptionsTransfer: Array<WorkItemTemplateTaskOption>
  taskOptionsGeneral: Array<WorkItemTemplateTaskOption>
  taskOptionsIC: Array<WorkItemTemplateTaskOption>
  settingFinishOnFulfilled: Maybe<boolean>
}

export const TRANSFER_TASK_OPTIONS = [
  WorkItemTemplateTaskOption.MoveCarriersToLocationGh,
  WorkItemTemplateTaskOption.OffloadCarriersGh,
  WorkItemTemplateTaskOption.PutOnCarriersGh,
  WorkItemTemplateTaskOption.AdvancedAreaRegistrationGh,
]

export const GENERAL_TASK_OPTIONS = [
  WorkItemTemplateTaskOption.AutoStartStopTasksGh,
  WorkItemTemplateTaskOption.AutoStopPerc50Gh,
  WorkItemTemplateTaskOption.AutoStopPerc60Gh,
  WorkItemTemplateTaskOption.AutoStopPerc70Gh,
  WorkItemTemplateTaskOption.AutoStopPerc80Gh,
  WorkItemTemplateTaskOption.AutoStopPerc90Gh,
  WorkItemTemplateTaskOption.AutoStopPerc100Gh,
  WorkItemTemplateTaskOption.BypassJobInventoryGh,
  WorkItemTemplateTaskOption.CropMaintenanceGh,
]

export const IC_TASK_OPTIONS = [
  WorkItemTemplateTaskOption.AutoFillConsumptionQuantityGh,
  WorkItemTemplateTaskOption.AllowOverruleLotReservationGh,
]

export const GHTemplateDialog = () => {
  const params = useParams()
  const h = useHistory()
  const { t } = useTranslation()

  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlert = (alertType: AlertType) => setAlert(alertType)
  const handleAlertClose = () => setAlert(null)
  const action = params.action ?? null
  const templateId = params.templateId ?? null
  const companyId = useCurrentCompanyId()

  const [getTemplate, { data: template }] = useLazyQuery(GET_TEMPLATE, {
    onError: console.error,
  })
  const [getTemplates, { data: templates }] = useLazyQuery(GET_ALL_TEMPLATES, {
    onError: console.error,
  })

  const [createTemplate, { data: createData }] = useMutation(CREATE_TEMPLATE, {
    refetchQueries: [GET_ALL_TEMPLATES, 'workItemTemplates'],
    onError: console.error,
  })
  const [updateTemplate, { data: updateData }] = useMutation(UPDATE_TEMPLATE, {
    refetchQueries: [GET_ALL_TEMPLATES, 'workItemTemplates', GET_TEMPLATE],
    onError: console.error,
  })

  useEffect(() => {
    if (templateId) {
      getTemplate({
        variables: {
          filter: {
            id: +templateId,
          },
        },
      })
    }
    getTemplates({
      variables: {
        filter: {
          companyId: +companyId,
          removed: false,
        },
      },
    })
  }, [])

  useEffect(() => {
    if (createData) {
      handleAlert('create')
      h.push(`/greenhouse-templates`)
    }
  }, [createData])

  useEffect(() => {
    if (updateData) {
      handleAlert('edit')
      h.push(`/greenhouse-templates`)
    }
  }, [updateData])

  const toWorkItemType = (type: WorkItemType) => ({ id: type, name: t(`WorkItemType.${type}`) })

  const [initialValues] = useLocalState<GHWorkItemTemplateForm>(
    () =>
      template
        ? {
            companyId: +companyId,
            id: template.workItemTemplate.id ?? ``,
            type: template.workItemTemplate.type ? toWorkItemType(template.workItemTemplate.type) : null,
            name: template.workItemTemplate.name ?? ``,
            tasks: (template.workItemTemplate.tasks ?? []).map((x: any) => ({
              name: x.name,
              order: x.order,
              types: x.taskOptions.includes(WorkItemTemplateTaskOption.CropMaintenanceGh)
                ? [WorkResultType.CropMaintenance]
                : x.types,
              taskOptions: x.taskOptions ?? [],
              taskOptionsTransfer: (x.taskOptions ?? []).filter((option: WorkItemTemplateTaskOption) =>
                TRANSFER_TASK_OPTIONS.includes(option),
              ),
              taskOptionsGeneral: (x.taskOptions ?? []).filter((option: WorkItemTemplateTaskOption) =>
                GENERAL_TASK_OPTIONS.includes(option),
              ),
              taskOptionsIC: (x.taskOptions ?? []).filter((option: WorkItemTemplateTaskOption) =>
                IC_TASK_OPTIONS.includes(option),
              ),
              settingFinishOnFulfilled: x.settingFinishOnFulfilled ?? null,
            })),
            activity: template.workItemTemplate.activity,
          }
        : {
            id: null,
            type: toWorkItemType(WorkItemType.JobWorkOrder),
            companyId: +companyId,
            name: ``,
            adhoc: false,
            activity: null,
            tasks: [],
            settingTrackingIdStatusId: null,
          },
    [template, companyId],
  )

  const handleSave = async (form: GHWorkItemTemplateForm) => {
    const patchedForm = patchResultTypes(form)
    const activityAlreadyAssignedToTemplate = templates.workItemTemplates?.page
      ?.filter((t: any) => t.id !== patchedForm.id)
      .some((t: any) => t.activity?.id === patchedForm.activity?.id)
    if (activityAlreadyAssignedToTemplate) {
      handleAlert('warning')
      return
    }
    const input = {
      autoClose: true,
      type: patchedForm.type?.id as WorkItemType,
      companyId: +patchedForm.companyId,
      name: patchedForm.name ?? ``,
      activityId: +patchedForm.activity!.id ?? null,
      tasks: (patchedForm.tasks ?? []).map(t => ({
        name: t.name,
        order: t.order,
        types: t.types,
        taskOptions: t.taskOptions,
        settingFinishOnFulfilled: t.settingFinishOnFulfilled ?? null,
      })),
      appName: AppName.GreenhouseApp,
    }

    if (patchedForm.id) {
      updateTemplate({
        variables: {
          id: +patchedForm.id,
          input: input,
        },
      })
    } else {
      createTemplate({
        variables: {
          input: input,
        },
      })
    }
  }

  const handleCancel = () => {
    h.push(`/greenhouse-templates`)
  }

  const isTemplateAdmin = useRoleAvailable(`ADMIN_TEMPLATE_ADMIN`)

  const [deleteTemplate, setDeleteTemplate] = useState(``)
  const handleDeleteTemplate = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (isTemplateAdmin) {
      setDeleteTemplate(templateId!)
    }
  }
  const deleteTemplateClose = () => setDeleteTemplate(``)

  const schema = GHTemplateDialog.useSchema()

  const alreadyAssignedActivityIds =
    templates?.workItemTemplates?.page
      ?.filter((t: any) => t.id !== template?.id && t.activity?.id)
      .map((t: any) => t.activity.id) ?? []

  return (
    <>
      {deleteTemplate && (
        <DeleteTemplateDialog
          open={!!deleteTemplate}
          templateId={deleteTemplate}
          onClose={deleteTemplateClose}
          handleAlert={handleAlert}
          editPopupClose={handleCancel}
        />
      )}
      {alert !== `warning` && alert !== `duplicate` && (
        <AlertDialog alert={alert} entity={`Template`} handleClose={handleAlertClose} />
      )}
      <PatternDialog<GHWorkItemTemplateForm>
        title={templateId ? t(`Edit Template`) : t(`Add Template`)}
        submit={templateId ? t(`Save`) : t(`Create`)}
        initialValues={initialValues}
        onClose={handleCancel}
        onSave={handleSave}
        onDelete={handleDeleteTemplate}
        schema={schema}
        open={Boolean(action || templateId)}
        // query={[templateId ? templateQuery : null, activitiesQuery].filter(defined)}
        // mutation={[upsertMutation]}
        query={null}
        mutation={null}
        alert={alert}
        onAlertClose={handleAlertClose}
      >
        <GHTemplateDialogForm alreadyAssignedActivityIds={alreadyAssignedActivityIds} />
      </PatternDialog>
    </>
  )
}

const GHTemplateDialogForm = ({ alreadyAssignedActivityIds }: { alreadyAssignedActivityIds: string[] }) => {
  const { t } = useTranslation()
  const f = useFormikContext<GHWorkItemTemplateForm>()
  const templateTypeChoices = [WorkItemType.JobPickOrder, WorkItemType.JobWorkOrder].map(key => ({
    id: key,
    name: t(`WorkItemType.${key}`),
  }))

  const excessResultTypes = getExcessResultTypes(f.values)

  return (
    <MSections>
      <MSection title={t(`General Information`)}>
        <MBlock padding={4}>
          <Field component={MFieldConnector} name='name' fullWidth label={t(`Name`)}>
            <MFieldInput margin='dense' autoFocus inputProps={{ maxLength: 25 }} />
          </Field>
          <Field component={AutocompleteField} name='type' label={t(`Work Item Type`)} options={templateTypeChoices} />
          <Field component={MFieldConnector} name='activity' label={t(`Activity`)}>
            <FieldActivity alreadyAssignedActivityIds={alreadyAssignedActivityIds} />
          </Field>
        </MBlock>
      </MSection>
      <MSection title={t(`Tasks`)}>
        <Field component={MFieldConnector} name='tasks'>
          <GHTaskListField templateType={(f.values.type?.id as WorkItemType) ?? WorkItemType.JobWorkOrder} />
        </Field>
      </MSection>
      <Collapse in={excessResultTypes.length > 0}>
        <MSection title={t(`Validation`)}>
          <MFlexBlock padding={4} gap={2}>
            <MFlexBlock shrink={0}>
              <IconWarning color='error' fontSize='large' />
            </MFlexBlock>
            <MFlexBlock grow={0}>
              <MText block textColor={MTextColor.dark}>
                The following task result types will be removed:
                <ul>
                  {excessResultTypes.map(x => (
                    <li>{t(`WorkResultType.${x}`)}</li>
                  ))}
                </ul>
                They are not compatible with the current work item type.
              </MText>
            </MFlexBlock>
          </MFlexBlock>
        </MSection>
      </Collapse>
    </MSections>
  )
}

GHTemplateDialog.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(() =>
    yupObject<GHWorkItemTemplateForm>({
      companyId: yup.string().label(`companyId`),
      id: yup.string().optional().nullable(),
      name: yup.string().label(t(`Name`)).required(),
      type: yupObject({ id: yup.string(), name: yup.string() }).label(t(`Type`)).required().nullable() as any,
      activity: yup.object().label(t(`Activity`)).required().nullable() as any,
      tasks: yup.array().min(1, t(`TasksField`)).label(t(`Tasks`)).required() as any,
    }),
  )

  return schema
}

const getExcessResultTypes = (form: GHWorkItemTemplateForm) => {
  const templateType = (form.type?.id as WorkItemType) ?? WorkItemType.AdHoc
  const resultTypes = Array.from(new Set(form.tasks?.flatMap(x => x.types)))

  let validResultTypes: Array<WorkResultType> = []

  if (templateType === WorkItemType.JobWorkOrder) {
    validResultTypes = [
      WorkResultType.ItemConsumption,
      WorkResultType.JobInventoryPutaway,
      WorkResultType.CropMaintenance,
    ]
  } else {
    validResultTypes = AvailableResultTypes[templateType] ?? []
  }
  const excessResultTypes = resultTypes.filter(x => !validResultTypes.includes(x))

  return excessResultTypes
}

const patchResultTypes = (form: GHWorkItemTemplateForm) => {
  const excessResultTypes = getExcessResultTypes(form)

  const patchedTasks = []
  for (const task of form.tasks ?? []) {
    if (task.types.includes(WorkResultType.CropMaintenance)) {
      // Change fake resultType CROP_MAINTENANCE to JOB_INVENTORY_PUTAWAY
      patchedTasks.push({
        ...task,
        types: [WorkResultType.JobInventoryPutaway],
        taskOptions: [...(task.taskOptions ?? []), WorkItemTemplateTaskOption.CropMaintenanceGh],
      })
    } else if (form.type?.id === WorkItemType.JobPickOrder) {
      // Currently, JobPickOrders MUST have WorkResultType.ItemOutput enabled
      patchedTasks.push({ ...task, types: [WorkResultType.ItemOutput] })
    } else {
      patchedTasks.push({ ...task, types: task.types.filter(x => !excessResultTypes.includes(x)) })
    }
  }

  return { ...form, tasks: patchedTasks }
}
