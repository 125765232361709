import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentCompanyId } from '../useCurrentCompany'
import { SearchAndSelectDialog } from './search-and-select-dialog'
import { NextPageView } from '../pagination'
import { DocumentNode, OperationVariables, useLazyQuery } from '@apollo/client'
import { useDebounceValue } from '../../shared/debounce-value'
import styled from '@emotion/styled'
import { MJoinChildren, MDivider, useMField, MFlexItem, MFlexBlock, MColor, MText } from '@mprise/react-ui'
import { ListItem, ListItemText, Checkbox } from '@mui/material'

let nextCursor: any
let fetchCursor: any

export const MultiSelectDialog = <T extends { id: number }>({
  open,
  title,
  onClose,
  onSave,
  initialValue,
  getRecordsQuery,
  additionalVariables,
  queryResultAccessor,
  hideSearch,
  displayProperty,
  selectAll,
  onSelectAll,
  groupBy,
  applyFrontendSorting,
}: {
  open: boolean
  title: string
  onClose: () => void
  onSave: (selected: T[]) => void
  initialValue?: T[]
  getRecordsQuery: DocumentNode
  additionalVariables?: OperationVariables
  queryResultAccessor: (data: any) => T[] | undefined
  hideSearch?: boolean
  displayProperty: keyof T
  selectAll?: boolean
  onSelectAll?: (newValue: boolean) => void
  groupBy?: string
  /** Applies frontend sorting. This is not compatible with queries that use backend paging. */
  applyFrontendSorting?: boolean
}) => {
  const { t } = useTranslation()

  const companyId = useCurrentCompanyId()

  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounceValue(search, 500)

  const [dialogInitialValue] = useState(initialValue ?? [])

  const [getRecords, { data, loading }] = useLazyQuery(getRecordsQuery, {
    onError: console.error,
  })

  useEffect(() => {
    getRecords({
      variables: {
        filter: {
          companyId: +companyId,
          removed: false,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          ...additionalVariables,
        },
      },
    })
  }, [additionalVariables, companyId, debouncedSearch, getRecords])

  return (
    <>
      {nextCursor && <NextPageView key={nextCursor} cursor={nextCursor} fetch={fetchCursor} />}
      <SearchAndSelectDialog
        loading={loading}
        initialValue={dialogInitialValue}
        gap={0}
        open={open}
        title={title}
        text={search}
        onClose={onClose}
        onSave={onSave}
        onChange={hideSearch ? undefined : setSearch}
      >
        {onSelectAll && (
          <>
            <MFlexBlockClickable gap={4} padding={[1, 4]} alignItems='center' onClick={() => onSelectAll(!selectAll)}>
              <Checkbox checked={selectAll} />
              <MFlexItem grow={1}>
                <ListItemText primary={t('All')} />
              </MFlexItem>
            </MFlexBlockClickable>
            {!groupBy && (
              <>
                {!selectAll && <MDivider />}
                <MDivider color={MColor.disabled} />
              </>
            )}
          </>
        )}
        {!selectAll && (
          <MultiSelectDialogList
            records={queryResultAccessor(data)}
            displayProperty={displayProperty}
            groupBy={groupBy}
            applyFrontendSorting={applyFrontendSorting}
          />
        )}
      </SearchAndSelectDialog>
    </>
  )
}

const MultiSelectDialogList = <T extends { id: number }>({
  records,
  displayProperty,
  groupBy,
  applyFrontendSorting,
}: {
  records?: T[]
  displayProperty: keyof T
  groupBy?: string
  applyFrontendSorting?: boolean
}) => {
  const { t } = useTranslation()

  if (!records?.length) {
    return (
      <ListItem>
        <ListItemText primary={t('No results')} />
      </ListItem>
    )
  }

  if (groupBy) {
    const getGroupByValue = (obj: T) => groupBy.split('.').reduce((xs: any, x) => xs?.[x] ?? null, obj)
    const grouped = new Map<string, T[]>()
    for (const record of records) {
      const groupByValue = getGroupByValue(record)
      if (grouped.has(groupByValue)) {
        const current = grouped.get(groupByValue) ?? []
        grouped.set(groupByValue, [...current, record])
      } else {
        grouped.set(groupByValue, [record])
      }
    }

    return Array.from(grouped.keys()).map(key => (
      <span key={key}>
        <MText style={{ paddingLeft: '0.75rem', marginTop: '0.75rem' }} block textVariant='small bold'>
          {key}
        </MText>

        <MJoinChildren divider={MDivider}>
          {grouped.get(key)?.map((value: T, i) => {
            return <MultiSelectDialogItem key={i} record={value} displayProperty={displayProperty} />
          })}
        </MJoinChildren>
      </span>
    ))
  }

  const recordsToShow = applyFrontendSorting ? sortRecordsByProperty(records, displayProperty) : records

  return (
    <MJoinChildren divider={MDivider}>
      {recordsToShow.map(x => {
        return <MultiSelectDialogItem key={x.id} record={x} displayProperty={displayProperty} />
      })}
    </MJoinChildren>
  )
}

const MultiSelectDialogItem = <T extends { id: number }>({
  record,
  displayProperty,
}: {
  record: T
  displayProperty: keyof T
}) => {
  const f = useMField()
  const selectedRecords = f.value as T[]
  const currentlySelected = selectedRecords.some(x => x.id === record.id)

  const handleClick = () => {
    const newSelected = selectedRecords.slice()
    if (currentlySelected) {
      f.onChange?.(newSelected.filter(x => x.id !== record.id))
    } else {
      newSelected.push(record)
      f.onChange?.(newSelected)
    }
  }

  return (
    <MFlexBlockClickable gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <Checkbox checked={currentlySelected} />
      <MFlexItem grow={1}>
        <ListItemText primary={record[displayProperty] as string} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

const MFlexBlockClickable = styled(MFlexBlock)`
  cursor: pointer;
  color: inherit;
`

/** Sorts objects alphabetically on the given property. */
const sortRecordsByProperty = <T,>(records: T[], sortOn: keyof T) => {
  return records?.slice().sort((a, b) => (a[sortOn] as string).localeCompare(b[sortOn] as string))
}
