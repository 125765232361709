import { MField, useMField } from '@mprise/react-ui'
import { useState } from 'react'
import { SelectWorkItemTypesDialog, SelectWorkItemTypesDialogAvatars } from '../dialog/select-workitem-types-dialog'
import { FieldBarcode } from './field-barcode'
import { WorkItemType } from '../../lib/enums'

export const FieldRPGWorkItemType = ({ title }: { title: string }) => {
  const f = useMField()
  const selected = f.value as WorkItemType[]
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  return (
    <>
      <SelectWorkItemTypesDialog
        initialValue={selected}
        open={open}
        onClose={handleClose}
        onSave={(items: WorkItemType[]) => {
          f.onChange?.(items)
          setOpen(false)
        }}
        title={title}
        loading={false}
      />
      <FieldBarcode
        selected={f.value}
        loading={false}
        onExplore={handleOpen}
        renderLabel={() => (
          <MField.ScrollableContentX>
            <SelectWorkItemTypesDialogAvatars />
          </MField.ScrollableContentX>
        )}
        showInstantScanButton={false}
        onClear={handleClear}
        onScanned={() => {}}
      />
    </>
  )
}
