import styled from '@emotion/styled'
import Icon from '@mdi/react'
import { Collapse, ListItem, ListItemText } from '@mui/material'
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'
import {
  MAvatar,
  MColor,
  MDivider,
  MFlexBlock,
  MFlexItem,
  MJoinChildren,
  MSelectItem,
  MTextColor,
  useMField,
} from '@mprise/react-ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentCompanyId } from '../useCurrentCompany'
import { IconLocation } from '../../icons'
import { MSection, MSections } from '../msection'
import { SearchAndSelectDialog } from './search-and-select-dialog'
import { MutationErrorMessage } from '../react-apollo'
import { MutationResult } from '@apollo/client'
import { NextPageView } from '../pagination'
import { mdiRefresh } from '@mdi/js'
import { useLazyQuery } from '@apollo/client'
import { GET_LOCATIONS } from '../../gql/location'
import { useDebounceValue } from '../../shared/debounce-value'

let nextCursor: any
let fetchCursor: any
export type SimpleLocation = {
  __typename?: 'Location' | undefined
  id: number
  code: string
  name: string
}

const MFlexBlockClickable = styled(MFlexBlock)`
  cursor: pointer;
`
export const SelectLocationsDialog = ({
  loading,
  open,
  title,
  onClose,
  onSave,
  initialValue,
  apolloMutation,
}: {
  loading: boolean
  open: boolean
  title: string
  onClose: () => void
  onSave: (selected: SimpleLocation[]) => void
  apolloMutation?: MutationResult<any>
  initialValue?: SimpleLocation[]
}) => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounceValue(search, 500)

  const [getLocations, { data: locations }] = useLazyQuery(GET_LOCATIONS, {
    onError: console.error,
  })

  useEffect(() => {
    getLocations({
      variables: {
        filter: {
          companyId: +companyId,
          removed: false,
          ...(debouncedSearch && { searchString: debouncedSearch }),
        },
      },
    })
  }, [companyId, debouncedSearch, getLocations])

  return (
    <>
      {nextCursor && <NextPageView key={nextCursor} cursor={nextCursor} fetch={fetchCursor} />}
      <SearchAndSelectDialog
        loading={loading}
        initialValue={initialValue}
        gap={0}
        open={open}
        title={title}
        text={search}
        onClose={onClose}
        onSave={selected => onSave(selected as SimpleLocation[])}
        onChange={(text: string) => {
          setSearch(text)
        }}
      >
        {apolloMutation && (
          <div style={{ margin: '.5em' }}>
            <MutationErrorMessage mutation={apolloMutation!} />
          </div>
        )}
        <SelectLocationsDialogAvatars />
        <MSections>
          <MDivider />
          {locations && <SelectLocationsDialogList header={t('All')} locations={locations.locations} />}
        </MSections>
      </SearchAndSelectDialog>
    </>
  )
}

const SelectLocationsDialogList = ({ locations, header }: { locations: SimpleLocation[]; header: string }) => {
  const { t } = useTranslation()
  return (
    <MSection title={header}>
      <MJoinChildren divider={MDivider}>
        {locations?.map(t => {
          return <SelectLocationsDialogItem key={t?.id} team={t} />
        })}
        {locations?.length > 0 ? null : (
          <ListItem>
            <ListItemText primary={t(`No results`)} />
          </ListItem>
        )}
      </MJoinChildren>
    </MSection>
  )
}

const SelectLocationsDialogItem = ({ team }: { team: SimpleLocation }) => {
  const field = useMField()
  const selectedTeams = field.value as SimpleLocation[]
  const selected = keyed(selectedTeams).includes(team)

  const primary = team.name ?? team.id

  const icon = (
    <MAvatar.Badge seed={primary} size='medium'>
      <IconLocation fontSize='large' />
    </MAvatar.Badge>
  )

  const check = selected ? (
    <Icon path={mdiRadioboxMarked} size={1} color={MColor.primary} />
  ) : (
    <Icon path={mdiRadioboxBlank} size={1} color={MTextColor.shadow} />
  )

  const handleClick = () => {
    if (selected) {
      const new_selected = selectedTeams.slice()
      keyed(new_selected).delete(team)
      field.onChange?.(new_selected)
    } else {
      const new_selected = selectedTeams.slice()
      keyed(new_selected).delete(team) // ?why?
      keyed(new_selected).add(team)
      field.onChange?.(new_selected)
    }
  }

  return (
    <MFlexBlockClickable id={team.id.toString()} gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <MFlexItem>{check}</MFlexItem>
      <MFlexItem>{icon}</MFlexItem>
      <MFlexItem grow={1}>
        <ListItemText primary={primary} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

const keyed = <T extends { id: number }>(list: T[]) => {
  const byId = (item: { id: number }) => (other: { id: number }) => item.id === other.id
  return {
    add(item: T) {
      if (!list.some(byId(item))) {
        list.push(item)
      }
    },
    delete(item: { id: number }) {
      if (list.some(byId(item))) {
        list.splice(list.findIndex(byId(item)), 1)
      }
    },
    includes(item: { id: number }) {
      return list.some(byId(item))
    },
  }
}

export const SelectLocationsDialogAvatars = ({ onClick }: { onClick?: () => void }) => {
  const field = useMField()
  const selectedLocations = field.value as SimpleLocation[]
  const handleRemove = (location: MSelectItem<SimpleLocation>) => {
    const keys = selectedLocations.slice()
    keyed(keys).delete(location.data)

    if (field.onChange) {
      field.onChange(keys)
    }
  }

  return (
    <>
      {nextCursor && (
        <Icon spin={0.8} style={{ margin: '20px auto' }} path={mdiRefresh} size={1} color={MColor.primary} />
      )}
      {!nextCursor && (
        <Collapse in={selectedLocations.length > 0} unmountOnExit>
          <MAvatar.MultiSelectList
            items={selectedLocations.map(toSelectItem)}
            enableUngroup={() => false}
            enableRemove={() => true}
            onUngroup={() => {}}
            onRemove={handleRemove}
            onClick={onClick}
          />
        </Collapse>
      )}
    </>
  )
}

const toSelectItem = (value: SimpleLocation): MSelectItem<SimpleLocation> => {
  return {
    data: value,
    icon: <IconLocation fontSize='large' />,
    id: value.id.toString(),
    primary: value.name ?? ``,
    secondary: value.code ?? null,
  }
}
