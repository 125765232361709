import styled from '@emotion/styled'
import { Collapse, ListItemText } from '@mui/material'
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js'
import Icon from '@mdi/react'
import {
  MAvatar,
  MColor,
  MDivider,
  MFlexBlock,
  MFlexItem,
  MJoinChildren,
  MSelectItem,
  MTextColor,
  useMField,
} from '@mprise/react-ui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconWork } from '../../icons'
import { i18n } from '../../lib/translations/i18n'
import { MSection, MSections } from '../msection'
import { useSelectiveSearch } from '../react-selective-search'
import { SearchAndSelectDialog } from './search-and-select-dialog'
import { WorkItemType } from '../../lib/enums'

export const SelectWorkItemTypesDialog = ({
  loading,
  open,
  title,
  onClose,
  onSave,
  initialValue,
}: {
  loading: boolean
  open: boolean
  title: string
  onClose: () => void
  onSave: (selected: WorkItemType[]) => void
  initialValue?: WorkItemType[]
}) => {
  const { t } = useTranslation()

  const [search, setSearch] = useState<string>('')
  const types = Object.values(WorkItemType)

  const typesFiltered = useSelectiveSearch(search, types, type => type)

  return (
    <SearchAndSelectDialog
      loading={loading}
      initialValue={initialValue}
      gap={0}
      open={open}
      title={title}
      text={search}
      onClose={onClose}
      onSave={selected => onSave(selected as WorkItemType[])}
      onChange={(text: string) => {
        setSearch(text)
      }}
    >
      <SelectWorkItemTypesDialogAvatars />
      <MSections>
        <MDivider />
        <SelectWorkItemTypesDialog.List header={t('SECTION_ALL')} items={typesFiltered} />
      </MSections>
    </SearchAndSelectDialog>
  )
}

SelectWorkItemTypesDialog.List = ({ items, header }: { items: string[]; header: string }) => {
  return (
    <MSection title={header}>
      <MJoinChildren divider={MDivider}>
        {items.map(t => {
          return <SelectWorkItemTypesDialogItem key={t} item={t} />
        })}
      </MJoinChildren>
    </MSection>
  )
}

const SelectWorkItemTypesDialogItem = ({ item }: { item: string }) => {
  const field = useMField()
  const selectedItems = field.value as string[]
  const selected = field.value.includes(item)

  const { t } = useTranslation()

  const check = selected ? (
    <Icon path={mdiRadioboxMarked} size={1} color={MColor.primary} />
  ) : (
    <Icon path={mdiRadioboxBlank} size={1} color={MTextColor.shadow} />
  )

  const handleClick = () => {
    if (selected) {
      const new_selected = selectedItems.slice().filter(x => x !== selected)
      field.onChange?.(new_selected)
    } else {
      const new_selected = selectedItems.slice().filter(x => x !== item)
      new_selected.push(item)
      field.onChange?.(new_selected)
    }
  }

  return (
    <MFlexBlockClickable id={item} gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <MFlexItem>{check}</MFlexItem>
      <MFlexItem>
        <MAvatar.Badge seed={item} size='medium'>
          <IconWork />
        </MAvatar.Badge>
      </MFlexItem>
      <MFlexItem grow={1}>
        <ListItemText primary={t(`WorkItemType.${item}`)} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

export const SelectWorkItemTypesDialogAvatars = ({ onClick }: { onClick?: () => void }) => {
  const field = useMField()
  const selectedItems = field.value as string[]
  const handleRemove = (item: MSelectItem<string>) => {
    const keys = selectedItems.slice().filter(x => x !== item.data)

    if (field.onChange) {
      field.onChange(keys)
    }
  }

  return (
    <Collapse in={selectedItems.length > 0} unmountOnExit>
      <MAvatar.MultiSelectList
        items={selectedItems.map(toSelectItem)}
        enableUngroup={() => false}
        enableRemove={() => true}
        onUngroup={() => {}}
        onRemove={handleRemove}
        onClick={onClick}
      />
    </Collapse>
  )
}

const toSelectItem = (value: string): MSelectItem<string> => {
  return {
    data: value,
    icon: <IconWork fontSize='large' />,
    id: value,
    primary: i18n.t(`WorkItemType.${value.toUpperCase()}`),
    secondary: null,
  }
}

const MFlexBlockClickable = styled(MFlexBlock)`
  cursor: pointer;
`
