import { ApolloError, ApolloQueryResult, MutationResult, OperationVariables } from '@apollo/client'
import { Button, Collapse } from '@mui/material'
import { MColor, MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'

type QueryResult = {
  loading: boolean
  error: ApolloError | undefined
  refetch?: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
}

export const QueryErrorMessage = ({ query }: { query: QueryResult | Array<QueryResult> }) => {
  query = Array.isArray(query) ? query : [query]

  const firstWithAnIssue = query.find((x: any) => !x.loading && !!x.error)

  return (
    <Collapse in={!!firstWithAnIssue} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
        <MFlexItem grow={1}>
          <MText block textVariant='content bold'>
            A problem occurred while requesting the information
          </MText>
          <MText block textVariant='small'>
            {firstWithAnIssue?.error?.message}
          </MText>
        </MFlexItem>
        {firstWithAnIssue?.refetch && (
          <MFlexItem shrink={0}>
            <Button
              variant='outlined'
              size='small'
              onClick={firstWithAnIssue.refetch}
              style={{ color: 'white', borderColor: 'white' }}
            >
              Retry
            </Button>
          </MFlexItem>
        )}
      </MFlexBlock>
    </Collapse>
  )
}

export const MutationErrorMessage = ({
  mutation,
  overrideError,
}: {
  mutation: MutationResult | Array<MutationResult>
  overrideError?: string
}) => {
  mutation = Array.isArray(mutation) ? mutation : [mutation]

  const { t } = useTranslation()

  const firstWithAnIssue = mutation.find(x => !x.loading && !!x.error)
  return (
    <Collapse in={!!firstWithAnIssue} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
        <MFlexItem grow={1}>
          <MText block textVariant='content bold'>
            {t('SUBMITTING_PROBLEM_OCCURED')}
          </MText>
          <MText block textVariant='small'>
            {overrideError ? overrideError : firstWithAnIssue?.error?.message ?? ''}
          </MText>
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}
