import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@mui/material'
import { MFlex } from '@mprise/react-ui'

export type AlertType = keyof typeof alertType

const alertType = {
  create: `created`,
  delete: `deleted`,
  edit: `edited`,
  warning: `warning`,
  duplicate: `duplicate`,
  skill: `skill`,
}

export const AlertDialog = ({
  alert,
  entity,
  handleClose,
}: {
  alert?: AlertType | null
  entity: string
  handleClose?: () => void
}) => {
  const successMessage = alert ? alertType[alert] : null
  const { t } = useTranslation()
  const isDuplicateTaskTypeAlert: Boolean = alert === alertType.duplicate
  const isDuplicateSkill: Boolean = alert === alertType.skill
  const isWarningAlert: Boolean = alert === alertType.warning
  const isSuccessAlert: Boolean =
    alert !== alertType.duplicate && alert !== alertType.warning && alert !== alertType.skill

  return (
    <MFlex justifyContent='flex-end'>
      {alert && isSuccessAlert && (
        <Alert onClose={handleClose} sx={success}>
          {t(`SuccessMessage`, { entity: entity, message: successMessage })}
        </Alert>
      )}
      {isDuplicateTaskTypeAlert && (
        <Alert onClose={handleClose} sx={duplicate}>
          {t(`DuplicateTaskType`, { entity: entity })}
        </Alert>
      )}
      {isDuplicateSkill && (
        <Alert onClose={handleClose} sx={duplicate}>
          {t(`DuplicateSkill`, { entity: entity })}
        </Alert>
      )}
      {isWarningAlert && (
        <Alert onClose={handleClose} sx={warning}>
          {t(`WarningMessage`, { entity: entity })}
        </Alert>
      )}
    </MFlex>
  )
}

export const useAlertTimeout = (duration: number = 2000) => {
  const state = useState<AlertType | null>(null)

  useEffect(() => {
    if (state[0]) {
      const timer = setTimeout(() => {
        state[1](null)
      }, duration)
      return () => clearTimeout(timer)
    }
  }, [state[0]])

  return state
}

AlertDialog.useAlertTimeout = useAlertTimeout

const success = {
  backgroundColor: '#32CD32',
  borderRadius: '6px',
  color: 'white',
  fontSize: '1rem',
  margin: '5px',
  padding: '2px 8px',
  position: 'fixed',
  top: 70,
  right: 0,
  width: 'fit-content',
  zIndex: 2,
  '& .MuiAlert-icon': {
    color: 'white',
    fontSize: 22,
    opacity: 1,
  },
}
const duplicate = {
  backgroundColor: '#E6980C',
  borderRadius: '6px',
  color: 'white',
  fontSize: '1rem',
  margin: '5px',
  padding: '2px 8px',
  position: 'fixed',
  top: 70,
  right: 0,
  width: 'fit-content',
  zIndex: 2000,
  '& .MuiAlert-icon': {
    color: 'white',
    fontSize: 22,
    opacity: 1,
  },
}
const warning = {
  backgroundColor: '#E6980C',
  borderRadius: '6px',
  color: 'white',
  fontSize: '1rem',
  margin: '0 auto 5px',
  position: 'fixed',
  textAlign: 'center',
  width: 'inherit',
  zIndex: 2,
  '& .MuiAlert-icon': {
    color: 'white',
    fontSize: 22,
    opacity: 1,
  },
}
