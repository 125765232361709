import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogContentText, TextField } from '@mui/material'
import { MPortalDialog } from '@mprise/react-ui'
import { AlertType } from '../../shared/alert-dialog'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_TEMPLATE, GET_ALL_TEMPLATES, DELETE_TEMPLATE } from '../../gql/templates'

export const DeleteTemplateDialog = ({
  handleAlert,
  open,
  templateId,
  onClose,
  editPopupClose,
}: {
  handleAlert: (alertType: AlertType) => void
  open: boolean
  templateId: string
  onClose: () => void
  editPopupClose: () => void
}) => {
  const { t } = useTranslation()

  const [getTemplate, { error: templateError, loading: templateLoading, data: template }] = useLazyQuery(GET_TEMPLATE)

  if (templateError) {
    console.error('rpgError', templateError)
  }

  const [deleteTemplate, { data: deleteData, loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_TEMPLATE,
    {
      refetchQueries: [GET_ALL_TEMPLATES, 'workItemTemplates'],
    },
  )

  if (deleteError) {
    console.error('deleteError', deleteError)
  }

  useEffect(() => {
    if (templateId) {
      getTemplate({
        variables: {
          filter: {
            id: +templateId,
          },
        },
      })
    }
  }, [templateId])

  useEffect(() => {
    if (deleteData) {
      handleAlert('delete')
      onClose()
    }
  }, [deleteData])

  const onCommit: React.FormEventHandler = async e => {
    e.preventDefault()
    deleteTemplate({ variables: { id: +templateId } })
    editPopupClose()
  }

  return (
    <MPortalDialog
      open={open}
      title={t(`TemplateDeleteAction`)}
      submit={t(`TemplateDeleteAction`)}
      onSubmit={onCommit}
      onClose={onClose}
    >
      <DialogContentText>
        {t('TemplateDeleteDialogHint', { name: template && template.workItemTemplate.name })}
      </DialogContentText>
      <TextField
        fullWidth
        variant='outlined'
        label={t('Name')}
        value={template ? template.workItemTemplate.name : ``}
        InputProps={{ readOnly: true }}
      />
    </MPortalDialog>
  )
}
