import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { IconButton, TextField } from '@mui/material'
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, px } from '@mprise/react-ui'
import { RefreshIcon } from '../../shared/refresh-icon'
import { useLazyQuery } from '@apollo/client'
import { GET_LOTS } from '../../gql/lots'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { DEFAULT_PAGE_SIZE, SimpleDataGrid } from '../../shared/simple-data-grid'
import { GridColDef } from '@mui/x-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { QueryErrorMessage } from '../../shared/react-apollo'

const columns: GridColDef[] = [
  { field: 'lotNumber', headerName: 'Lot Number', width: 250 },
  {
    field: 'description',
    headerName: 'Description',
    width: 350,
    valueGetter: params => `${params.row.item?.name ?? ``} - ${params.row.variantCode}`,
  },
]

export const LotPage = () => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()
  const [search, setSearch] = useState(``)
  const debouncedSearch = useDebounceValue(search, 500)
  const handleSearch = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => setSearch(e.target.value), [])

  const [getLots, { data, loading, error, fetchMore }] = useLazyQuery(GET_LOTS, {
    onError: console.error,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    getLots({
      variables: {
        filter: {
          companyId: +companyId,
          ...(debouncedSearch && { searchString: debouncedSearch }),
        },
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
      },
    })
  }, [companyId, debouncedSearch, getLots])

  // TODO: Replace control that requires refetch or make a pagination in backend
  const refetch = () => {}

  const handleClear = () => {
    setSearch(``)
  }

  return (
    <>
      <Helmet title={t('Lots')} />

      <MPageTitle label={t('Lots')} />
      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <MFlexItem minWidth='auto' grow={10} shrink={2} />
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={handleClear}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
          <IconButton onClick={refetch}>
            <RefreshIcon loading={loading} />
          </IconButton>
        </MFlex>

        <QueryErrorMessage query={{ loading, error }} />

        <SimpleDataGrid
          columns={columns}
          data={data?.lots?.page}
          rowCount={data?.lots?.total}
          loading={loading}
          fetchMore={fetchMore}
        />
      </MFlexBlock>
    </>
  )
}
