import { gql } from '@apollo/client'

export const GET_ONE_COMPANY = gql`
  query Company($filter: CompanyFilter) {
    company(filter: $filter) {
      id
      name
      externalId
      cosmosKey
    }
  }
`

export const GET_COMPANIES = gql`
  query Companies($filter: CompanyFilter) {
    companies(filter: $filter) {
      id
      name
      externalId
      cosmosKey
    }
  }
`
