import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { Add as AddIcon, Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, px } from '@mprise/react-ui'
import { useHistory } from '../../shared/use-history'
import { RouterLink } from '../../react-router'
import { AlertDialog } from '../../shared/alert-dialog'
import { RefreshIcon } from '../../shared/refresh-icon'
import { useLazyQuery } from '@apollo/client'
import { GET_SKILLS } from '../../gql/skills'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { SimpleDataGrid } from '../../shared/simple-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { QueryErrorMessage } from '../../shared/react-apollo'

type SkillRow = {
  __typename?: 'Skill' | undefined
  id: number
  name: string
  code: string
  description: string
}

export const SkillPage = () => {
  const { t } = useTranslation()
  const h = useHistory()
  const companyId = useCurrentCompanyId()

  const [search, setSearch] = useState(``)
  const debouncedSearch = useDebounceValue(search, 500)
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)

  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlertClose = () => setAlert(null)

  const [getSkills, { data, loading, error }] = useLazyQuery(GET_SKILLS, {
    onError: console.error,
  })

  useEffect(() => {
    getSkills({
      variables: {
        filter: {
          companyId: +companyId,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          removed: false,
        },
      },
    })
  }, [companyId, debouncedSearch, getSkills])

  // TODO: Replace control that requires refetch or make a pagination in backend
  const refetch = () => {}

  const handleEditSkill = (skill: SkillRow, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    h.push(`/skills/edit/${skill.id}`)
  }

  return (
    <>
      <Helmet title={t('Skills')} />
      <AlertDialog alert={alert} entity={`Skill`} handleClose={handleAlertClose} />

      <MPageTitle label={t('Skills')} />
      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <MFlexItem minWidth='auto' grow={10} shrink={2}>
            <Box mr={1} mt={1}>
              <Button
                variant='contained'
                color='primary'
                size='small'
                component={RouterLink}
                startIcon={<AddIcon />}
                to='/skills/new'
                children='Add Skill'
              />
            </Box>
          </MFlexItem>
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={() => setSearch('')}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
          <IconButton onClick={refetch}>
            <RefreshIcon loading={loading} />
          </IconButton>
        </MFlex>

        <QueryErrorMessage query={{ loading, error }} />

        <SimpleDataGrid
          columnNames={['code', 'name', 'description']}
          data={data?.skills}
          onRowClick={handleEditSkill}
          rowCount={data?.skills?.length}
          loading={loading}
        />
      </MFlexBlock>
    </>
  )
}
