import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField as MuiTextField,
  Autocomplete,
} from '@mui/material'
import { MField } from '@mprise/react-ui'
import { FieldProps, getIn } from 'formik'
import { Clear as ClearIcon } from '@mui/icons-material'
import { Maybe } from './typescript'

export const CheckboxField = ({
  field: { name, onBlur, value },
  form,
  label,
  description,
}: FieldProps & { label?: React.ReactNode; description?: React.ReactNode }) => {
  const fieldError = getIn(form.errors, name)
  const fieldTouched = getIn(form.touched, name)
  const error = fieldTouched && fieldError ? String(fieldError) : null
  return (
    <FormControl fullWidth margin='normal' component='fieldset' variant='standard'>
      <FormLabel component='legend'>{label}</FormLabel>
      <FormGroup row>
        <FormControlLabel
          value='top'
          control={
            <Checkbox
              name={name}
              color='primary'
              checked={value}
              onChange={(_, c) => form.setFieldValue(name, c, true)}
              onBlur={onBlur}
            />
          }
          label={description}
        />
      </FormGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export const ChoiceField = ({
  field: { name, onBlur, value },
  form,
  label,
  getLabel,
  getValue,
  options,
}: FieldProps & {
  label?: React.ReactNode
  options: Array<any>
  getLabel: (item: any) => React.ReactNode
  getValue: (item: any) => any
}) => {
  const fieldError = getIn(form.errors, name)
  const fieldTouched = getIn(form.touched, name)
  const error = fieldTouched && fieldError ? String(fieldError) : null
  return (
    <FormControl fullWidth margin='normal' component='fieldset' variant='standard'>
      <FormLabel component='legend'>{label}</FormLabel>
      <RadioGroup name={name} value={value} onChange={(_, c) => form.setFieldValue(name, c, true)} row>
        {options.map((option, idx) => (
          <FormControlLabel
            key={idx}
            label={getLabel(option)}
            control={<Radio name={name} value={getValue(option)} color='primary' onBlur={onBlur} />}
          />
        ))}
      </RadioGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export interface AutocompleteOption {
  id: string
  name: string
  code?: string
}
export const AutocompleteField = ({
  label,
  options,
  ...formik
}: FieldProps & { label: React.ReactNode; options: Array<AutocompleteOption> }) => {
  const error = getIn(formik.form.errors, formik.field.name)
  const touch = getIn(formik.form.touched, formik.field.name)

  const value = formik.field.value ?? null

  return (
    <MField.Container vertical>
      <Autocomplete<AutocompleteOption, false>
        {...formik}
        multiple={false}
        options={options}
        getOptionLabel={option =>
          option.code && option.name ? `${option.name} (${option.code})` : `${option.name ?? option.code ?? '-'}`
        }
        isOptionEqualToValue={(x: any, y: any) => x.id === y.id || x === y}
        value={value}
        onChange={(_, selected) => {
          formik.form.setFieldValue(formik.field.name, selected ?? null)
        }}
        renderInput={(params: any) => (
          <MuiTextField
            name={formik.field.name}
            label={label}
            variant='standard'
            error={Boolean(touch && error)}
            helperText={touch && error ? error : null}
            onBlur={formik.field.onBlur}
            {...params}
          />
        )}
      />
    </MField.Container>
  )
}

type AutocompleteOptionSimple = string

type TAutocompleteFieldSimple = FieldProps & {
  label: React.ReactNode
  options: Array<AutocompleteOptionSimple>
  showClearIcon?: boolean
  defaultValue?: Maybe<string>
  getOptionLabel: (option: AutocompleteOptionSimple) => string
}

/**
 * Like Autocomplete but here the options are represented as simple string,
 * instead of objects. Suitable for rendering enums. A quick fix for issue
 * #42320.
 *
 * @todo refactor task-dialog in template to handle Enum options
 */
export const AutocompleteFieldSimple = ({
  label,
  options,
  showClearIcon = true,
  defaultValue = null,
  getOptionLabel,
  ...formik
}: TAutocompleteFieldSimple) => {
  const error = getIn(formik.form.errors, formik.field.name)
  const touch = getIn(formik.form.touched, formik.field.name)

  const value = formik.field.value ?? defaultValue

  return (
    <MField.Container vertical>
      <Autocomplete<AutocompleteOptionSimple, false>
        {...formik}
        multiple={false}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(x: any, y: any) => x === y}
        value={value}
        onChange={(_, selected) => {
          formik.form.setFieldValue(formik.field.name, selected ?? defaultValue)
        }}
        clearIcon={showClearIcon ? <ClearIcon fontSize='small' /> : null}
        renderInput={(params: any) => (
          <MuiTextField
            name={formik.field.name}
            label={label}
            variant='standard'
            error={Boolean(touch && error)}
            helperText={touch && error ? error : null}
            onBlur={formik.field.onBlur}
            {...params}
          />
        )}
      />
    </MField.Container>
  )
}
