import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { MColor, MFieldInput, MFlex, MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'
import { Field, Formik } from 'formik'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { AlertType } from '../../shared/alert-dialog'
import { FieldLocationSet } from '../../shared/form/field-location-set'
import { FieldRPGWorkItemType } from '../../shared/form/field-rpg-workitem-type-set'
import { FieldTeamSet } from '../../shared/form/field-team-set'
import { withFormikCompareFix } from '../../shared/formik'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MSection, MSections } from '../../shared/msection'
import { FormikDialog } from '../../shared/react-formik-dialog'
import { useLocalState } from '../../shared/react-local-state'
import { defined } from '../../shared/typescript'
import { ValidationIssues } from '../../shared/validation-issues'
import { yup, yupObject, yupObjectArray } from '../../shared/yup-common-types'
import { Scalars, WorkItemType } from '../../lib/enums'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_RPG, GET_ALL_RPGS, UPDATE_RPG } from '../../gql/rpgs'

export type FullRPG = {
  __typename?: 'ResourcePlanningGroup' | undefined
  id: number
  name: string
  description: string
  workItemTypes: WorkItemType[]
  locations: any[]
  teams: any[]
}
export type RPGTeam = {
  __typename?: 'Team' | undefined
  id: number
  name: string
}
export type RPGLocation = {
  __typename?: 'Location' | undefined
  id: number
  name: string
  code: string
}

// export type FullRPG = NotOptional<ResourcePlanningGroupQuery['resourcePlanningGroup']>
// export type RPGTeam = NotOptional<NotOptional<ResourcePlanningGroupQuery['resourcePlanningGroup']>['teams'][0]>
// export type RPGLocation = NotOptional<NotOptional<ResourcePlanningGroupQuery['resourcePlanningGroup']>['locations'][0]>

const MAX_LENGTH_INPUT_FIELD = 50

interface editRPGInput {
  name: string
  companyId: string
  description: string
  teams: RPGTeam[]
  locations: RPGLocation[]
  types: WorkItemType[]
}

export const ResourcePlanningGroupDialogEdit = ({
  id,
  open,
  rpgs,
  onClose,
  onDelete,
  handleAlert,
}: {
  id: Scalars['ID']
  open: boolean
  rpgs: any
  onClose: () => any
  onDelete: (id: string) => void
  handleAlert: (alertType: AlertType) => void
}) => {
  // if (!open) return (<></>);
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  // const loadGroupQ = useResourcePlanningGroupQuery({ fetchPolicy: 'network-only', variables: { groupId: id } })
  // const loadedGroup = loadGroupQ.data?.resourcePlanningGroup ?? null

  const [getRpg, { error: rpgError, loading: rpgLoading, data: rpg }] = useLazyQuery(GET_RPG, {
    variables: { filter: { id: +id } },
  })

  const [conflict, setConflict] = useState(false)
  const [conflicts, setConflicts] = useState([])

  if (rpgError) {
    console.error('rpgError', rpgError)
  }

  const [updateRpg, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(UPDATE_RPG, {
    refetchQueries: [GET_ALL_RPGS, 'resourcePlanningGroups'],
  })

  if (updateError) {
    console.error('updateError', updateError)
  }

  useEffect(() => {
    if (id) {
      getRpg({
        variables: {
          filter: {
            id: +id,
          },
        },
      })
      setConflict(false)
    }
  }, [id])

  const [initialValues] = useLocalState<editRPGInput>(() => {
    if (rpg) {
      return withFormikCompareFix({
        id: rpg.resourcePlanningGroup.id ?? '',
        description: rpg.resourcePlanningGroup.description ?? '',
        name: rpg.resourcePlanningGroup.name ?? '',
        companyId,
        teams: (rpg.resourcePlanningGroup.teams ?? []).filter(defined),
        locations: (rpg.resourcePlanningGroup.locations ?? []).filter(defined),
        types: (rpg.resourcePlanningGroup.workItemTypes ?? []).filter(defined),
      })
    } else {
      return withFormikCompareFix({
        name: '',
        description: '',
        companyId,
        teams: [],
        locations: [],
        types: [],
      })
    }
  }, [id, open, rpg])

  const schema = ResourcePlanningGroupDialogEdit.useSchema()

  // const { save, mutations } = ResourcePlanningGroupDialogEdit.useSave(id)

  useEffect(() => {
    if (updateData) {
      handleAlert('edit')
      onClose()
    }
  }, [updateData])

  const handleSubmit = async (form: editRPGInput) => {
    const conflicts: never[] = []
    const resourcePlanningGroups = rpgs.resourcePlanningGroups
    const existingName = resourcePlanningGroups.find((r: any) => form.name === r.name && +id !== r.id)

    if (existingName) {
      conflicts.push(t('RPG_name_conflict'))
    }

    if (form.types.length === 0) {
      conflicts.push(t('Select one or multiple work item types'))
    }

    if (form.teams.length === 0) {
      conflicts.push(t('Select one or multiple teams'))
    }

    if (form.locations.length === 0) {
      conflicts.push(t('Select one or multiple locations'))
    }

    if (conflicts.length > 0) {
      setConflict(true)
      setConflicts(conflicts)
    } else {
      updateRpg({
        variables: {
          id: +id,
          input: {
            name: form.name,
            description: form.description,
            teamIds: form.teams?.map(t => +t.id) ?? [],
            locationIds: form.locations && form.locations.length > 0 ? form.locations.map(l => +l.id) : [],
            workItemTypes: form.types && form.types.length > 0 ? (form.types as WorkItemType[]) : [],
          },
        },
      })
    }

    // const saved = await save(form)
    // if (saved) {
    //   // on created
    //   handleAlert('edit')
    //   // on close
    //   onClose()
    // }
  }

  // const has409: boolean = doesMutationHave409(mutations)

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      <FormikDialog
        minWidth='md'
        open={open}
        title={t('DialogUpdateRPGTitle')}
        submit={t('DialogUpdateRPGTitle')}
        removeChildren={t(`ACTION_REMOVE`)}
        onClose={onClose}
        onRemove={() => onDelete(id)}
      >
        <ValidationIssues />
        {conflict && <ResourcePlanningGroupDialogEditConflict conflicts={conflicts} />}
        {/* {!has409 && <MutationErrorMessage mutation={mutations} />} */}
        <ResourcePlanningGroupDialogEditForm loadedGroup={rpg && rpg.resourcePlanningGroup} />
      </FormikDialog>
    </Formik>
  )
}

const ResourcePlanningGroupDialogEditConflict = ({ conflicts }: { conflicts: never[] }) => {
  const { t } = useTranslation()

  return (
    <Collapse in={true} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
        <MFlexItem grow={1}>
          <MText block textVariant='content bold'>
            {t('Conflict')}
          </MText>
          {conflicts.map((c: any) => (
            <MText block textVariant='small'>
              {c}
            </MText>
          ))}
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}

const ResourcePlanningGroupDialogEditForm = ({ loadedGroup }: { loadedGroup: FullRPG | null }) => {
  const { t } = useTranslation()

  if (!loadedGroup) {
    return (
      <MFlex alignItems='center' justifyContent='center'>
        <CircularProgress />
      </MFlex>
    )
  }

  return (
    <MSections>
      <MSection title={t(`Resource Planning Group`)}>
        <Field component={MFieldConnector} name='name' label={t(`Name`)}>
          <MFieldInput inputProps={{ maxLength: MAX_LENGTH_INPUT_FIELD }} />
        </Field>
        <Field component={MFieldConnector} name='description' label={t(`Description`)}>
          <MFieldInput inputProps={{ maxLength: MAX_LENGTH_INPUT_FIELD }} />
        </Field>
      </MSection>
      <MSection title={t(`Filters`)}>
        <Field component={MFieldConnector} name='types' label={t(`Work Item Types`)}>
          <FieldRPGWorkItemType title={t('Work Item Type')} />
        </Field>
        <Field component={MFieldConnector} name='teams' label={t(`Teams`)}>
          <FieldTeamSet title={t('Teams')} />
        </Field>
        <Field component={MFieldConnector} name='locations' label={t(`Locations`)}>
          <FieldLocationSet title={t('Locations')} />
        </Field>
      </MSection>
    </MSections>
  )
}

ResourcePlanningGroupDialogEdit.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(() =>
    yupObject<editRPGInput>({
      companyId: yup.string().required(),
      name: yup.string().required().min(4).max(MAX_LENGTH_INPUT_FIELD).label(t(`Name`)) as any,
      description: yup.string().min(4).max(MAX_LENGTH_INPUT_FIELD).required().label(t(`Description`)),
      locations: yupObjectArray({ id: yup.string().required(), name: yup.string().required() })
        .required()
        .min(1, 'Select one or multiple locations')
        .label(t(`Locations`)) as any,
      teams: yupObjectArray({ id: yup.string().required(), name: yup.string().required() })
        .required()
        .min(1, 'Select one or multiple teams')
        .label(t(`Teams`)) as any,
      types: yup
        .array(yup.mixed().oneOf(Object.values(WorkItemType)).required())
        .required()
        .min(1, 'Select one or multiple work item types')
        .label(t('Types')),
    }),
  )
  return schema
}

// ResourcePlanningGroupDialogEdit.useSave = (groupId: string) => {
//   const [updateRPG, updateRPGMutation] = useUpdateResourcePlanningGroupMutation({fetchPolicy: 'network-only'})

//   async function save(input: editRPGInput) {
//     const update = await updateRPG({
//         variables: {
//           groupId,
//           companyId: input.companyId,
//           name: input.name,
//           description: input.description,
//           teamIds: input.teams && input.teams.length > 0 ? input.teams.map((t) => t.id) : [],
//           locationIds: input.locations && input.locations.length > 0 ? input.locations.map((l) => l.id) : [],
//           workItemTypes: input.types && input.types.length > 0 ? (input.types as WorkItemType[]) : [],
//         },
//       })

//     if (!update.data?.updateResourcePlanningGroup?.id) {
//       return null
//     }

//     return update.data?.updateResourcePlanningGroup
//   }

//   return { save, mutations: [updateRPGMutation] }
// }

// const doesMutationHave409 = (mutations: MutationResult<UpdateResourcePlanningGroupMutation>[]) => {
//   const firstWithAnIssue = mutations.find((x) => !x.loading && !!x.error)

//   if (firstWithAnIssue) {
//     let is409: boolean = false
//     firstWithAnIssue.error?.graphQLErrors.forEach((e) => {
//       if (e.message.indexOf('409') > -1) {
//         is409 = true
//       }
//     })

//     return is409
//   }

//   return false
// }
