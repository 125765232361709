import { gql } from '@apollo/client'

export const GET_JOBS = gql`
  query Jobs($filter: JobFilter, $limit: Int, $offset: Int) {
    jobs(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
      }
      total
    }
  }
`
