import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { Clear as ClearIcon, PersonAdd, Search as SearchIcon } from '@mui/icons-material'
import { MColor, MFlex, MFlexBlock, MFlexItem, MText, px } from '@mprise/react-ui'
import { RefreshIcon } from '../../shared/refresh-icon'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_LICENSE_USAGE, GET_TENANT_USERS } from '../../gql/users'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { DEFAULT_PAGE_SIZE, SimpleDataGrid } from '../../shared/simple-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { GridColDef } from '@mui/x-data-grid'
import { useHistory } from '../../shared/use-history'
import { QueryErrorMessage } from '../../shared/react-apollo'

const columns: GridColDef[] = [
  { field: 'email', headerName: 'Email', width: 250, valueGetter: params => params.row.email },
  { field: 'resource', headerName: 'Resource', width: 200, valueGetter: params => params.row.resource?.name },
  {
    field: 'roles',
    headerName: 'Roles',
    width: 200,
    valueGetter: params => params.row.roles.map((r: any) => r.name).join(', '),
  },
  {
    field: 'activityPermissions',
    headerName: 'Activities',
    width: 250,
    valueGetter: params => params.row.activityPermissions?.map((x: any) => x.name).join(', '),
  },
  {
    field: 'locationPermissions',
    headerName: 'Locations',
    width: 250,
    valueGetter: params => params.row.locationPermissions?.map((x: any) => x.name).join(', '),
  },
  // {
  //   field: 'genusPermissions',
  //   headerName: 'Genuses',
  //   width: 150,
  //   valueGetter: params => params.row.genusPermissions?.map((x: any) => x.name).join(', '),
  // },
  // {
  //   field: 'jobTypePermissions',
  //   headerName: 'Job Types',
  //   width: 150,
  //   valueGetter: params => params.row.jobTypePermissions?.map((x: any) => x.name).join(', '),
  // },
  { field: 'isActive', headerName: 'Status', width: 100, type: 'boolean', valueGetter: params => params.row.isActive },
]

export const UsersList = () => {
  const { t } = useTranslation()
  const h = useHistory()
  const companyId = useCurrentCompanyId()
  const handleSearch = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => setSearch(e.target.value), [])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 500)

  const [getUsers, { data, loading, error, fetchMore }] = useLazyQuery(GET_TENANT_USERS, {
    notifyOnNetworkStatusChange: true,
    onError: console.error,
  })

  useEffect(() => {
    getUsers({
      variables: {
        filter: {
          ...(debouncedSearch && { searchString: debouncedSearch }),
        },
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
      },
    })
  }, [companyId, debouncedSearch, getUsers])

  // TODO: Replace control that requires refetch or make a pagination in backend
  const refetch = () => {}

  const handleEditUser = (user: any, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    h.push(`/users/edit/${user.id}`)
  }

  return (
    <>
      <Helmet title={t('Users')} />

      <MPageTitle label={t('Users')} />

      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <Box marginX={1} marginY={2}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              startIcon={<PersonAdd />}
              children='Add New User'
              component={Link}
              to='/users/new'
            />
          </Box>
          <LicenseUsageInfo />
          <MFlexItem minWidth='auto' grow={10} shrink={2} />
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={() => setSearch('')}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
          <IconButton onClick={refetch}>
            <RefreshIcon loading={loading} />
          </IconButton>
        </MFlex>

        <QueryErrorMessage query={{ loading, error }} />

        <SimpleDataGrid
          columns={columns}
          data={data?.users?.page}
          onRowClick={handleEditUser}
          rowCount={data?.users?.total}
          loading={loading}
          fetchMore={fetchMore}
        />
      </MFlexBlock>
    </>
  )
}

const LicenseUsageInfo = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(GET_LICENSE_USAGE, {
    onError: console.error,
  })

  if (loading) {
    return <></>
  }

  const used = data?.licenseUsage?.used ?? 0
  const total = data?.licenseUsage?.total ?? 0

  return (
    <MFlexBlock bgColor={MColor.white} margin={[0, 4]}>
      <MText block textVariant='small' style={{ fontSize: 12 }}>
        {t('LicensesInUse', { used, total })}
      </MText>
    </MFlexBlock>
  )
}
