import { useTranslation } from 'react-i18next'
import { mdiFlagOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { MAvatar, MColor } from '@mprise/react-ui'
import { WorkPriority } from '../lib/enums'

const activeColor: Record<WorkPriority, MColor> = {
  [WorkPriority.None]: MColor.status_todo,
  [WorkPriority.Low]: MColor.low,
  [WorkPriority.Medium]: MColor.medium,
  [WorkPriority.High]: MColor.high,
}
const inactiveColor = MColor.status_todo

export const TagPriority = ({ disabled, priority }: { disabled?: boolean; priority: WorkPriority }) => {
  return (
    <MAvatar.Badge primary={disabled ? inactiveColor : activeColor[priority]}>
      <Icon path={mdiFlagOutline} size={1} />
    </MAvatar.Badge>
  )
}

export const PriorityAvatar = ({ disabled, priority }: { disabled?: boolean; priority: WorkPriority }) => {
  const { t } = useTranslation()
  const label = t(`Priority.${priority}`)
  const color = disabled ? inactiveColor : activeColor[priority]
  const icon = <Icon path={mdiFlagOutline} size={1} />
  return <MAvatar.Item primary={color} label={label} image={icon} />
}
