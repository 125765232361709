import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { Clear as ClearIcon, PostAdd, Search as SearchIcon } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, px } from '@mprise/react-ui'
import { RefreshIcon } from '../../shared/refresh-icon'
import { useLazyQuery } from '@apollo/client'
import { GET_TENANT_ROLES } from '../../gql/roles'
import { useDebounceValue } from '../../shared/debounce-value'
import { SimpleDataGrid } from '../../shared/simple-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { GridColDef } from '@mui/x-data-grid'
import { useHistory } from '../../shared/use-history'

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Role', width: 270, valueGetter: params => params.row.name },
  { field: 'permissions', headerName: 'Permissions', width: 170, valueGetter: params => params.row.permissions.length },
  { field: 'users', headerName: 'Users', width: 120, valueGetter: params => params.row.userCount },
  {
    field: 'activityPermissions',
    headerName: 'Activities',
    width: 250,
    valueGetter: params => params.row.activityPermissions?.map((x: any) => x.name).join(', '),
  },
  {
    field: 'locationPermissions',
    headerName: 'Locations',
    width: 250,
    valueGetter: params => params.row.locationPermissions?.map((x: any) => x.name).join(', '),
  },
  // {
  //   field: 'genusPermissions',
  //   headerName: 'Genuses',
  //   width: 150,
  //   valueGetter: params => params.row.genusPermissions?.map((x: any) => x.name).join(', '),
  // },
  // {
  //   field: 'jobTypePermissions',
  //   headerName: 'Job Types',
  //   width: 150,
  //   valueGetter: params => params.row.jobTypePermissions?.map((x: any) => x.name).join(', '),
  // },
]

export const RolesList = () => {
  const { t } = useTranslation()
  const h = useHistory()
  const handleSearch = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => setSearch(e.target.value), [])
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 500)

  const [getRoles, { loading, data }] = useLazyQuery(GET_TENANT_ROLES, {
    notifyOnNetworkStatusChange: true,
    onError: console.error,
  })

  useEffect(() => {
    getRoles()
  }, [getRoles])

  // TODO: Replace control that requires refetch or make a pagination in backend
  const refetch = () => {}

  const handleEditRole = (role: any, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    h.push(`/roles/edit/${role.id}`)
  }

  const roles = debouncedSearch
    ? data?.roles?.filter((x: any) => x.name.toLowerCase().includes(debouncedSearch.toLowerCase()))
    : data?.roles

  return (
    <>
      <Helmet title={t('Roles')} />

      <MPageTitle label={t('Roles')} />

      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <Box marginX={1} marginY={2}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              startIcon={<PostAdd />}
              children='Add New Role'
              component={Link}
              to='/roles/new'
            />
          </Box>
          <MFlexItem minWidth='auto' grow={10} shrink={2} />
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={() => setSearch('')}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
          <IconButton onClick={refetch}>
            <RefreshIcon loading={loading} />
          </IconButton>
        </MFlex>

        <SimpleDataGrid
          columns={columns}
          data={roles}
          onRowClick={handleEditRole}
          rowCount={roles?.length}
          loading={loading}
        />
      </MFlexBlock>
    </>
  )
}
