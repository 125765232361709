import styled from '@emotion/styled'
import { MTextColor } from '@mprise/react-ui'

const defaultTitleFontSize = '21px'

export const MPageTitle = ({ label, fontSize }: { label: string; fontSize?: string }) => {
  const Title = styled.h1({
    fontSize: fontSize ?? defaultTitleFontSize,
    fontWeight: 100,
    color: MTextColor.header,
    margin: '1rem 0 0 1.5rem',
  })

  return <Title>{label}</Title>
}
