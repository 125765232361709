import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Snackbar, Typography } from '@mui/material'

export const UpdateBar = ({
  updated,
  instance,
  children,
  onUpdate,
}: {
  updated: boolean
  instance: ServiceWorkerRegistration | null
  children: React.ReactNode
  onUpdate: () => any
}) => {
  const { t } = useTranslation()
  const updateServiceWorker = async () => {
    const swInstance = instance
    const swRegistration = swInstance?.waiting

    if (!swRegistration) return
    await swInstance?.unregister()

    setTimeout(() => {
      onUpdate()
      window.location.reload()
    }, 500) // small delay for ux
  }
  return (
    <>
      {updated ? (
        <Snackbar open={true}>
          <Alert elevation={6} variant='filled' severity='info' onClick={updateServiceWorker}>
            <Typography>{t('UpdateAction')}</Typography>
          </Alert>
        </Snackbar>
      ) : null}
      {children}
    </>
  )
}

UpdateBar.Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {children}
    </div>
  )
}
