import React, { ErrorInfo } from 'react'
import { AppBar, Toolbar, Typography, Box, Button } from '@mui/material'
import { IconError } from './icons'

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { error?: Error; errorInfo?: ErrorInfo }
> {
  static clearState() {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = `/`
  }

  static refresh() {
    window.location.reload()
  }

  constructor(props: { children: React.ReactNode }) {
    super(props)

    this.state = {}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(`[error-boundary]`, { error, errorInfo })
    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <AppBar>
            <Toolbar>
              <Typography variant='subtitle1'>Oops!</Typography>
            </Toolbar>
          </AppBar>
          <Box display='flex' flexDirection='column' alignItems='center' margin='8rem 2rem 2rem 2rem'>
            <IconError color='disabled' style={{ fontSize: `5rem` }} />
            <Typography variant='subtitle2'>An error occurred</Typography>
          </Box>
          <Box display='flex' justifyContent='center' margin='4rem' style={{ gap: `2rem` }}>
            <Button color='secondary' variant='contained' onClick={ErrorBoundary.clearState}>
              Clear state
            </Button>
            <Button color='primary' variant='contained' onClick={ErrorBoundary.refresh}>
              Reload
            </Button>
          </Box>
          <Box display='flex' flexDirection='column' margin='4rem'>
            <Typography variant='subtitle2'>Error details:</Typography>
            <pre>{this.state.error.message}</pre>
          </Box>
        </>
      )
    } else {
      return this.props.children
    }
  }
}
