import { Card } from '@mui/material'
import { MFlex, MFlexBlock, MHeader, MText, MTextColor } from '@mprise/react-ui'

export const MSections = ({ children }: { children: React.ReactNode }) => {
  return (
    <MFlex gap={4} vertical>
      {children}
    </MFlex>
  )
}

export const MSection = ({
  title,
  help,
  children,
}: {
  title: React.ReactNode
  help?: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <Card sx={{ backgroundColor: 'transparent' }} elevation={1}>
      <MHeader>
        <MFlexBlock alignItems='center'>
          <MFlexBlock padding={2} grow={1}>
            <MText block textVariant='header' textColor={MTextColor.header}>
              {title}
            </MText>
          </MFlexBlock>
          {help}
        </MFlexBlock>
      </MHeader>
      {children}
    </Card>
  )
}
