import { gql } from '@apollo/client'

export const GET_CONTAINERS = gql`
  query Containers($filter: ContainerFilter,$limit: Int, $offset: Int) {
    containers(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
        containerOuterLength
        containerOuterWidth
        cosmosKey
      }
      total
    }
  }
`
