import { gql } from '@apollo/client'

export const GET_ONE_SKILL = gql`
  query Skill($filter: SkillFilter) {
    skill(filter: $filter) {
      id
      code
      name
      description
      cosmosKey
      externalId
      removed
      __typename
    }
  }
`

export const GET_SKILLS = gql`
  query Skills($filter: SkillFilter) {
    skills(filter: $filter) {
      id
      code
      name
      description
      cosmosKey
      externalId
      removed
      __typename
    }
  }
`

export const CREATE_SKILL = gql`
  mutation CreateSkill($input: CreateSkillInput) {
    createSkill(input: $input) {
      id
      code
      name
      description
      cosmosKey
      externalId
      removed
      __typename
    }
  }
`

export const UPDATE_SKILL = gql`
  mutation UpdateSkill($id: ID!, $input: UpdateSkillInput) {
    updateSkill(id: $id, input: $input) {
      id
      code
      name
      description
      cosmosKey
      externalId
      removed
      __typename
    }
  }
`
