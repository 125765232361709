import React, { Dispatch, useEffect } from 'react'
import { i18n } from '../lib/translations/i18n'
import { useImmerReducer } from '../shared/immer-reducer'
import { useLocalStorage } from '../shared/local-storage'

export interface Session {
  locale: string
}

export interface Action {
  setLocale: string
}

export const StateLocale = ({ children }: { children: React.ReactNode }) => {
  const [settings, setSettings] = useLocalStorage<Session>(`app-locale`)

  const initialState: Session = settings ?? {
    locale: 'en',
  }

  const [state, dispatch] = useImmerReducer<Session, Action>((state, action) => {
    if (action.setLocale) {
      state.locale = action.setLocale
    }
  }, initialState)

  useEffect(() => {
    setSettings(state)
    i18n.changeLanguage(state.locale)
  }, [state])

  return <StateLocale.Context.Provider value={[state, dispatch]}>{children}</StateLocale.Context.Provider>
}
StateLocale.Context = React.createContext<[Session, Dispatch<Action>]>([
  { locale: `` },
  () => {
    throw Error(`requires <StateLocale>...</StateLocale>`)
  },
])
