import { gql } from '@apollo/client'

export const GET_AUTO_PICK_RULE = gql`
  query AutoPickRule($companyId: Int!, $id: Int!) {
    autoPickRule(companyId: $companyId, id: $id) {
      id
      genus {
        id
        name
        code
      }
      items {
        id
        name
      }
      variants {
        id
        code
        item {
          id
        }
      }
      allItems
      allVariants
      status
      defaultResource {
        id
        name
      }
      defaultPosition {
        id
        code
      }
    }
  }
`

export const GET_AUTO_PICK_RULES = gql`
  query AutoPickRules($companyId: Int!) {
    autoPickRules(companyId: $companyId) {
      id
      genus {
        id
        name
      }
      items {
        id
        name
      }
      variants {
        id
        code
      }
      allItems
      allVariants
      status
      defaultResource {
        id
        name
      }
      defaultPosition {
        id
        code
      }
    }
  }
`

export const UPSERT_AUTO_PICK_RULE = gql`
  mutation UpsertAutoPickRule($companyId: Int!, $input: UpsertAutoPickRuleInput!) {
    upsertAutoPickRule(companyId: $companyId, input: $input) {
      id
    }
  }
`

export const DELETE_AUTO_PICK_RULE = gql`
  mutation DeleteAutoPickRule($companyId: Int!, $id: Int!) {
    deleteAutoPickRule(companyId: $companyId, id: $id)
  }
`
