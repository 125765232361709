import { gql } from '@apollo/client'

export const GET_TEAM = gql`
  query Team($filter: TeamFilter) {
    team(filter: $filter) {
      id
      name
      leader {
        id
        name
        code
      }
      resourceMode
      resourceCount
      resources {
        id
        code
        name
        type
        jobTitle
        initials
      }
      skills {
        id
        name
        code
      }
      cosmosKey
    }
  }
`

export const GET_TEAMS = gql`
  query Teams($filter: TeamFilter) {
    teams(filter: $filter) {
      __typename
      id
      name
      leader {
        id
        name
      }
      resourceMode
      resourceCount
      resources {
        __typename
        id
        code
        name
        type
        jobTitle
        initials
      }
      skills {
        id
        name
        code
      }
      cosmosKey
    }
  }
`

export const CREATE_TEAM = gql`
  mutation CreateTeam($input: CreateTeamInput) {
    createTeam(input: $input) {
      __typename
      id
      name
    }
  }
`

export const UPDATE_TEAM = gql`
  mutation UpdateTeam($id: Int!, $input: UpdateTeamInput) {
    updateTeam(id: $id, input: $input) {
      __typename
      id
      name
    }
  }
`
export const DELETE_TEAM = gql`
  mutation DeleteTeam($id: Int!) {
    deleteTeam(id: $id)
  }
`
