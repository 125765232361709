import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton, TextField } from '@mui/material'
import { Clear as ClearIcon, Search as SearchIcon, Add as AddIcon } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, px } from '@mprise/react-ui'
import { RoleVisible, useRoleAvailable } from '../../auth'
import { AlertDialog, AlertType } from '../../shared/alert-dialog'
import { RefreshIcon } from '../../shared/refresh-icon'
import { defined } from '../../shared/typescript'
import { CreateTeamDialog } from './dialog-create-team'
import { DeleteTeamDialog } from './dialog-delete-team'
import { UpdateTeamDialog } from './dialog-edit-team'
import { useLazyQuery } from '@apollo/client'
import { GET_TEAMS } from '../../gql/teams'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { SimpleDataGrid } from '../../shared/simple-data-grid'
import { GridColDef } from '@mui/x-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { QueryErrorMessage } from '../../shared/react-apollo'

// missing leader, resources, skills
export type TeamRow = {
  __typename: 'Team'
  id: number
  name: string
  resourceMode: string
  resourceCount: string
  leader: any
  resources: any
  skills: any
}

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 250 },
  {
    field: 'resourceCount',
    headerName: 'Members',
    width: 250,
    valueGetter: params =>
      params.row?.resourceCount + ' members' + (params.row?.resourceMode === 'LIST' ? ' (named)' : ''),
  },
  {
    field: 'skills',
    headerName: 'Skills',
    width: 250,
    valueGetter: params =>
      params.row?.skills
        .filter(defined)
        .map((s: any) => s.name)
        .join(', '),
  },
]

export const TeamRoute = () => {
  const isTeamAdmin = useRoleAvailable(`ADMIN_TEAM_ADMIN`)
  const { t } = useTranslation()
  const [alert, setAlert] = AlertDialog.useAlertTimeout()
  const handleAlert = (alertType: AlertType) => setAlert(alertType)
  const handleAlertClose = () => setAlert(null)
  const handleSearch = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => setSearch(e.target.value), [])
  const [createTeam, setCreateTeam] = useState(false)
  const createTeamOpen = () => setCreateTeam(true)
  const createTeamClose = () => setCreateTeam(false)
  const [editTeam, setEditTeam] = useState('')
  const [search, setSearch] = useState('')
  const companyId = useCurrentCompanyId()
  const debouncedSearch = useDebounceValue(search, 500)

  const [getTeams, { data, loading, error }] = useLazyQuery(GET_TEAMS, {
    onError: console.error,
  })

  useEffect(() => {
    getTeams({
      variables: {
        filter: {
          companyId: +companyId,
          ...(debouncedSearch && { searchString: debouncedSearch }),
          removed: false,
        },
      },
    })
  }, [companyId, debouncedSearch, getTeams])

  // TODO: Replace control that requires refetch or make a pagination in backend
  const refetch = () => {}

  const handleEditTeam = (team: TeamRow, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (isTeamAdmin) {
      setEditTeam(team.id.toString())
    }
  }
  const editTeamClose = () => setEditTeam(``)

  const [deleteTeam, setDeleteTeam] = useState(``)
  const handleDeleteTeam = (team: any, e: React.MouseEvent<HTMLElement>) => {
    if (isTeamAdmin) {
      setDeleteTeam(team.teamId)
    }
  }
  const deleteTeamClose = () => {
    setDeleteTeam('')
    editTeamClose()
  }

  return (
    <>
      <Helmet title={t('Teams')} />

      <AlertDialog alert={alert} entity={`Team`} handleClose={handleAlertClose} />
      <UpdateTeamDialog
        open={!!editTeam}
        key={editTeam === '' ? 0 : 1}
        teamId={editTeam}
        onClose={editTeamClose}
        onDelete={handleDeleteTeam}
        onFinished={editTeamClose}
        handleAlert={handleAlert}
      />
      <CreateTeamDialog
        open={createTeam}
        onClose={createTeamClose}
        onFinished={createTeamClose}
        handleAlert={handleAlert}
      />
      {deleteTeam && (
        <DeleteTeamDialog open={!!deleteTeam} teamId={deleteTeam} onClose={deleteTeamClose} handleAlert={handleAlert} />
      )}

      <MPageTitle label={t('Teams')} />
      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <MFlexItem minWidth='auto' grow={10} shrink={2}>
            <RoleVisible role='ADMIN_TEAM_ADMIN'>
              <Box mr={1} mt={1}>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  startIcon={<AddIcon />}
                  children='Add Team'
                  onClick={createTeamOpen}
                />
              </Box>
            </RoleVisible>
          </MFlexItem>
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={() => setSearch('')}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
          <IconButton onClick={refetch}>
            <RefreshIcon loading={loading} />
          </IconButton>
        </MFlex>

        <QueryErrorMessage query={{ loading, error }} />

        <SimpleDataGrid
          columns={columns}
          data={data?.teams}
          rowCount={data?.teams?.length}
          onRowClick={handleEditTeam}
          loading={loading}
        />
      </MFlexBlock>
    </>
  )
}
