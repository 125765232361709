import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Box, Button, CircularProgress, Collapse, Switch } from '@mui/material'
import { PostAdd } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, MText, MTextColor } from '@mprise/react-ui'
import { MutationResult, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { SimpleDataGrid } from '../../shared/simple-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { GridColDef } from '@mui/x-data-grid'
import { useHistory } from '../../shared/use-history'
import { MutationErrorMessage, QueryErrorMessage } from '../../shared/react-apollo'
import { GET_BOOLEAN_SETTING, SET_BOOLEAN_SETTING } from '../../gql/settings'
import { GET_AUTO_PICK_RULES } from '../../gql/auto-pick-rules'

const columns: GridColDef[] = [
  { field: 'genus', headerName: 'Genus', width: 250, valueGetter: params => params.row.genus.name },
  {
    field: 'item',
    headerName: 'Items',
    width: 250,
    valueGetter: params => (params.row.allItems ? 'All' : params.row.items?.map((x: any) => x.name).join(', ')),
  },
  {
    field: 'variant',
    headerName: 'Variants',
    width: 250,
    valueGetter: params => (params.row.allVariants ? 'All' : params.row.variants?.map((x: any) => x.code).join(', ')),
  },
  {
    field: 'status',
    headerName: 'Status Category',
    width: 250,
    valueGetter: params => params.row.status,
  },
  {
    field: 'defaultResource',
    headerName: 'Default Resource',
    width: 250,
    valueGetter: params => params.row.defaultResource.name,
  },
  {
    field: 'defaultPosition',
    headerName: 'Default Position',
    width: 250,
    valueGetter: params => params.row.defaultPosition.code,
  },
]

export const AutoPickSettingsPage = () => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const [autoPickEnabled, setAutoPickEnabled] = useState(false)
  const [showCollapsibleContent, setShowCollapsibleContent] = useState(false)

  //prettier-ignore
  const { data: setting, loading: settingLoading, error: settingError, refetch } = useQuery(GET_BOOLEAN_SETTING, {
    variables: {
      companyId: +companyId,
      settingName: 'enable-auto-pick',
    },
  })

  const [setSetting, { data: setSettingData, loading: setSettingLoading, error: setSettingError }] =
    useMutation(SET_BOOLEAN_SETTING)

  useEffect(() => {
    setAutoPickEnabled(setting?.booleanSetting ?? false)
    setShowCollapsibleContent(setting?.booleanSetting ?? false)
  }, [setting])

  useEffect(() => {
    const newSettingValue = setSettingData?.setBooleanSetting
    if (newSettingValue !== null && newSettingValue !== undefined) {
      setAutoPickEnabled(newSettingValue)
      setShowCollapsibleContent(newSettingValue)
    }
  }, [setSettingData])

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAutoPickValue = event.target.checked
    setShowCollapsibleContent(false)
    setAutoPickEnabled(newAutoPickValue)

    setSetting({
      variables: {
        companyId: +companyId,
        settingName: 'enable-auto-pick',
        value: newAutoPickValue,
      },
    })
  }

  return (
    <>
      <Helmet title={t('Auto Pick')} />

      <MPageTitle label={t('Auto Pick')} />

      <MText block textVariant='small' textColor={MTextColor.shadow} style={{ paddingLeft: '1.5rem' }}>
        {t('AUTO_PICK_EXPLANATION')}
      </MText>
      <br />
      <MFlexBlock vertical gap={2} margin={[0, 4, 4, 4]}>
        <QueryErrorMessage query={{ loading: settingLoading, error: settingError, refetch }} />
        <MutationErrorMessage mutation={{ error: setSettingError } as MutationResult} />
        <MText block textVariant='content' textColor='#000000de' style={{ paddingLeft: '0.5rem' }}>
          {t('Enable Auto Pick')}
        </MText>
        <MFlexBlock gap={2} alignItems='center'>
          <Switch checked={autoPickEnabled} disabled={settingLoading || setSettingLoading} onChange={handleChange} />
          {(settingLoading || setSettingLoading) && <CircularProgress size='20px' />}
        </MFlexBlock>
      </MFlexBlock>

      <Collapse in={showCollapsibleContent}>
        <AutoPickRulesTable />
      </Collapse>
    </>
  )
}

const AutoPickRulesTable = () => {
  const { t } = useTranslation()
  const h = useHistory()
  const companyId = useCurrentCompanyId()

  const [getAutoPickRules, { data, loading, error, refetch }] = useLazyQuery(GET_AUTO_PICK_RULES, {
    notifyOnNetworkStatusChange: true,
    onError: console.error,
  })

  useEffect(() => {
    getAutoPickRules({
      variables: {
        companyId: +companyId,
      },
    })
  }, [companyId, getAutoPickRules])

  const handleEditRule = (rule: any, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    h.push(`/auto-pick-rules/edit/${rule.id}`)
  }

  return (
    <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
      <MFlex grow={1} alignItems='center'>
        <Box marginX={1} marginY={2}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            startIcon={<PostAdd />}
            children={t('Add New Rule')}
            component={Link}
            to='/auto-pick-rules/new'
          />
        </Box>
        <MFlexItem minWidth='auto' grow={10} shrink={2} />
      </MFlex>

      <QueryErrorMessage query={{ loading, error, refetch }} />

      <SimpleDataGrid
        columns={columns}
        data={data?.autoPickRules}
        onRowClick={handleEditRule}
        rowCount={data?.autoPickRules?.length ?? 0}
        loading={loading}
      />
    </MFlexBlock>
  )
}
