import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMField } from '@mprise/react-ui'
import { FieldSelect } from './field-select'
import { SingleSelectDialog } from '../dialog/single-select-dialog'
import { GET_POSITIONS } from '../../gql/positions'

export type SimplePosition = {
  id: number
  code: string
  name: string
}

export const FieldPosition = () => {
  const f = useMField()
  const { t } = useTranslation()
  const selected = f.value
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.(null)

  return (
    <>
      {open && (
        <SingleSelectDialog<SimplePosition>
          displayProperty='code'
          open={open}
          onClose={handleClose}
          onSave={position => {
            f.onChange?.(position)
            setOpen(false)
          }}
          title={t('Select Position')}
          initialValue={selected}
          getRecordsQuery={GET_POSITIONS}
          queryResultAccessor={data => data?.positions?.page}
        />
      )}
      <FieldSelect<SimplePosition>
        displayProperty='code'
        selected={f.value}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
