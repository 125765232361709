import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Box, Button, Checkbox, IconButton, TextField } from '@mui/material'
import { Add as AddIcon, Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import { MFlex, MFlexBlock, MFlexItem, px } from '@mprise/react-ui'
import { useHistory } from '../../shared/use-history'
import { RoleVisible, useRoleAvailable } from '../../auth'
import { RouterLink } from '../../react-router'
import { RefreshIcon } from '../../shared/refresh-icon'
import { useLazyQuery } from '@apollo/client'
import { GET_TRID_STATUSES } from '../../gql/tracking-status'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import { SimpleDataGrid } from '../../shared/simple-data-grid'
import { MPageTitle } from '../../shared/page-title'
import { GridColDef } from '@mui/x-data-grid'
import { Instant, LocalDate, LocalDateTime } from '@js-joda/core'

export type WarehouseStatusValue = {
  __typename?: 'WarehouseTrackingStatus' | undefined
  id: string
  name: string
  active: boolean
  activeUntil: string
  agriwareStatus: string
  isDefault: string
  lastModified: string
}

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 250 },
  {
    field: 'agriwareStatus',
    headerName: 'Status Category',
    width: 250,
    valueGetter: params => params.row?.agriwareStatus,
  },
  {
    field: 'isDefault',
    headerName: 'Default',
    width: 250,
    renderCell: params => <Checkbox disabled={true} checked={params.row?.isDefault} />,
  },
  {
    field: 'lastModified',
    headerName: 'Modified on',
    width: 250,
    valueGetter: params => dateToString(params.row?.lastModified, true),
  },
  {
    field: 'active',
    headerName: 'Status',
    width: 250,
    valueGetter: params =>
      params.row?.activeUntil && LocalDate.now() > LocalDate.ofInstant(Instant.parse(params.row?.activeUntil))
        ? 'Inactive'
        : 'Active',
  },
  {
    field: 'activeUntil',
    headerName: 'Active Until',
    width: 250,
    valueGetter: params => dateToString(params.row?.activeUntil, false),
  },
]

export const WarehouseStatusList = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const isStatusAdmin = useRoleAvailable(`ADMIN_STATUS_ADMIN`)
  const companyId = useCurrentCompanyId()
  const handleSearch = useCallback<React.ChangeEventHandler<HTMLInputElement>>(e => setSearch(e.target.value), [])
  const [search, setSearch] = useState(``)
  const debouncedSearch = useDebounceValue(search, 500)

  const [getStatuses, { error: statusesError, loading, data }] = useLazyQuery(GET_TRID_STATUSES)

  if (statusesError) {
    console.error(statusesError)
  }

  useEffect(() => {
    if (companyId) {
      getStatuses({
        variables: {
          companyId: +companyId,
        },
      })
    }
  }, [companyId, getStatuses])

  // TODO: Replace control that requires refetch or make a pagination in backend
  const refetch = () => {}

  const handleEditStatus = (status: WarehouseStatusValue, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (isStatusAdmin) {
      h.push(`/tracking-id-status/edit/${status.id}`)
    }
  }

  const statuses = data?.customStatuses?.filter((s: any) => s?.name?.toLowerCase()?.includes(debouncedSearch))

  return (
    <>
      <Helmet title={t('Tracking ID Statuses')} />

      <MPageTitle label={t('Tracking ID Statuses')} />
      <MFlexBlock vertical gap={4} margin={[0, 4, 25, 4]}>
        <MFlex grow={1} alignItems='center'>
          <MFlexItem minWidth='auto' grow={10} shrink={2}>
            <RoleVisible role='ADMIN_STATUS_ADMIN'>
              <Box mr={1} mt={1}>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  component={RouterLink}
                  startIcon={<AddIcon />}
                  to={`/tracking-id-status/new`}
                  children='Add Status'
                />
              </Box>
            </RoleVisible>
          </MFlexItem>
          <MFlexItem basis={px(480)} grow={1} shrink={1}>
            <TextField
              placeholder='Search'
              fullWidth
              variant='standard'
              autoFocus
              value={search}
              onChange={handleSearch}
              autoComplete='off'
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: <IconButton onClick={() => setSearch(``)}>{search && <ClearIcon />}</IconButton>,
              }}
            />
          </MFlexItem>
          <IconButton onClick={refetch}>
            <RefreshIcon loading={loading} />
          </IconButton>
        </MFlex>

        <SimpleDataGrid
          columns={columns}
          data={statuses}
          rowCount={statuses?.length}
          onRowClick={handleEditStatus}
          loading={loading}
        />
      </MFlexBlock>
    </>
  )
}

const dateToString = (dateString: string, withTime: boolean) => {
  if (dateString) {
    const date = LocalDateTime.ofInstant(Instant.parse(dateString))
    return date.toLocalDate() + (withTime ? ' ' + date.toLocalTime().toString().substring(0, 5) : '')
  }
  return ''
}
