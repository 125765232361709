import * as yup from 'yup'

// here we hide the weirdness of yup typings

export { yup }

export const yupObject = <T extends {}>(fields: Record<keyof T, yup.AnySchema>): yup.AnySchema => {
  return yup.object<T>()
}

export const yupObjectArray = <T extends {}>(fields: Record<keyof T, yup.AnySchema>): yup.AnySchema => {
  return yup.array().of(yup.object<T>().required())
}

export const yupStringArray = <T extends yup.StringSchema>(item: T) => {
  return yup.array().of(item.required())
}

export const yupEnum = <E,>(enumeration: E): yup.StringSchema<string | undefined> | any => {
  // return yup.string().oneOf(Object.values(enumeration))
  return yup.string()
}

export const yupEnumArray = <E,>(enumeration: E): yup.ArraySchema<any, yup.StringSchema> => {
  // return yup.array().of(yup.string().oneOf(Object.values(enumeration)).required())
  return yup.array()
}
