import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMField } from '@mprise/react-ui'
import { SelectAssigneeDialog } from '../dialog/select-assignee-dialog'
import { FieldSelect } from './field-select'

export type SimpleResource = {
  id: number
  code: string
  name: string
}

export const FieldResource = () => {
  const f = useMField()
  const { t } = useTranslation()
  const selected = f.value
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.(null)

  return (
    <>
      {open && (
        <SelectAssigneeDialog
          initialValue={selected}
          accept={['resource']}
          open={open}
          onClose={handleClose}
          onSave={resource => {
            f.onChange?.(resource)
            setOpen(false)
          }}
          title={t('Select Resource')}
        />
      )}
      <FieldSelect<SimpleResource>
        displayProperty='name'
        selected={f.value}
        onExplore={handleOpen}
        onClear={handleClear}
      />
    </>
  )
}
