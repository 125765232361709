import { gql } from '@apollo/client'

export const GET_POSITIONS = gql`
  query Positions($filter: PositionFilter, $limit: Int, $offset: Int) {
    positions(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        name
      }
      total
    }
  }
`

export const GET_SPACING_METHODS = gql`
  query SpacingMethods($filter: SpacingMethodFilter, $limit: Int, $offset: Int) {
    spacingMethods(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        description
      }
      total
    }
  }
`
