import { Collapse, FormGroup } from '@mui/material'
import { MBlock, MFieldInput } from '@mprise/react-ui'
import { Field, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { AlertDialog, AlertType } from '../../shared/alert-dialog'
import { FieldArraySwitch } from '../../shared/field-switch'
import { FormikCompareFix } from '../../shared/formik'
import { AutocompleteField, AutocompleteFieldSimple, CheckboxField } from '../../shared/material-ui-formik'
import { MFieldConnector } from '../../shared/mfield-adapter'
import { MFieldProviderConnector } from '../../shared/mfield-provider-adapter'
import { MSection, MSections } from '../../shared/msection'
import { PatternDialog } from '../../shared/pattern-dialog'
import { Maybe, nameof } from '../../shared/typescript'
import { yupEnumArray, yupObject } from '../../shared/yup-common-types'
import { AvailableResultTypes } from './restrictions'
import { useLazyQuery } from '@apollo/client'
import { GET_TRID_STATUSES } from '../../gql/tracking-status'
import { useCurrentCompanyId } from '../../shared/useCurrentCompany'
import {
  CarrierStatus,
  WorkItemType,
  ResourceReportOption,
  WorkResultType,
  WorkItemTemplateTaskOption,
} from '../../lib/enums'

export interface TaskForm {
  order: number
  name: string
  types: Array<WorkResultType>
  taskOptions: Array<WorkItemTemplateTaskOption>
  settingFinishOnFulfilled: Maybe<boolean>
  settingItemOutputWarehouseStatus: Maybe<{ id: string; name: string }>
  settingItemConsumptionWarehouseStatus: Maybe<{ id: string; name: string }>
  settingPackInputNextWarehouseStatus: Maybe<{ id: string; name: string }>
  settingPackOutputNextWarehouseStatus: Maybe<{ id: string; name: string }>
  settingLoadInputNextWarehouseStatus: Maybe<{ id: string; name: string }>
  settingLoadOutputNextCarrierStatus: Maybe<{ id: string; name: string }>
  settingsResourceReport: Maybe<{ id: ResourceReportOption; name: string }>
}

export const TaskDialog = ({
  alert,
  templateType,
  title,
  label,
  open,
  onClose,
  onSave,
  initialValues,
  onAlertClose,
}: {
  alert?: AlertType | null
  templateType: WorkItemType
  title: React.ReactNode
  label: string
  open: boolean
  onClose: () => void
  onSave: (values: TaskForm & FormikCompareFix) => void
  initialValues: TaskForm & FormikCompareFix
  onAlertClose?: () => void
}) => {
  const schema = TaskDialog.useSchema()
  return (
    <PatternDialog
      initialValues={initialValues}
      schema={schema}
      onSave={onSave}
      title={title}
      submit={label}
      open={open}
      onClose={onClose}
    >
      {alert === `duplicate` && <AlertDialog alert={alert} entity={`Task`} handleClose={onAlertClose} />}
      <TaskDialogForm templateType={templateType} />
    </PatternDialog>
  )
}

const TaskDialogForm = ({ templateType }: { templateType: WorkItemType }) => {
  const { t } = useTranslation()
  const companyId = useCurrentCompanyId()

  const [getStatuses, { error: statusesError, data: statuses }] = useLazyQuery(GET_TRID_STATUSES)

  if (statusesError) {
    console.error(statusesError)
  }

  useEffect(() => {
    getStatuses({
      variables: {
        filter: {
          companyId: +companyId,
          removed: false,
        },
      },
    })
  }, [])

  const f = useFormikContext<TaskForm>()
  const resultTypes = AvailableResultTypes[templateType] ?? []

  const getCarrierStatusDescription = (s: typeof CarrierStatus) => {
    return t(`CarrierStatus.${s}`)
  }
  const getReportResourceDescription = (s: ResourceReportOption) => {
    return t(s)
  }

  const showSettingFinishWhenFulfilled = [
    WorkItemType.JobPickOrder,
    WorkItemType.PackingOrder,
    WorkItemType.LoadingOrder,
  ].includes(templateType)
  return (
    <MSections>
      <MSection title={t(`Task`)} /*help={<HelpIcon description="Short description TODO" url="https://google.com" />}*/>
        <MBlock padding={4}>
          <Field component={MFieldConnector} name={nameof<TaskForm>(`name`)} fullWidth label={t(`Name`)}>
            <MFieldInput margin='dense' autoFocus inputProps={{ maxLength: 25 }} />
          </Field>
          {showSettingFinishWhenFulfilled && (
            <Field
              style={{ border: '1px solid' }}
              component={CheckboxField}
              name={nameof<TaskForm>(`settingFinishOnFulfilled`)}
              fullWidth
              label={t(`FIELD_FINISH_TASK_ON_FULFILLED`)}
              description={t(`FIELD_FINISH_TASK_ON_FULFILLED_DESCRIPTION`)}
            />
          )}
        </MBlock>
      </MSection>
      {resultTypes.length > 0 ? (
        <MSection
          title={t(
            `Select Task Results`,
          )} /*help={<HelpIcon description="Short description TODO" url="https://google.com" />}*/
        >
          <MBlock padding={4}>
            <Field component={MFieldProviderConnector} name='types'>
              <FormGroup>
                {resultTypes.map((type, index) => (
                  <FieldArraySwitch key={index} label={t(`WorkResultType.${type}`)} value={type} />
                ))}
              </FormGroup>
            </Field>
          </MBlock>
        </MSection>
      ) : null}
      {templateType === WorkItemType.JobPickOrder && showSettingsItemOutput(f.values) ? (
        <MSection title={t('Task Options')}>
          <MBlock padding={4}>
            <Field component={MFieldProviderConnector} name='taskOptions'>
              <FormGroup>
                <FieldArraySwitch
                  label={t(`PREFILL_DEFAULT_POSITION`)}
                  value={WorkItemTemplateTaskOption.PickPrefillDefaultPosition}
                />
                <FieldArraySwitch
                  label={t(`Prefill Default Job`)}
                  value={WorkItemTemplateTaskOption.PickPrefillDefaultJob}
                />
                <FieldArraySwitch
                  label={t(`Prefill Default Output Position`)}
                  value={WorkItemTemplateTaskOption.PickPrefillDefaultOutputPosition}
                />
                {f.values.taskOptions.includes(WorkItemTemplateTaskOption.PickPrefillDefaultOutputPosition) ? (
                  <FieldArraySwitch
                    label={t(`Block Changing Output Position`)}
                    value={WorkItemTemplateTaskOption.PickBlockChangingOutputPosition}
                  />
                ) : null}
                <FieldArraySwitch
                  label={t(`Specify Used Resource`)}
                  value={WorkItemTemplateTaskOption.PickSpecifyResource}
                />
                <FieldArraySwitch
                  label={t(`Prefill Remaining Quantity`)}
                  value={WorkItemTemplateTaskOption.PickPrefillRemainingQuantity}
                />
                <FieldArraySwitch
                  label={t(`ALLOW_DELETION_RESULT_LINES`)}
                  value={WorkItemTemplateTaskOption.PickAllowDeletionResultLines}
                />
              </FormGroup>
            </Field>
          </MBlock>
        </MSection>
      ) : null}
      {/* new field values should be 'None'  and 'Consumption Lines'  */}

      {showSettingsResourceReport(f.values) ? (
        <MSection title={t(`Resource Reporting`)}>
          <MBlock padding={4}>
            <Field
              component={AutocompleteFieldSimple}
              name='settingsResourceReport'
              label={t(`Resource Reporting Method`)}
              options={Object.values(ResourceReportOption)}
              getOptionLabel={getReportResourceDescription}
            />
          </MBlock>
        </MSection>
      ) : null}

      <ConditionalForm in={showSettings}>
        <MSection title={t(`Select Task Result Status`)}>
          <MBlock padding={4}>
            {showSettingItemConsumptionWarehouseStatus(f.values) ? (
              <Field
                component={AutocompleteField}
                name='settingItemConsumptionWarehouseStatus'
                label={t(`Item Consumption Status`)}
                options={statuses?.customStatuses ?? []}
              />
            ) : null}
            {showSettingsItemOutput(f.values) ? (
              <Field
                component={AutocompleteField}
                name='settingItemOutputWarehouseStatus'
                label={t(`Item Output Status`)}
                options={statuses?.customStatuses ?? []}
              />
            ) : null}
            {showSettingPackInputNextWarehouseStatus(f.values) ? (
              <Field
                component={AutocompleteField}
                name='settingPackInputNextWarehouseStatus'
                label={t(`Input TrackingId status after completion`)}
                options={statuses?.customStatuses ?? []}
              />
            ) : null}
            {showSettingPackOutputNextWarehouseStatus(f.values) ? (
              <Field
                component={AutocompleteField}
                name='settingPackOutputNextWarehouseStatus'
                label={t(`Output TrackingId status after completion`)}
                options={statuses?.customStatuses ?? []}
              />
            ) : null}
            {showSettingLoadInputNextCarrierStatus(f.values) ? (
              <Field
                component={AutocompleteField}
                name='settingLoadInputNextWarehouseStatus'
                label={t(`Input TrackingId Status after completion`)}
                options={statuses?.customStatuses ?? []}
              />
            ) : null}
            {showSettingLoadOutputNextCarrierStatus(f.values) ? (
              <Field
                component={AutocompleteFieldSimple}
                name='settingLoadOutputNextCarrierStatus'
                label={t(`Output CarrierId status after completion`)}
                getOptionLabel={getCarrierStatusDescription}
                options={Object.values(CarrierStatus)}
              />
            ) : null}
          </MBlock>
        </MSection>
      </ConditionalForm>
    </MSections>
  )
}

TaskDialog.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(() =>
    yupObject<TaskForm>({
      name: yup.string().label(t(`Name`)).required(),
      types: yupEnumArray(WorkResultType).optional().label(t(`Types`)) as any,
      taskOptions: yupEnumArray(WorkItemTemplateTaskOption).optional().label(t(`TaskOptions`)) as any,
      order: yup.number().required().label(t(`Order`)),
      settingFinishOnFulfilled: yup.boolean().nullable().label(t(`FIELD_FINISH_TASK_ON_FULFILLED`)),
      settingItemOutputWarehouseStatus: yupObject({ id: yup.string(), name: yup.string() })
        .nullable()
        .test((x: unknown, c: { parent: TaskForm }) => {
          if (c.parent.types.includes(WorkResultType.ItemOutput)) {
            // Require an input when Item Output
            return Boolean(x)
          } else {
            return true
          }
        })
        .label(t(`Item Output Status`)),
      settingsResourceReport: yupObject({ id: yup.string(), name: yup.string() })
        .nullable()
        .test((x: { id: string; name: string }) => {
          if (!x) {
            return true
          }
          return Object.values(ResourceReportOption).some(option => {
            return x!.id === option
          })
        })

        .label(t(`Resource Reporting Method`)),
      settingItemConsumptionWarehouseStatus: yupObject({ id: yup.string(), name: yup.string() })
        .nullable()
        .test((x: unknown, c: { parent: TaskForm }) => {
          if (c.parent.types.includes(WorkResultType.ItemConsumption)) {
            // Require an input when Item Consumption
            return Boolean(x)
          } else {
            return true
          }
        })
        .label(t(`Item Consumption Status`)),
      settingPackInputNextWarehouseStatus: yupObject({ id: yup.string(), name: yup.string() })
        .nullable()
        .test((x: unknown, c: { parent: TaskForm }) => {
          if (c.parent.types.includes(WorkResultType.TrackingidAssembly)) {
            // Require an input when TrackingId Assembly
            return Boolean(x)
          } else {
            return true
          }
        })
        .label(t(`Input Tracking ID status after completion`)),
      settingPackOutputNextWarehouseStatus: yupObject({ id: yup.string(), name: yup.string() })
        .nullable()
        .test((x: unknown, c: { parent: TaskForm }) => {
          if (c.parent.types.includes(WorkResultType.TrackingidAssembly)) {
            // Require an input when TrackingId Assembly
            return Boolean(x)
          } else {
            return true
          }
        })
        .label(t(`Output Tracking ID status after completion`)),
      settingLoadInputNextWarehouseStatus: yupObject({ id: yup.string(), name: yup.string() })
        .nullable()
        .test((x: unknown, c: { parent: TaskForm }) => {
          if (c.parent.types.includes(WorkResultType.CarrieridAssembly)) {
            // Require an input when TrackingId Assembly
            return Boolean(x)
          } else {
            return true
          }
        })
        .label(t(`Input Tracking ID status after completion`)),
      settingLoadOutputNextCarrierStatus: yup
        .string()
        .nullable()
        .test((x: unknown, c: { parent: TaskForm }) => {
          if (c.parent.types.includes(WorkResultType.CarrieridAssembly)) {
            // Require an input when TrackingId Assembly
            return Boolean(x)
          } else {
            return true
          }
        })
        .label(t(`Output Carrier ID status after completion`)),
    }),
  )

  return schema
}

const ConditionalForm = <Values extends unknown>({
  children,
  in: _in,
}: {
  children: React.ReactNode
  in: (form: Values) => boolean
}) => {
  const f = useFormikContext<Values>()
  return <Collapse in={_in(f.values)}>{children}</Collapse>
}

const showSettingPackInputNextWarehouseStatus = (values: TaskForm): boolean => {
  return values.types.includes(WorkResultType.TrackingidAssembly)
}

const showSettingLoadInputNextCarrierStatus = (values: TaskForm): boolean => {
  return values.types.includes(WorkResultType.CarrieridAssembly)
}

const showSettingPackOutputNextWarehouseStatus = (values: TaskForm): boolean => {
  return values.types.includes(WorkResultType.TrackingidAssembly)
}

const showSettingLoadOutputNextCarrierStatus = (values: TaskForm): boolean => {
  return values.types.includes(WorkResultType.CarrieridAssembly)
}

const showSettingsItemOutput = (values: TaskForm): boolean => {
  return values.types.includes(WorkResultType.ItemOutput)
}

const showSettingItemConsumptionWarehouseStatus = (values: TaskForm): boolean => {
  return values.types.includes(WorkResultType.ItemConsumption)
}
const showSettingsResourceReport = (values: TaskForm): boolean => {
  return values.types.some(x => AvailableResultTypes.JOB_WORK_ORDER?.includes(x))
}

const showSettings = (values: TaskForm): boolean => {
  return [
    showSettingsItemOutput,
    showSettingItemConsumptionWarehouseStatus,
    showSettingPackInputNextWarehouseStatus,
    showSettingPackOutputNextWarehouseStatus,
    showSettingLoadInputNextCarrierStatus,
    showSettingLoadOutputNextCarrierStatus,
    showSettingsResourceReport,
  ].some(show => show(values))
}
