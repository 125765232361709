export type Optional<T> = T | null | undefined
export type NotOptional<T> = Exclude<T, null | undefined>
export type Maybe<T> = T | null

export const defined = <T extends unknown>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined

export const nameof = <T,>(name: Extract<keyof T, string>): string => name

export function fail(msg: string): never {
  throw Error(msg)
}

/**
 * selects all unique items (first item of duplicate wins)
 * @param array list of all items
 * @param key selects unique identifier for an item
 * @returns subset of all items where each key only occurs once
 */
export function unique<T>(array: Array<T>, key?: (item: T) => unknown): Array<T> {
  if (key) {
    const reversed = array.slice().reverse() // first unique one appears in output
    const combined = new Map(reversed.map(x => [key(x), x]))
    const output = Array.from(combined.values())
    return output
  } else {
    return Array.from(new Set(array).values())
  }
}

export type ObjectWithDefaults<Values, Defaults extends Values> = {
  [key in keyof Values]-?: Exclude<Values[key], null | undefined> | Defaults[key]
}

export function withFallbackValues<Values extends {}, Defaults extends Values>(
  input: Values,
  defaults: Defaults,
): ObjectWithDefaults<Values, Defaults>
export function withFallbackValues(input: {}, defaults: Record<PropertyKey, unknown>): {} {
  if (input) {
    const entries = Object.entries(input)
    const patched = entries.map(([key, value]) => [key, value ?? defaults[key]])
    const result = Object.fromEntries(patched)
    return result as any
  } else {
    throw Error(`expects input`)
  }
}

export function FallbackValues<Values extends {}, Defaults extends Values>(
  defaults: Defaults,
): (input: Values) => ObjectWithDefaults<Values, Defaults>
export function FallbackValues(defaults: Record<PropertyKey, unknown>): {} {
  return (input: {}) => withFallbackValues(input, defaults)
}

export function withFallbackValuesMaybe<Values extends {}, Defaults extends Values>(
  input: Values | undefined | null,
  defaults: Defaults,
): ObjectWithDefaults<Values, Defaults> | Defaults
export function withFallbackValuesMaybe(
  input: {} | null | undefined,
  defaults: Record<PropertyKey, unknown>,
): {} | null | undefined {
  if (input) {
    const entries = Object.entries(input)
    const patched = entries.map(([key, value]) => [key, value ?? defaults[key]])
    const result = Object.fromEntries(patched)
    return result as any
  } else {
    return null
  }
}

export function WithFallbackValuesMaybe<Values extends {}, Defaults extends Values>(
  defaults: Defaults,
): (input: Values | undefined | null) => ObjectWithDefaults<Values, Defaults> | Defaults
export function WithFallbackValuesMaybe(defaults: Record<PropertyKey, unknown>): {} | null | undefined {
  return (input: {} | null | undefined) => withFallbackValuesMaybe(input, defaults)
}
