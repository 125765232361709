import { gql } from '@apollo/client'

export const GET_REASONS = gql`
  query Reasons($filter: ReasonFilter, $limit: Int, $offset: Int) {
    reasons(filter: $filter, limit: $limit, offset: $offset) {
      page {
      id
      code
      name
      cosmosKey
    }
    total
    }
  }
`
