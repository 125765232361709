import { gql } from '@apollo/client'

export const GET_GENUSES = gql`
  query Genuses($filter: GenusFilter, $limit: Int, $offset: Int) {
    genuses(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        name
        code
      }
      total
    }
  }
`
