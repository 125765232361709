import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MPortalDialog, MTextColor } from '@mprise/react-ui'
import { DocumentNode, MutationResult, OperationVariables, useMutation } from '@apollo/client'
import { DialogContentText } from '@mui/material'
import { MutationErrorMessage } from '../react-apollo'

export const DeleteRecordDialog = ({
  record,
  recordType,
  onClose,
  editPopupClose,
  deleteQuery,
  variables,
  refetchQueries,
}: {
  record: { id: number | string; name?: string }
  recordType: string
  onClose: () => void
  editPopupClose: () => void
  deleteQuery: DocumentNode
  variables?: OperationVariables
  refetchQueries: DocumentNode[]
}) => {
  const { t } = useTranslation()

  const [deleteRecord, { data: deleteData, error }] = useMutation(deleteQuery, {
    refetchQueries,
    onError: console.error,
  })

  useEffect(() => {
    if (deleteData) {
      editPopupClose()
      onClose()
    }
  }, [deleteData, editPopupClose, onClose])

  const onCommit: React.FormEventHandler = async e => {
    e.preventDefault()
    deleteRecord({ variables: variables ?? { id: record.id } })
  }

  return (
    <MPortalDialog
      open={!!record}
      title={t('DeleteRecordOfType', { recordType })}
      submit={t('DeleteRecordOfType', { recordType })}
      onSubmit={onCommit}
      onClose={onClose}
    >
      <MutationErrorMessage mutation={{ error } as MutationResult} />
      <MPortalDialog.Content>
        <DialogContentText color={MTextColor.dark}>
          {record.name
            ? t('DeleteRecordDialogHint', { recordType: recordType.toLowerCase(), name: `'${record.name}'` })
            : t('DeleteRecordDialogHintWithoutName', { recordType: recordType.toLowerCase() })}
        </DialogContentText>
      </MPortalDialog.Content>
    </MPortalDialog>
  )
}
