import produce, { Draft } from 'immer'
import { Reducer, useReducer } from 'react'

export const useImmerReducer = <S extends unknown, A extends unknown>(
  reducer: (state: Draft<S>, action: A) => Draft<S> | void,
  initialState: S,
) =>
  useReducer<Reducer<S, A>>(
    (state, action) => produce(state, mutableState => reducer(mutableState, action)) as S,
    initialState,
  )
