import Button from '@mui/material/Button'
import { MpriseAuthProvider } from '@mprise/react-auth'
import {
  MAvatar,
  MDialog,
  MSelectItem,
  PaginatedSearchAndSelect,
  ScanningSetting,
  ToMSelectItem,
} from '@mprise/react-ui'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounceValue } from '../shared/debounce-value'
import { useLazyQuery } from '@apollo/client'
import { GET_COMPANIES } from '../gql/companies'
import { useLocalState } from './react-local-state'

export type CompanyValue = {
  __typename?: 'Company' | undefined
  id: number
  name: string
  cosmosKey: string
}

const toSelectItem = ToMSelectItem<CompanyValue>({
  id: x => x.id.toString(),
  icon: () => <MAvatar.Icon.Company />,
  primary: x => x.name ?? ``,
})

export const SelectCompanyDialog = ({
  title,
  open,
  onClose,
  onSave,
}: {
  title: React.ReactNode
  open: boolean
  onClose: () => void
  onSave: (values: CompanyValue | null) => void
}) => {
  const { t } = useTranslation()

  const { mpriseId } = MpriseAuthProvider.useContext()

  const handleLogout = () => {
    mpriseId?.logout()
  }

  const [getCompanies, { error: companiesError, data: companies }] = useLazyQuery(GET_COMPANIES)

  const [search, setSearch] = useLocalState(``, [open])
  const debouncedSearch = useDebounceValue(search, 500)

  useEffect(() => {
    getCompanies()
  }, [])

  useEffect(() => {
    if (debouncedSearch) {
      getCompanies({
        variables: {
          filter: {
            name: debouncedSearch,
          },
        },
      })
    } else {
      getCompanies()
    }
  }, [debouncedSearch])

  const handleSelect = async (item: MSelectItem<CompanyValue> | null) => {
    onSave(item?.data ?? null)
  }

  const handleSubmit: React.FormEventHandler = async e => {
    e.stopPropagation()
    e.preventDefault()
    onClose()
  }

  const handleNextPage = (cursor: string) => {
    // companyQuery.fetchMore({ variables: { companieCursor: cursor } })
  }

  return (
    <MDialog
      minWidth='sm'
      open={open}
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit}
      footer={
        companiesError || (companies && companies.companies.length === 0) ? (
          <Button fullWidth variant='contained' color='primary' onClick={handleLogout}>
            {t(`Logout`)}
          </Button>
        ) : null
      }
    >
      {companies && (
        <PaginatedSearchAndSelect
          scanningSetting={ScanningSetting.KEYBOARD}
          items={companies.companies.map(toSelectItem) ?? null}
          nextCursor={null}
          search={search}
          value={null}
          onChange={handleSelect}
          onNextPage={handleNextPage}
          onSearch={setSearch}
          showSwitchKeyboardOption={true}
        />
      )}
    </MDialog>
  )
}
