import { Button } from '@mui/material'
import { MFlex, MFlexBlock, MPortalDialog, MPortalDialogProps } from '@mprise/react-ui'
import { Form, useFormikContext } from 'formik'
import React from 'react'

export const FormikDebug = () => {
  const f = useFormikContext()
  const grabbed = { values: f.values, touched: f.touched, errors: f.errors }
  return <pre>{JSON.stringify(grabbed, undefined, 4)}</pre>
}

export const FormikDialog = ({
  removeChildren,
  onRemove,
  ...props
}: Omit<MPortalDialogProps, 'onSubmit' | 'onReset'> & {
  removeChildren?: React.ReactNode
  onRemove?: (form: unknown) => void
}) => {
  const f = useFormikContext()
  const handleRemove = () => {
    onRemove?.(f.values)
  }
  return (
    <MPortalDialog.Dialog minWidth={props.minWidth} open={props.open} onClose={props.onClose}>
      <Form>
        <MPortalDialog.Header onClose={props.onClose}>
          <MFlexBlock justifyContent='flex-start' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            {props.title}
          </MFlexBlock>
        </MPortalDialog.Header>
        <MPortalDialog.Content>{props.children}</MPortalDialog.Content>
        <MPortalDialog.Footer>
          <MFlex alignItems='flex-end'>
            <MFlex grow={1}>{removeChildren && <Button onClick={handleRemove}>{removeChildren}</Button>}</MFlex>
            <MPortalDialog.SubmitButton>{props.submit}</MPortalDialog.SubmitButton>
          </MFlex>
        </MPortalDialog.Footer>
      </Form>
    </MPortalDialog.Dialog>
  )
}
