import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ListItemText, Checkbox, IconButton } from '@mui/material'
import { MColor, MDivider, MFlexBlock, MFlexItem, MJoinChildren, MText, useMField } from '@mprise/react-ui'
import { SearchAndSelectDialog } from '../dialog/search-and-select-dialog'
import { mdiPencilOutline } from '@mdi/js'
import Icon from '@mdi/react'

export const FieldPermissionSubset = ({ label, permissionSubset }: { label: string; permissionSubset: string[] }) => {
  const f = useMField()
  const { t } = useTranslation()
  const selected = f.value as string[]
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const onSave = (selectedItems: string[]) => {
    f.onChange?.(selectedItems)
    handleClose()
  }

  return (
    <>
      {open && (
        <SearchAndSelectDialog
          initialValue={selected}
          gap={0}
          open={open}
          title={`${t('Permissions')} - ${label}`}
          onClose={handleClose}
          onSave={onSave}
        >
          <MJoinChildren divider={MDivider}>
            <MultiSelectDialogItemSelectAll allPermissionsInSubset={permissionSubset} />
            {permissionSubset.map(x => (
              <MultiSelectDialogItem key={x} value={x} />
            ))}
          </MJoinChildren>
        </SearchAndSelectDialog>
      )}
      <FieldSelectPermissions
        label={label}
        selected={selected}
        allPermissionsInSubset={permissionSubset}
        handleOpen={handleOpen}
      />
    </>
  )
}

const MultiSelectDialogItemSelectAll = ({ allPermissionsInSubset }: { allPermissionsInSubset: string[] }) => {
  const f = useMField()
  const { t } = useTranslation()
  const selected = f.value as string[]
  const selectedCount = selected.filter(x => allPermissionsInSubset.includes(x)).length
  const totalCount = allPermissionsInSubset.length
  const anySelected = selectedCount !== 0

  const handleClick = () => {
    const newSelected = selected.slice()
    if (anySelected) {
      f.onChange?.(newSelected.filter(x => !allPermissionsInSubset.includes(x)))
    } else {
      newSelected.push(...allPermissionsInSubset)
      f.onChange?.(newSelected)
    }
  }

  return (
    <MFlexBlockClickable gap={4} padding={[2, 4, 1, 4]} alignItems='center' onClick={handleClick}>
      <Checkbox checked={anySelected} indeterminate={anySelected && selectedCount < totalCount} />
      <MFlexItem grow={1}>
        <ListItemText primary={t('Select All')} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

const MultiSelectDialogItem = ({ value }: { value: string }) => {
  const f = useMField()
  const { t } = useTranslation()
  const selectedRecords = f.value as string[]
  const currentlySelected = selectedRecords.includes(value)

  const handleClick = () => {
    const newSelected = selectedRecords.slice()
    if (currentlySelected) {
      f.onChange?.(newSelected.filter(x => x !== value))
    } else {
      newSelected.push(value)
      f.onChange?.(newSelected)
    }
  }

  return (
    <MFlexBlockClickable gap={4} padding={[1, 4]} alignItems='center' onClick={handleClick}>
      <Checkbox checked={currentlySelected} />
      <MFlexItem grow={1}>
        <ListItemText primary={t(`PERMISSION.${value}`)} />
      </MFlexItem>
    </MFlexBlockClickable>
  )
}

const FieldSelectPermissions = ({
  label,
  selected,
  allPermissionsInSubset,
  handleOpen,
}: {
  label: string
  selected: string[]
  allPermissionsInSubset: string[]
  handleOpen: () => void
}) => {
  const selectedCount = selected.filter(x => allPermissionsInSubset.includes(x)).length
  const totalCount = allPermissionsInSubset.length
  const anySelected = selectedCount !== 0

  return (
    <MFlexBlock bgColor={MColor.white} alignItems='center'>
      <MFlexItemClickable grow={1} onClick={handleOpen}>
        <Checkbox checked={anySelected} indeterminate={anySelected && selectedCount < totalCount} sx={{ ml: -1.5 }} />
        <MText inline style={{ display: 'inline-block', width: '215px' }}>
          {label}
        </MText>
        <MText inline>{`${selectedCount}/${totalCount}`}</MText>
      </MFlexItemClickable>
      <IconButton onClick={handleOpen}>
        <Icon path={mdiPencilOutline} size={1} />
      </IconButton>
    </MFlexBlock>
  )
}

const MFlexItemClickable = styled(MFlexItem)`
  cursor: pointer;
`

const MFlexBlockClickable = styled(MFlexBlock)`
  cursor: pointer;
  color: inherit;
`
