import { gql } from '@apollo/client'

export const GET_RESOURCES = gql`
  query Resources($filter: ResourceFilter, $limit: Int, $offset: Int) {
    resources(filter: $filter, limit: $limit, offset: $offset) {
      page {
        id
        code
        blocked
        name
        cosmosKey
        type
        jobTitle
        initials
        skills {
          id
          name
          code
        }
      }
      total
    }
  }
`
