import { BarcodeCompletedHandler, MAvatar, MField, MFlex, MText, MTextColor, useMField } from '@mprise/react-ui'
import { useEffect, useState } from 'react'
import {
  AssigneeTeamOptions,
  AssigneeType,
  AssigneeValue,
  SelectAssigneeDialog,
} from '../dialog/select-assignee-dialog'
import { FieldBarcode } from './field-barcode'
import { useLazyQuery } from '@apollo/client'
import { GET_RESOURCES } from '../../gql/resource'
import { useCurrentCompanyId } from '../useCurrentCompany'
import { TeamResourceMode } from '../../lib/enums'
import { DEFAULT_PAGE_SIZE } from '../simple-data-grid'

export const FieldAssignee = ({
  title,
  accept,
  dialogTeamOptions,
  teamFilterOnResourceMode,
}: {
  title: string
  accept?: Array<AssigneeType>
  dialogTeamOptions?: AssigneeTeamOptions
  teamFilterOnResourceMode?: TeamResourceMode
}) => {
  const f = useMField()
  const companyId = useCurrentCompanyId()
  const selected = f.value as AssigneeValue
  const [open, setOpen] = useState(false)

  const [getResources, { error: resourcesError, loading: resourcesLoading, data: resources }] =
    useLazyQuery(GET_RESOURCES)

  if (resourcesError) {
    console.error(resourcesError)
  }

  useEffect(() => {
    if (resources) {
      f.onChange?.(resources.resources.page)
    }
  }, [resources])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const handleScanned: BarcodeCompletedHandler = ({ code }) => {
    getResources({
      variables: {
        filter: {
          companyId: +companyId,
          code: code,
          removed: false,
        },
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
      },
    })
  }

  return (
    <>
      <SelectAssigneeDialog
        initialValue={selected}
        accept={accept}
        open={open}
        onClose={handleClose}
        onSave={team => {
          f.onChange?.(team)
          setOpen(false)
        }}
        title={title}
        teamOptions={dialogTeamOptions}
        teamFilterOnResourceMode={teamFilterOnResourceMode}
      />
      <FieldBarcode
        selected={f.value}
        loading={resourcesLoading}
        renderLabel={(value: any) => (
          <MField.ScrollableContentX>
            <MText block ellipsis textVariant='content bold'>
              <MFlex>
                {value && (
                  <MAvatar.Item label={renderAvatarLabel(value)} image={renderAvatarIcon(value)} seed={value.id} />
                )}
              </MFlex>
            </MText>
          </MField.ScrollableContentX>
        )}
        showInstantScanButton={false}
        onClear={handleClear}
        onExplore={handleOpen}
        onScanned={handleScanned}
      />
    </>
  )
}

const renderAvatarIcon = (assignee: { __typename: string; id: string; code?: string; name: string; resources: [] }) => {
  if (assignee.__typename === `Resource`) {
    return <MAvatar.Icon.Resource />
  } else {
    return <MAvatar.Icon.Team />
  }
}

const renderAvatarLabel = (assignee: {
  __typename: string
  id: string
  code?: string
  name: string
  resources: []
}) => {
  if (assignee.__typename === `Team`) {
    return (
      <>
        <MText block ellipsis textVariant='small' textColor={MTextColor.dark}>
          {assignee.name}
        </MText>
        <MText block ellipsis textVariant='smallest' textColor={MTextColor.shadow}>
          ({assignee.resources.length ?? 0} members)
        </MText>
      </>
    )
  } else if (assignee.__typename === `Resource`) {
    return (
      <>
        <MText block ellipsis textVariant='small' textColor={MTextColor.dark}>
          {assignee.name}
        </MText>
        <MText block ellipsis textVariant='smallest' textColor={MTextColor.shadow}>
          ({assignee.code})
        </MText>
      </>
    )
  } else {
    return (
      <MText block ellipsis textVariant='small' textColor={MTextColor.dark}>
        {assignee.name}
      </MText>
    )
  }
}
