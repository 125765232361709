import { MFieldContext } from '@mprise/react-ui'
import { useUniqueId } from '@mprise/react-ui/dist/internals'
import { FieldProps } from 'formik'
import React, { useCallback } from 'react'

export const MFieldProviderConnector = ({
  children,
  scrollableX,
  label,
  ...f
}: FieldProps & { label?: React.ReactNode; scrollableX?: boolean; children: React.ReactNode }) => {
  const id = useUniqueId(f.field.name)
  const name = f.field.name
  const value = f.field.value
  const onChange = useCallback((value: any) => {
    f.form.setFieldValue(f.field.name, value)
  }, [])
  return <MFieldContext.Provider value={{ id, name, value, onChange }}>{children}</MFieldContext.Provider>
}
