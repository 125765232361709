import { BarcodeCompletedHandler, MField, MText, useMField } from '@mprise/react-ui'
import { useEffect, useState } from 'react'
import { AssigneeType, AssigneeValue } from '../dialog/select-assignee-dialog'
import { AssigneeAvatars, SelectAssigneesDialog } from '../dialog/select-assignees-dialog'
import { FieldBarcode } from './field-barcode'
import { useLazyQuery } from '@apollo/client'
import { GET_RESOURCES } from '../../gql/resource'
import { useDebounceValue } from '../../shared/debounce-value'
import { useCurrentCompanyId } from '../useCurrentCompany'
import { DEFAULT_PAGE_SIZE } from '../simple-data-grid'

export const FieldAssigneeSet = ({ title, accept }: { title: string; accept?: Array<AssigneeType> }) => {
  const f = useMField()
  const companyId = useCurrentCompanyId()
  const selected: AssigneeValue[] = f.value
  const [open, setOpen] = useState(false)

  const [getResources, { error: resourcesError, loading: resourcesLoading, data: resources }] =
    useLazyQuery(GET_RESOURCES)

  if (resourcesError) {
    console.error(resourcesError)
  }

  useEffect(() => {
    if (resources) {
      f.onChange?.(resources.resources.page)
    }
  }, [resources])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClear = () => f.onChange?.([])

  const handleScanned: BarcodeCompletedHandler = ({ code }) => {
    getResources({
      variables: {
        filter: {
          companyId: +companyId,
          code: code,
          removed: false,
        },
        limit: DEFAULT_PAGE_SIZE,
        offset: 0,
      },
    })
  }

  return (
    <>
      <SelectAssigneesDialog
        initialValue={selected}
        accept={accept}
        open={open}
        onClose={handleClose}
        onSave={team => {
          f.onChange?.(team)
          setOpen(false)
        }}
        title={title}
      />
      <FieldBarcode
        selected={f.value}
        loading={resourcesLoading}
        renderLabel={() => (
          <MField.ScrollableContentX>
            <MText block ellipsis textVariant='content bold'>
              <AssigneeAvatars onExplore={handleOpen} />
            </MText>
          </MField.ScrollableContentX>
        )}
        showInstantScanButton={false}
        onClear={handleClear}
        onScanned={handleScanned}
        onExplore={handleOpen}
      />
    </>
  )
}
